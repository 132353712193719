import { useCallback, useMemo, useState } from 'react';
import {
  ReviewInviteFormValues,
  ReviewInviteModal,
} from './ListOfCompaniesTable.components';

export function useReviewInviteModal(
  onSubmit: (data: ReviewInviteFormValues) => Promise<void>,
) {
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [companyLinkInfo, setCompanyLinkInfo] = useState<{
    companyName: string;
    companyLinkId: string;
  } | null>(null);

  const openReviewModal = useCallback(
    (companyName: string, companyLinkId: string) => {
      setCompanyLinkInfo({
        companyName,
        companyLinkId,
      });
      setReviewModalOpen(true);
    },
    [],
  );

  const handleClose = useCallback(
    async (data: ReviewInviteFormValues | null) => {
      if (data) {
        await onSubmit(data);
      }
      setReviewModalOpen(false);
    },
    [onSubmit],
  );

  const Modal = useMemo(() => {
    return () => (
      <ReviewInviteModal
        open={isReviewModalOpen}
        companyLinkInfo={companyLinkInfo}
        onClose={handleClose}
      />
    );
  }, [isReviewModalOpen, companyLinkInfo, handleClose]);

  return {
    openReviewModal,
    Modal,
  };
}
