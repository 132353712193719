import { Button } from '@/modules/common/components/Button';
import { DropDown } from '@/modules/common/components/DropDown';
import { MenuItem } from '@/modules/common/components/MenuItem';
import { AddTeamMemberDialog } from '@/modules/employees/components/AddTeamMemberDialog';
import { EmployeeLearningBudgetDialog } from '@/modules/employees/components/EmployeeLearningBudgetDialog';
import { RemoveTeamMemberDialog } from '@/modules/employees/components/RemoveTeamMemberDialog';
import { Input } from '@mui/base';
import { ArrowBackIosOutlined, Search } from '@mui/icons-material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { useGetTeam } from '../hooks/useTeams';

import { IUser } from '@/types/user';

type Params = {
  teamId: string;
};

const routeApi = getRouteApi('/_admin/teams/$teamId');

export function ViewTeamPage() {
  const { teamId } = routeApi.useParams<Params>();

  const navigate = useNavigate();

  const [learningBudgetEmployeeId, setLearningBudgetEmployeeId] = useState('');
  const [removeMemberId, setRemoveMemberId] = useState('');
  const [filteredMembers, setFilteredMembers] = useState<IUser[]>([]);
  const [isAddTeamMemberDialogOpen, setAddTeamMemberDialogOpen] =
    useState(false);

  const { isLoading: isLoadingTeam, data: { data: team } = {} } =
    useGetTeam(teamId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const members = (team?.members as IUser[]) || [];

  const hasNoMembers = members.length < 1;

  const debouncedSearchTerm = debounce(term => {
    const filtered = members.filter(m => {
      return (
        m.firstName?.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
        m.lastName?.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
        m.email.toLowerCase().indexOf(term.toLowerCase()) !== -1
      );
    });

    setFilteredMembers(filtered);
  }, 300);

  useEffect(() => {
    setFilteredMembers(members);
  }, [members]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    debouncedSearchTerm(value);
  };

  if (!team || isLoadingTeam) {
    return <CircularProgress />;
  }

  return (
    <div className="flex flex-col h-full">
      <header className="flex justify-between items-center px-12 py-5 border-b">
        <h1 className="text-2xl font-medium flex items-center">
          <a
            className="mr-2 inline-block align-bottom cursor-pointer"
            title="Back"
            onClick={() => window.history.back()}
          >
            <ArrowBackIosOutlined fontSize={'small'} />
          </a>
          <span>Team {team.name}</span>
        </h1>
      </header>

      <div className="flex-1 px-12 pt-8">
        <h2 className="font-semibold">Team Members</h2>
        <p className="">Invite and manage members of your team.</p>
        <div className="flex justify-end">
          <Button
            small
            primary
            onClick={() => setAddTeamMemberDialogOpen(true)}
          >
            Add team member
          </Button>
        </div>
        <div className="flex gap-12 py-6">
          <div className="flex items-center w-full">
            <Input
              id="memberName"
              slotProps={{
                input: {
                  className: 'focus:outline-none  bg-gray-background',
                },
              }}
              className="px-6 py-2 w-full rounded-lg border text-text bg-gray-background"
              startAdornment={<Search />}
              placeholder="Search members"
              onChange={onInputChange}
            />
          </div>
        </div>
        <div className="flex items-center pb-4">
          <p className="text-xs font-medium">View:</p>
          <DropDown
            toggleButtonLabel={'All'}
            slotProps={{
              button: {
                className: 'text-xs font-medium',
              },
            }}
          >
            <MenuItem key={'All'} className="text-xs">
              {'All'}
            </MenuItem>
          </DropDown>
        </div>

        <ul>
          <li>
            <div className="flex justify-between text-sm font-semibold p-4">
              <div className="flex flex-col mr-2 w-1/3">Name</div>
              <div className="flex flex-col items-center w-1/3">
                Learning Budget
              </div>
              <div className="flex items-center justify-end w-1/3">Actions</div>
            </div>
          </li>
          {hasNoMembers ? (
            <li>
              <div className="flex flex-col bg-mydra-gray text-mydra-black h-full p-3 items-center justify-center rounded">
                <GroupsOutlinedIcon
                  className="text-mydra-purple mt-10"
                  style={{
                    width: 68,
                    height: 62,
                  }}
                />
                <p className="mt-2 text-xl font-medium">{`Team Members`}</p>
                <p className="mb-10">{`Your team members will show up here once added. `}</p>
              </div>
            </li>
          ) : (
            filteredMembers.map((user: IUser) => {
              return (
                <li key={user._id} className="mb-2">
                  <div className="flex justify-between rounded border p-4">
                    <div className="flex flex-col w-1/3">
                      <span className="font-semibold">
                        {user.firstName} {user.lastName}
                      </span>
                      <span className="text-xs">{user.email}</span>
                    </div>
                    {team.learningBudgetLimit && (
                      <div className="flex items-center justify-center w-1/3">
                        <Chip
                          icon={<GroupsIcon />}
                          label={`${team.learningBudgetLimit} €`}
                        />
                      </div>
                    )}
                    <span className="flex items-center justify-end w-1/3">
                      <Button
                        small
                        dropdownItems={
                          <>
                            <MenuItem
                              onClick={() =>
                                navigate({ to: `/employees/${user._id}` })
                              }
                            >
                              <VisibilityOutlinedIcon />
                              View member
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                setLearningBudgetEmployeeId(user._id!)
                              }
                            >
                              <AccountBalanceWalletOutlinedIcon />
                              Set learning budget
                            </MenuItem>
                            <MenuItem
                              onClick={() => setRemoveMemberId(user._id!)}
                            >
                              <DeleteOutlinedIcon />
                              Delete
                            </MenuItem>
                          </>
                        }
                        onClick={() =>
                          navigate({ to: `/employees/${user._id}` })
                        }
                      >
                        View
                      </Button>
                    </span>
                  </div>
                </li>
              );
            })
          )}
        </ul>
      </div>
      <AddTeamMemberDialog
        open={isAddTeamMemberDialogOpen}
        onClose={() => setAddTeamMemberDialogOpen(false)}
        onSuccess={() => {
          setAddTeamMemberDialogOpen(false);
        }}
        team={team}
      />
      <EmployeeLearningBudgetDialog
        open={!!learningBudgetEmployeeId}
        onClose={() => setLearningBudgetEmployeeId('')}
        onSuccess={() => {
          setLearningBudgetEmployeeId('');
        }}
        employee={learningBudgetEmployeeId}
      />
      <RemoveTeamMemberDialog
        open={!!removeMemberId}
        onClose={() => setRemoveMemberId('')}
        onSuccess={() => {
          setRemoveMemberId('');
        }}
        team={teamId}
        user={removeMemberId}
      />
    </div>
  );
}
