import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ListPaymentsPage } from '@/modules/payments/pages/ListPaymentsPage';
import { PaymentsSearchParams } from '@/modules/payments/types';
import { PaymentStatus } from '@/types/payment';
import { createFileRoute } from '@tanstack/react-router';
import * as yup from 'yup';

export const Route = createFileRoute('/_admin/payments/')({
  validateSearch: (search: Record<string, unknown>): PaymentsSearchParams => {
    return {
      page: yup.number().integer().min(1).optional().validateSync(search.page),
      limit: yup
        .number()
        .integer()
        .min(1)
        .optional()
        .validateSync(search.limit),
      status: yup
        .lazy(val =>
          Array.isArray(val)
            ? yup
                .array(
                  yup.string().oneOf(Object.values(PaymentStatus)).required(),
                )
                .optional()
            : yup.string().oneOf(Object.values(PaymentStatus)).optional(),
        )
        .validateSync(search.status),
    };
  },
  component: () => {
    return (
      <AdminLayout>
        <ListPaymentsPage />
      </AdminLayout>
    );
  },
});
