import { InvitationService } from '@/services/invitation';
import { ApiErrorResponse, ApiReactMutationOptions } from '@/types/api';
import {
  ApiIndividualInvitation,
  IInvitationPayload,
} from '@/types/invitation';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useInvitationsMutation(
  options: ApiReactMutationOptions<
    ApiIndividualInvitation,
    IInvitationPayload
  > = {},
) {
  const mutationKey = [InvitationService.PATH];
  const queryClient = useQueryClient();

  return useMutation<
    ApiIndividualInvitation,
    ApiErrorResponse,
    IInvitationPayload
  >({
    ...options,
    mutationKey: mutationKey,
    mutationFn: (payload: IInvitationPayload) => {
      if (!payload.team) {
        payload.team = undefined;
      }

      return InvitationService.postInvitation(payload);
    },
    onSettled: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: [InvitationService.PATH],
        exact: false,
      });

      options?.onSettled?.(...args);
    },
  });
}
