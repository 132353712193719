import { API_URL } from '@/constants';
import { ApiErrorResponse } from '@/types/api';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export class ApiError extends Error implements ApiErrorResponse {
  statusCode: number;
  error: string;

  constructor(message: string, statusCode: number, error?: string) {
    super(message);
    this.name = 'ApiError';

    this.error = error ?? 'ServerError';
    this.statusCode = statusCode;
  }
}

const request = async (
  endpoint: string,
  method: HttpMethod,
  options: RequestInit,
) => {
  const url = new URL(endpoint, API_URL);
  const isMultiPartFormData = options.body instanceof FormData;

  const res = await fetch(url, {
    method: method ?? 'GET',
    headers: {
      'x-mydra-app': 'partnerDashboard',
      ...(isMultiPartFormData ? {} : { 'Content-Type': 'application/json' }),
      ...options?.headers,
    },
    credentials: 'include',
    ...options,
  });

  if (!res.ok) {
    let json;

    try {
      json = await res.json();
    } catch {
      json = {};
    }

    throw new ApiError(
      json.message ?? res.statusText,
      res.status,
      json.error ?? '',
    );
  }

  try {
    return await res.json();
  } catch {
    return undefined;
  }
};

export class ApiService {
  static async get(endpoint: string, options?: RequestInit) {
    return await request(endpoint, 'GET', { ...options });
  }

  static async post(endpoint: string, payload: unknown, options?: RequestInit) {
    const isMultiPartFormData = payload instanceof FormData;
    return await request(endpoint, 'POST', {
      body: isMultiPartFormData ? payload : JSON.stringify(payload),
      ...options,
    });
  }

  static async put(endpoint: string, payload: unknown, options?: RequestInit) {
    return await request(endpoint, 'PUT', {
      body: JSON.stringify(payload),
      ...options,
    });
  }

  static async patch(
    endpoint: string,
    payload: unknown,
    options?: RequestInit,
  ) {
    return await request(endpoint, 'PATCH', {
      body: JSON.stringify(payload),
      ...options,
    });
  }

  static async delete(endpoint: string, options?: RequestInit) {
    return await request(endpoint, 'DELETE', { ...options });
  }

  static async review(
    endpoint: string,
    payload: unknown,
    options?: RequestInit,
  ) {
    return await request(endpoint, 'PATCH', {
      body: JSON.stringify(payload),
      ...options,
    });
  }

  constructor() {
    throw new Error('This class should not be instantiated');
  }
}
