import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Button } from '@/modules/common/components/Button';
import { DropDown } from '@/modules/common/components/DropDown';
import { MenuItem } from '@/modules/common/components/MenuItem';
import { DeleteTeamDialog } from '@/modules/employees/components/DeleteTeamDialog';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import { ITeam } from '@/types/team';
import { TeamLearningBudgetDialog } from '../TeamLearningBudgetDialog';

type ListTeams = {
  teams: ITeam[];
  isLoading?: boolean;
} & React.TableHTMLAttributes<HTMLUListElement>;

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.substr(0, 2)}`,
  };
}

export function ListTeams({ teams, isLoading, ...props }: ListTeams) {
  const hasNoTeams = teams.length === 0 && !isLoading;

  const navigate = useNavigate();

  const [learningBudgetTeamId, setLearningBudgetTeamId] = useState('');
  const [deleteTeamId, setDeleteTeamId] = useState('');

  return (
    <>
      <div className="flex items-center pb-4">
        <p className="text-xs font-medium">View:</p>
        <DropDown
          toggleButtonLabel={'All'}
          slotProps={{
            button: {
              className: 'text-xs font-medium',
            },
          }}
        >
          <MenuItem key={'All'} className="text-xs">
            {'All'}
          </MenuItem>
        </DropDown>
      </div>

      <ul {...props}>
        <li>
          <div className="flex justify-between text-sm font-semibold p-4">
            <div className="flex flex-col mr-2 w-1/3">Team Name</div>
            <div className="flex flex-col items-center w-1/3">
              Learning Budget
            </div>
            <div className="flex items-center justify-end w-1/3">Actions</div>
          </div>
        </li>
        {hasNoTeams ? (
          <li>
            <div className="flex flex-col bg-mydra-gray text-mydra-black h-full p-3 items-center justify-center rounded">
              <GroupsOutlinedIcon
                className="text-mydra-purple mt-10"
                style={{
                  width: 68,
                  height: 62,
                }}
              />
              <p className="mt-2 text-xl font-medium">{`Create a Team`}</p>
              <p className="mb-10">{`Your created teams will be displayed here once set up.`}</p>
            </div>
          </li>
        ) : (
          teams.map(team => {
            return (
              <li key={team._id} className="mb-2">
                <div className="flex justify-between rounded border p-4">
                  <div className="flex items-center mr-2 w-1/3">
                    <Avatar {...stringAvatar(team.name)} className="mr-2" />
                    <div className="flex flex-col">
                      <span className="font-semibold">{team.name}</span>
                      <span className="text-xs">
                        {team.members.length} members
                      </span>
                    </div>
                  </div>
                  {team.learningBudgetLimit && (
                    <div className="flex items-center justify-center w-1/3">
                      <Chip
                        icon={<GroupsIcon />}
                        label={`${team.learningBudgetLimit} €`}
                      />
                    </div>
                  )}
                  <span className="flex items-center justify-end w-1/3">
                    <Button
                      small
                      dropdownItems={
                        <>
                          <MenuItem
                            onClick={() =>
                              navigate({ to: `/teams/${team._id}` })
                            }
                          >
                            <VisibilityOutlinedIcon />
                            View team
                          </MenuItem>
                          <MenuItem
                            onClick={() => setLearningBudgetTeamId(team._id!)}
                          >
                            <AccountBalanceWalletOutlinedIcon />
                            Set learning budget
                          </MenuItem>
                          <MenuItem onClick={() => setDeleteTeamId(team._id!)}>
                            <DeleteOutlinedIcon />
                            Delete
                          </MenuItem>
                        </>
                      }
                      onClick={() => navigate({ to: `/teams/${team._id}` })}
                    >
                      Edit
                    </Button>
                  </span>
                </div>
              </li>
            );
          })
        )}
      </ul>
      <TeamLearningBudgetDialog
        open={!!learningBudgetTeamId}
        onClose={() => setLearningBudgetTeamId('')}
        onSuccess={() => {
          setLearningBudgetTeamId('');
        }}
        team={learningBudgetTeamId}
      />
      <DeleteTeamDialog
        open={!!deleteTeamId}
        onClose={() => setDeleteTeamId('')}
        onSuccess={() => {
          setDeleteTeamId('');
        }}
        team={deleteTeamId}
      />
    </>
  );
}
