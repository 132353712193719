import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ViewTeamPage } from '@/modules/employees/pages/ViewTeamPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_admin/teams/$teamId')({
  component: () => {
    return (
      <AdminLayout>
        <ViewTeamPage />
      </AdminLayout>
    );
  },
});

