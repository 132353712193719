import { ApiIndividualTeam, ApiListTeams, ITeamPayload } from '@/types/team';
import qs from 'query-string';
import { ApiService } from './api';

export class TeamService extends ApiService {
  static PATH = '/teams';

  static async getTeams(company: string): Promise<ApiListTeams> {
    const queryString = qs.stringify({ company });
    return ApiService.get(`${TeamService.PATH}?${queryString}`);
  }

  static async getTeam(team: string): Promise<ApiIndividualTeam> {
    return ApiService.get(`${TeamService.PATH}/${team}`);
  }

  static async postTeam(payload: ITeamPayload): Promise<ApiIndividualTeam> {
    return ApiService.post(TeamService.PATH, payload);
  }

  static async patchTeam(
    team: string,
    payload: Partial<ITeamPayload>,
  ): Promise<ApiIndividualTeam> {
    return ApiService.patch(`${TeamService.PATH}/${team}`, payload);
  }

  static async deleteTeam(team: string): Promise<void> {
    return ApiService.delete(`${TeamService.PATH}/${team}`);
  }

  static async addTeamMember(
    team: string,
    payload: { email: string },
  ): Promise<void> {
    return ApiService.post(`${TeamService.PATH}/${team}/members`, payload);
  }

  static async removeTeamMember(team: string, user: string): Promise<void> {
    return ApiService.delete(`${TeamService.PATH}/${team}/members/${user}`);
  }
}
