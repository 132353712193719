import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { IUser, IUserWithEducationProvider } from './user';

export type ApiIndividualEducationProvider =
  ApiIndividualResponse<IEducationProvider>;
export type ApiPaginatedEducationProvider =
  ApiPaginatedResponse<IEducationProvider>;

export type ApiStripeLinks = ApiIndividualResponse<{
  loginLink?: string | null;
  connectAccountLink?: string | null;
}>;

export enum PaymentProvider {
  Stripe = 'STRIPE',
}

export enum PaymentStatus {
  Authorized = 'AUTHORIZED',
  Pending = 'PENDING',
  Paid = 'PAID',
  Failed = 'FAILED',
}

export type IPaymentProvider = {
  type?: PaymentProvider;
  externalId?: string;
  loginEnabled?: boolean;
  status?: PaymentStatus;
};

export type IEducationProvider = {
  _id?: string;
  name: string;
  slug?: string;
  logoUrl: string;
  paymentProvider?: IPaymentProvider;
  isAffiliate?: boolean;
  country: string;
  countryCode: string;
  agreements?: [];
  users?: IUser[] | string[];
  hasAgreement?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type IEducationProviderWithoutUserObjects = Omit<
  IEducationProvider,
  'users'
> & {
  users?: IUserWithEducationProvider[];
};
