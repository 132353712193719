import { LandingPagesService } from '@/services/landing-pages';
import { ApiErrorResponse, ApiReactMutationOptions } from '@/types/api';
import {
  ApiIndividualLandingPage,
  ILandingPageRegenerationParams,
  ILandingPageUpdatePayload,
} from '@/types/landing-page';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseLandingPageMutationFnSignature = {
  id: string;
  updatedData: ILandingPageUpdatePayload;
  regenerationParams?: ILandingPageRegenerationParams;
};

export function useLandingPageMutation(
  options: ApiReactMutationOptions<
    ApiIndividualLandingPage,
    UseLandingPageMutationFnSignature
  > = {},
) {
  const mutationKey = [LandingPagesService.PATH];
  const queryClient = useQueryClient();

  return useMutation<
    ApiIndividualLandingPage,
    ApiErrorResponse,
    UseLandingPageMutationFnSignature
  >({
    ...options,
    mutationKey: mutationKey,
    mutationFn: ({ id, updatedData, regenerationParams }) => {
      return LandingPagesService.patchLandingPage(
        id,
        updatedData,
        regenerationParams,
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [LandingPagesService.PATH],
        exact: false,
      });
    },
  });
}
