import { ICourse } from '@/types/course';
import {
  AccountCircleOutlined,
  DateRange,
  GppGoodOutlined,
  Preview,
} from '@mui/icons-material';
import { isValid } from 'date-fns';
import { CourseEditingSection } from '../CourseEditingSection';
import { CourseItem } from '../CourseItem';

type CourseAdministrativeDetailsProps = {
  course:
    | Pick<ICourse, 'published' | 'createdAt' | 'updatedAt' | '_id'>
    | undefined;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseAdministrativeDetails({
  course,
  ...props
}: CourseAdministrativeDetailsProps) {
  const {
    createdAt: rawCreatedAt,
    updatedAt: rawUpdatedAt,
    _id,
    published,
  } = course ?? {};

  const [createdAt, updatedAt] = [rawCreatedAt, rawUpdatedAt].map(
    v => new Date(v ?? ''),
  );

  return (
    <CourseEditingSection
      {...props}
      sectionName="course-administrative-details"
      sectionTitle="Administrative details"
      defaultSection={
        <div className="flex flex-col gap-4">
          <CourseItem label="Published:" icon={<Preview />}>
            {published ? 'Yes' : 'No'}
          </CourseItem>
          <CourseItem label="Created at:" icon={<DateRange />}>
            {isValid(createdAt)
              ? `${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`
              : 'Not available'}
          </CourseItem>
          <CourseItem label="Updated at:" icon={<DateRange />}>
            {isValid(updatedAt)
              ? `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleTimeString()}`
              : 'Not available'}
          </CourseItem>
          <CourseItem label="Created by:" icon={<AccountCircleOutlined />}>
            Not available
          </CourseItem>
          <CourseItem label="Updated by:" icon={<AccountCircleOutlined />}>
            Not available
          </CourseItem>
          <CourseItem label="Course ID:" icon={<GppGoodOutlined />}>
            {_id ?? 'Not available'}
          </CourseItem>
        </div>
      }
    />
  );
}
