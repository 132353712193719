import { PaymentService } from '@/services/payments';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactQueryOptions,
  IncludeApiQueryParam,
} from '@/types/api';
import { ApiPaginatedPayments, ApiPayPayments } from '@/types/payment';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const getPaymentsQueryOptions = (
  filters?: ApiQueryParams,
  pagination?: ApiPaginationParams,
  include?: IncludeApiQueryParam,
  options?: ApiReactQueryOptions<ApiPaginatedPayments>,
) => ({
  queryKey: [PaymentService.PATH, filters, pagination],
  queryFn: async () =>
    await PaymentService.getPayments(filters, pagination, include),
  ...options,
});

export const useGetPayments = (
  filters: ApiQueryParams = {},
  pagination: ApiPaginationParams = {},
  include?: IncludeApiQueryParam,
  options?: ApiReactQueryOptions<ApiPaginatedPayments>,
) => {
  return useQuery<ApiPaginatedPayments, ApiErrorResponse>(
    getPaymentsQueryOptions(filters, pagination, include, options),
  );
};

export function usePayPayments() {
  const queryClient = useQueryClient();
  const mutationKey = [PaymentService.PATH, 'pay'];

  return useMutation<
    ApiPayPayments,
    ApiErrorResponse,
    {
      paymentIds: string[];
    }
  >({
    mutationKey: mutationKey,
    mutationFn: async data => {
      if (!data.paymentIds?.length) {
        throw new Error('Payments are required for mutation');
      }

      return PaymentService.pay(data);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [PaymentService.PATH],
        exact: false,
      });
    },
  });
}
