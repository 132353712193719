import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteOutline } from '@mui/icons-material';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { CourseProgrammeStructureSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';

type FormValues = Pick<ICourse, 'programmeStructure'>;

type CourseProgramProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseProgram({ onSubmit, ...props }: CourseProgramProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const programmeStructure = getValues('programmeStructure');

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      programmeStructure: programmeStructure ?? [],
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CourseProgrammeStructureSchema),
  });

  const {
    fields: programStructureFields,
    append: appendProgramStructureField,
    remove: removeProgramStructureField,
  } = useFieldArray({
    control,
    name: 'programmeStructure',
  });

  const handleAddProgramStructure = () => {
    appendProgramStructureField({
      title: '',
      description: '',
      subtitle: '',
    });
  };

  const hasError = !!errors.programmeStructure;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      className={`${props.className}`}
      sectionName="course-program"
      sectionTitle="Programme Structure"
      defaultSection={
        <div className="flex flex-col">
          {programmeStructure?.length ? (
            <ul className="flex flex-col gap-2">
              {(programmeStructure ?? []).map(
                ({ title, description }, index) => (
                  <li key={index} className="p-4 border rounded-xl">
                    <p className="text-sm font-medium text-mydra-black">
                      {title}
                    </p>
                    <p>{description}</p>
                  </li>
                ),
              )}
            </ul>
          ) : (
            <p className="text-text">No programme structure set</p>
          )}
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-8">
          <button
            className="absolute top-0 right-0 px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
            onClick={handleAddProgramStructure}
          >
            <div className="flex items-center">
              <Add sx={{ fontSize: 18 }} /> Add Module
            </div>
          </button>

          {programStructureFields.map((programStructureField, index) => (
            <div key={programStructureField.id} className="flex flex-col gap-2">
              <input
                {...register(`programmeStructure.${index}.title`)}
                className="p-4 border rounded-xl"
                placeholder="Title"
              />
              {formErrors?.programmeStructure?.[index]?.title?.message && (
                <p className="mt-1 text-red-500">
                  {formErrors?.programmeStructure?.[index]?.title?.message}
                </p>
              )}
              <TextareaAutosize
                className="p-4 border rounded-xl"
                {...register(`programmeStructure.${index}.description`)}
                placeholder="Description"
              />
              {formErrors?.programmeStructure?.[index]?.description
                ?.message && (
                <p className="mt-1 text-red-500">
                  {
                    formErrors?.programmeStructure?.[index]?.description
                      ?.message
                  }
                </p>
              )}
              <button
                className="self-start px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
                onClick={() => removeProgramStructureField(index)}
              >
                <div className="flex items-center gap-2">
                  <DeleteOutline sx={{ fontSize: 18 }} /> Delete
                </div>
              </button>
            </div>
          ))}
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() => reset({ programmeStructure })}
    ></CourseEditingSection>
  );
}
