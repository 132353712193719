import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { PaginationButton } from '@/modules/common/components/PaginationButton';
import { ICompany } from '@/types/company';
import { TaxBenefitsProviderCompanyLinkStatus } from '@/types/tax-benefits-provider';
import { ModalProps } from '@mui/base';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { RowData, Table } from '@tanstack/react-table';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

export function TableHeader({
  children,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <div {...props} className={`flex w-full ${props.className ?? ''}`}>
      <span className={`text-xs text-text w-full ${props.className ?? ''}`}>
        {children}
      </span>
    </div>
  );
}

type TableFooterProps<T extends RowData> = {
  tableInstance: Table<T>;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

export function TableFooter({
  tableInstance,
  ...props
}: TableFooterProps<ICompany>) {
  function formatTableFooter(
    pageIndex: number,
    pageSize: number,
    totalResults: number,
  ): string {
    const startIndex = pageIndex * pageSize + 1;
    let endIndex = startIndex + pageSize - 1;
    if (endIndex > totalResults) {
      endIndex = totalResults;
    }

    return `Show results ${startIndex}-${endIndex} of ${totalResults}`;
  }

  const {
    pagination: { pageIndex, pageSize },
  } = tableInstance.getState();

  const columns = tableInstance.getAllColumns();
  const rowCount = tableInstance.getRowCount();
  const footerMessage = formatTableFooter(pageIndex, pageSize, rowCount);
  const pageOptions = tableInstance.getPageOptions();

  const canNavigateToNextPage = tableInstance.getCanNextPage();
  const canNavigateToPreviousPage = tableInstance.getCanPreviousPage();

  return (
    <>
      {pageOptions.length > 1 ? (
        <tfoot {...props} className="w-full">
          <tr>
            <td colSpan={columns.length}>
              <div className="flex py-2 w-full">
                <div className="flex gap-6 items-center ml-auto">
                  <p className="text-xs tracking-tight leading-7 text-text">
                    {footerMessage}
                  </p>
                  <div className="flex gap-2">
                    <PaginationButton
                      onClick={tableInstance.previousPage}
                      disabled={!canNavigateToPreviousPage}
                    >
                      <ChevronLeft />
                    </PaginationButton>
                    {pageOptions.slice(pageIndex, pageIndex + 2).map(page => (
                      <PaginationButton
                        key={page}
                        onClick={() => tableInstance.setPageIndex(page)}
                        className={
                          pageIndex === page ? '!bg-gray-background' : ''
                        }
                      >
                        {page + 1}
                      </PaginationButton>
                    ))}
                    <PaginationButton
                      onClick={tableInstance.nextPage}
                      disabled={!canNavigateToNextPage}
                    >
                      <ChevronRight />
                    </PaginationButton>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      ) : null}
    </>
  );
}

type ReviewInviteModalProps = Omit<
  ModalProps,
  'title' | 'children' | 'onClose'
> & {
  companyLinkInfo: {
    companyName: string;
    companyLinkId: string;
  } | null;
  onClose: (data: ReviewInviteFormValues | null) => Promise<void>;
};

export type ReviewInviteFormValues = {
  status: TaxBenefitsProviderCompanyLinkStatus | null;
  companyLinkId: string;
};

export function ReviewInviteModal({
  companyLinkInfo = null,
  onClose,
  ...props
}: ReviewInviteModalProps) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting },
  } = useForm<ReviewInviteFormValues>({
    defaultValues: {
      status: null,
      companyLinkId: companyLinkInfo?.companyLinkId,
    },
  });

  const onSubmit = useCallback(
    async (data: ReviewInviteFormValues) => {
      await onClose(data);
    },
    [onClose],
  );

  return (
    <Modal
      {...props}
      title={`Please review this invite`}
      showCloseButton
      onClose={() => onClose(null)}
      className="max-w-2xl"
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <p>
          The company <strong>{companyLinkInfo?.companyName}</strong> has
          invited you to be their tax benefits provider. You will manage
          applications of their employees to approve/reject and pay applications
          to tax benefits like Retribución Flexible.
        </p>

        <input type="hidden" {...register('companyLinkId')} />

        <div className="flex gap-3 justify-end">
          <Button
            disabled={isSubmitting}
            className="text-red-500"
            type="submit"
            onClick={() => {
              setValue('status', TaxBenefitsProviderCompanyLinkStatus.Rejected);
            }}
          >
            Reject
          </Button>
          <Button
            primary
            isLoading={isSubmitting}
            type="submit"
            onClick={() => {
              setValue('status', TaxBenefitsProviderCompanyLinkStatus.Accepted);
            }}
          >
            Accept
          </Button>
        </div>
      </form>
    </Modal>
  );
}
