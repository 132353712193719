import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { EXTERNAL_ROUTES } from '@/constants';
import { TableFooter } from '@/modules/common/components/TableFooter';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ILandingPage } from '@/types/landing-page';
import { IUser } from '@/types/user';
import { CancelPresentation } from '@mui/icons-material';
import { useLandingPageDeletion } from '../../hooks/useLandingPageDeletion';
import { useLandingPageMutation } from '../../hooks/useLandingPageMutation';
import { useLandingPageMutationConfirmation } from './ListLandingPagesTable.components';
import { getListLandingPagesTableColumns } from './ListLandingPagesTable.constants';

type ListLandingPagesTable = {
  landingPages: ILandingPage[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
  onMutation: () => Promise<unknown>;
} & React.TableHTMLAttributes<HTMLTableElement>;

export function ListLandingPagesTable({
  landingPages,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  onMutation,
  ...props
}: ListLandingPagesTable) {
  const { showNotification } = useNotificationContext();
  const { Modal, showPublishConfirmation, showDeleteConfirmation } =
    useLandingPageMutationConfirmation();

  const { mutateAsync: updateLandingPage } = useLandingPageMutation({
    onSuccess: () => {
      showNotification(
        'Landing page updated successfully',
        NotificationStyle.SUCCESS,
      );
    },
    onError: () => {
      showNotification(
        'Failed to update landing page',
        NotificationStyle.ERROR,
      );
    },
  });
  const { mutateAsync: deleteLandingPage } = useLandingPageDeletion({
    onSuccess: () => {
      showNotification(
        'Landing page deleted successfully',
        NotificationStyle.SUCCESS,
      );
    },
    onError: () => {
      showNotification(
        'Failed to delete landing page',
        NotificationStyle.ERROR,
      );
    },
  });

  const onPublishToggle = async (
    landingPageId: string,
    isPublished: boolean,
  ) => {
    showPublishConfirmation(async () => {
      await updateLandingPage({
        id: landingPageId,
        updatedData: { isPublished: !isPublished },
      });
      await onMutation();
    }, isPublished);
  };

  const onDelete = async (landingPageId: string) => {
    showDeleteConfirmation(async () => {
      await deleteLandingPage(landingPageId);
      await onMutation();
    });
  };

  const onPreview = async (landingPageId: string) => {
    const landingPage = landingPages.find(lp => lp._id === landingPageId);

    window.open(
      EXTERNAL_ROUTES.LANDING_PAGE_URL.replace(
        ':lang',
        landingPage?.language ?? 'en',
      ).replace(':id', landingPageId),
      '_blank',
    );
  };

  const onRegenerate = async (landingPageId: string) => {
    await updateLandingPage({
      id: landingPageId,
      updatedData: {},
      regenerationParams: { regenerate: true },
    });
  };

  const columns = getListLandingPagesTableColumns({
    onPublishToggle,
    onDelete,
    onPreview,
    onRegenerate,
  });

  const table = useReactTable<ILandingPage>({
    data: landingPages,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel<IUser>(),
  });

  const rows = table.getRowModel().rows;

  const hasNoLandingPages = rows.length === 0 && !isLoading;

  return (
    <>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoLandingPages ? 'h-full' : ''}`}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-3 first:px-0 last:px-0"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoLandingPages ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="p-0 h-full text-center"
              >
                <div className="flex flex-col justify-center p-3 h-full align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className="text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">
                      No landing pages found
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
      {Modal}
    </>
  );
}
