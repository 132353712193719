export const moneyToInteger = (value: string) => {
  if (!value) {
    return 0;
  }

  return Math.round(parseFloat(value) * 100);
};

export const integerToMoney = (value: string) => {
  if (!value) {
    return 0;
  }

  return parseFloat((parseInt(value) / 100).toFixed(2));
};

export function roundHalfToEven(value: number) {
  if (Math.abs(value % 1) === 0.5) {
    return 2 * Math.round(value / 2);
  }
  return Math.round(value);
}
