import { ICourse } from '@/types/course';
import { ModeEdit } from '@mui/icons-material';
import { CoursePublicationStatus } from '../CousePublicationStatus';

type NavBarTitleProps = {
  course: Pick<ICourse, 'name' | 'published'> | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

export function CourseNavBarTitle({ course, ...props }: NavBarTitleProps) {
  return (
    <div {...props} className="flex items-center gap-5">
      {course?.name ?? ''}
      <ModeEdit sx={{ fontSize: 18 }} />
      <CoursePublicationStatus published={!!course?.published} />
    </div>
  );
}
