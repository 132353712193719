import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { PaymentProvider, PaymentStatus } from '@/types/education-provider';
import { useNavigate } from '@tanstack/react-router';
import { useGetConnectStripeAccountLinks } from '../hooks/useGetStripeLinksEducationProvider';
import { FlowModes } from '../types';

type StripeBannerProps = React.HTMLAttributes<HTMLDivElement>;

export function StripeBanner(props: StripeBannerProps) {
  const navigate = useNavigate();

  const { educationProvider } = useCurrentUserContext() ?? {};
  const { paymentProvider } = educationProvider ?? {};

  const shouldConnectStripeAccount =
    paymentProvider?.type === PaymentProvider.Stripe &&
    paymentProvider?.status === PaymentStatus.Pending;

  const { data: { data: { connectAccountLink = '' } = {} } = {} } =
    useGetConnectStripeAccountLinks(educationProvider?._id, {
      enabled: shouldConnectStripeAccount,
    });

  const redirectToStripeConnect = () => {
    return navigate({
      to: '/stripe',
      search: {
        mode: FlowModes.CONNECT_ACCOUNT,
        id: educationProvider?._id ?? '',
      },
    });
  };

  return shouldConnectStripeAccount && connectAccountLink ? (
    <div
      {...props}
      className={`flex flex-col gap-3 border rounded-xl px-3 py-6 overflow-hidden bg-mydra-violet/40 ${props.className ?? ''} `}
    >
      <div className="flex items-center gap-1">
        <img
          src="/images/stripe-logo.png"
          alt="Stripe logo"
          className="w-8 h-8"
        />
        <h1 className="text-sm font-medium">Stripe account</h1>
      </div>
      <p className="text-xs">
        Set up your stripe account to start your payments onboarding.
      </p>
      <Button primary small onClick={redirectToStripeConnect}>
        Let's go
      </Button>
    </div>
  ) : null;
}
