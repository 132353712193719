import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ViewEmployeePage } from '@/modules/employees/pages/ViewEmployeePage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_admin/employees/$employeeId')({
  component: () => {
    return (
      <AdminLayout>
        <ViewEmployeePage />
      </AdminLayout>
    );
  },
});
