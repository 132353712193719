import { ApiPaginationParams, ApiQueryParams } from '@/types/api';
import {
  ApiIndividualCourse,
  ApiIndividualCourseId,
  ApiPaginatedCourse,
  ICourse,
  ICourseCreatePayloadWithAI,
  ICourseUpdatePayload,
} from '@/types/course';
import qs from 'query-string';
import { ApiService } from './api';

export class CourseService extends ApiService {
  static PATH = '/courses';
  static DELETE = '/courses';

  static async getCourses(
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedCourse> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${CourseService.PATH}?${queryString}`, {
      headers: {
        'accept-language': 'en',
      },
    });
  }

  static async getCourse(id: string): Promise<ApiIndividualCourse> {
    return ApiService.get(`${CourseService.PATH}/${id}`, {
      headers: {
        'accept-language': 'en',
      },
    });
  }

  static async putCourse(
    id: string,
    data: ICourse,
  ): Promise<ApiIndividualCourse> {
    return ApiService.put(`${CourseService.PATH}/${id}`, data);
  }

  static async patchCourse(
    id: string,
    data: Partial<ICourseUpdatePayload>,
  ): Promise<ApiIndividualCourse> {
    return ApiService.patch(`${CourseService.PATH}/${id}`, data);
  }

  static async postCourse(
    data: Partial<ICourseUpdatePayload>,
  ): Promise<ApiIndividualCourse> {
    return ApiService.post(CourseService.PATH, data);
  }

  static async postCourseWithAi(
    data: ICourseCreatePayloadWithAI,
  ): Promise<ApiIndividualCourseId> {
    return ApiService.post(
      `${CourseService.PATH}?ai=true`,
      data,
    ) as Promise<ApiIndividualCourseId>;
  }

  static async deleteCourse(id: string): Promise<ApiIndividualCourse> {
    return ApiService.delete(`${CourseService.DELETE}/${id}`);
  }
}
