import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableFooter } from './ListApplicationsTable.components';
import {
  getListApplicationsTableColumns,
  getReviewerPendingValidations,
} from './ListApplicationsTable.constants';

import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { Modal, ModalProps } from '@/modules/common/components/Modal';
import { Switch } from '@/modules/common/components/Switch';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import {
  ApplicationValidationReviewer,
  ApplicationValidationType,
  IApplicationWithOrderAndCourse,
} from '@/types/application';
import { UserRole } from '@/types/user';
import {
  CancelPresentation,
  Check,
  Close,
  ReviewsOutlined,
} from '@mui/icons-material';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useReviewApplicationMutation } from '../../hooks/useApplications';

type ListApplicationsTable = {
  applications: IApplicationWithOrderAndCourse[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
} & React.TableHTMLAttributes<HTMLTableElement>;

const getValidationNames = (
  applications: IApplicationWithOrderAndCourse[],
  userRoles: UserRole[],
) => {
  const validationNames = applications.reduce((acc, application) => {
    if (userRoles.includes(UserRole.Employer)) {
      getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.Employer,
      ).forEach(validation => acc.add(validation.name));
    }

    if (userRoles.includes(UserRole.TaxBenefitsProvider)) {
      getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.FlexibleRemunerationProvider,
      ).forEach(validation => acc.add(validation.name));
    }

    if (userRoles.includes(UserRole.EducationProvider)) {
      getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.EducationProvider,
      ).forEach(validation => acc.add(validation.name));
    }

    return acc;
  }, new Set<ApplicationValidationType>());

  return Array.from(validationNames);
};

const formatReviewCalls = (
  applications: IApplicationWithOrderAndCourse[],
  review: Partial<ReviewModalState>,
  userRoles: UserRole[],
) => {
  return applications.flatMap(application => {
    const validations = [
      ...getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.Employer,
      ),
      ...getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.FlexibleRemunerationProvider,
      ),
      ...getReviewerPendingValidations(
        application.validations || [],
        ApplicationValidationReviewer.EducationProvider,
      ),
    ].filter(validation => {
      if (userRoles.includes(UserRole.Employer)) {
        return validation.reviews.some(
          review => review.reviewer === ApplicationValidationReviewer.Employer,
        );
      }

      if (userRoles.includes(UserRole.TaxBenefitsProvider)) {
        return validation.reviews.some(
          review =>
            review.reviewer ===
            ApplicationValidationReviewer.FlexibleRemunerationProvider,
        );
      }

      if (userRoles.includes(UserRole.EducationProvider)) {
        return validation.reviews.some(
          review =>
            review.reviewer === ApplicationValidationReviewer.EducationProvider,
        );
      }

      return false;
    });

    const reviewCalls = [];

    if (
      validations.some(
        validation =>
          validation.name === ApplicationValidationType.FlexibleRemuneration,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: !!review.taxBenefits,
          validationType: ApplicationValidationType.FlexibleRemuneration,
        },
      });
    }

    if (
      validations.some(
        validation =>
          validation.name === ApplicationValidationType.LearningBudget,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: !!review.learningBudget,
          validationType: ApplicationValidationType.LearningBudget,
        },
      });
    }

    if (
      validations.some(
        validation => validation.name === ApplicationValidationType.Skills,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: !!review.skills,
          validationType: ApplicationValidationType.Skills,
        },
      });
    }

    if (
      validations.some(
        validation => validation.name === ApplicationValidationType.Capacity,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: !!review.capacity,
          validationType: ApplicationValidationType.Capacity,
        },
      });
    }

    if (
      validations.some(
        validation =>
          validation.name === ApplicationValidationType.AdmissionProcess,
      )
    ) {
      reviewCalls.push({
        id: application._id!,
        updatedData: {
          isApproved: !!review.admissionProcess,
          validationType: ApplicationValidationType.AdmissionProcess,
        },
      });
    }

    return reviewCalls;
  });
};

enum ApplicationValidationStateType {
  LearningBudget = 'learningBudget',
  TaxBenefits = 'taxBenefits',
  Skills = 'skills',
  Capacity = 'capacity',
  AdmissionProcess = 'admissionProcess',
}

type ReviewModalState = {
  [key in ApplicationValidationStateType]: boolean;
};

export function ListApplicationsTable({
  applications,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  ...props
}: ListApplicationsTable) {
  const { currentUser: { roles: userRoles = [] } = {} } =
    useCurrentUserContext() ?? {};
  const { mutateAsync: reviewApplication } =
    useReviewApplicationMutation(undefined);
  const { showNotification } = useNotificationContext();

  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [applicationsToReview, setApplicationsToReview] = useState<
    IApplicationWithOrderAndCourse[]
  >([]);
  const columns = getListApplicationsTableColumns({
    onApplicationReview: (application: IApplicationWithOrderAndCourse) => {
      setApplicationsToReview([...applicationsToReview, application]);
      setReviewModalOpen(true);
    },
    userRoles,
  });

  const table = useReactTable<IApplicationWithOrderAndCourse>({
    data: applications,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel<IApplicationWithOrderAndCourse>(),
  });

  const rows = table.getRowModel().rows;

  const hasNoApplications = rows.length === 0 && !isLoading;

  const reviewSelectedApplications = async (
    reviews: Partial<ReviewModalState>,
  ) => {
    if (!applicationsToReview.length) {
      return;
    }

    try {
      setReviewModalOpen(false);

      const reviewCalls = formatReviewCalls(
        applicationsToReview,
        reviews,
        userRoles as UserRole[],
      );

      await Promise.all(reviewCalls.map(call => reviewApplication(call)));

      showNotification(
        `Application${applicationsToReview.length > 1 ? 's' : ''} reviewed successfully`,
        NotificationStyle.SUCCESS,
      );

      table.resetRowSelection();
    } catch {
      showNotification(
        `Failed to review application${applicationsToReview.length > 1 ? 's' : ''}`,
        NotificationStyle.ERROR,
      );
    }
  };

  const handleReviewMultiple = async () => {
    const selectedRowModel = table.getSelectedRowModel();

    if (selectedRowModel.rows.length === 0) {
      return;
    }

    setApplicationsToReview(selectedRowModel.rows.map(row => row.original));
    setReviewModalOpen(true);
  };

  const handleReviewSubmit = async (
    submittedReview?: Partial<ReviewModalState>,
  ) => {
    setReviewModalOpen(false);
    if (submittedReview) {
      await reviewSelectedApplications(submittedReview as ReviewModalState);
    } else {
      table.resetRowSelection();
    }
  };

  return (
    <>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoApplications ? 'h-full' : ''}`}
      >
        <thead>
          {table.getIsSomeRowsSelected() && (
            <tr>
              <th
                colSpan={table.getVisibleFlatColumns().length}
                className="p-0"
              >
                <div className="flex items-center w-full gap-3 p-4 text-xs font-medium rounded-md bg-gray-background">
                  <p>
                    {pluralize(
                      'item',
                      table.getSelectedRowModel().rows.length,
                      true,
                    )}{' '}
                    selected
                  </p>
                  <button
                    className="flex items-center p-1 ml-10 rounded-md text-mydra-purple hover:bg-mydra-medium-gray hover:text-black"
                    onClick={() => table.resetRowSelection()}
                  >
                    <Close className="w-6 h-6" />
                    Deselect
                  </button>

                  <div className="flex content-end w-full">
                    <button
                      className="flex items-center gap-2 p-1 ml-10 rounded-md text-text hover:bg-mydra-medium-gray hover:text-black"
                      onClick={() => {
                        setReviewModalOpen(true);
                        handleReviewMultiple();
                      }}
                    >
                      <ReviewsOutlined className="w-6 h-6" />
                      Review
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          )}
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-3 first:px-0 last:px-0"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoApplications ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="h-full p-0 text-center"
              >
                <div className="flex flex-col justify-center h-full p-3 align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className="text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">{`No applications found`}</p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.original._id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
      <ReviewModal
        applicationsToReview={applicationsToReview}
        userRoles={userRoles as UserRole[]}
        onClose={handleReviewSubmit}
        open={isReviewModalOpen}
        initialReview={{
          learningBudget: false,
          taxBenefits: false,
          skills: false,
          capacity: false,
          admissionProcess: false,
        }}
      />
    </>
  );
}

type ReviewModalProps = Omit<ModalProps, 'onClose' | 'title' | 'children'> & {
  applicationsToReview: IApplicationWithOrderAndCourse[];
  userRoles: UserRole[];
  initialReview: ReviewModalState;
  onClose: (review?: Partial<ReviewModalState>) => Promise<void>;
};

function ReviewModal({
  applicationsToReview,
  userRoles,
  initialReview,
  onClose,
  ...props
}: ReviewModalProps) {
  const validationsToReview = getValidationNames(
    applicationsToReview,
    userRoles,
  );

  return userRoles.includes(UserRole.EducationProvider) ? (
    <EducationProviderReviewModal
      applicationsToReview={applicationsToReview}
      userRoles={userRoles}
      initialReview={initialReview}
      onClose={onClose}
      validationsToReview={validationsToReview}
      {...props}
    />
  ) : (
    <PaymentFeaturesReviewModal
      applicationsToReview={applicationsToReview}
      userRoles={userRoles}
      initialReview={initialReview}
      onClose={onClose}
      validationsToReview={validationsToReview}
      {...props}
    />
  );
}

type PaymentFeaturesReviewModalProps = ReviewModalProps & {
  validationsToReview: ApplicationValidationType[];
};

function PaymentFeaturesReviewModal({
  applicationsToReview,
  validationsToReview,
  initialReview,
  onClose,
  ...props
}: PaymentFeaturesReviewModalProps) {
  const [review, setReview] = useState<ReviewModalState>(initialReview);

  return (
    <Modal
      {...props}
      title={`Please review ${applicationsToReview.length > 1 ? 'these application' : 'this application'}`}
      showCloseButton
      className="max-w-2xl"
    >
      <div className="flex flex-col gap-4">
        <p>
          Review and approve the following requests to ensure all payment
          features meet our standards for a seamless transaction experience.
        </p>
        <div>
          {validationsToReview.map(validation => (
            <div key={validation} className="flex flex-row items-center gap-2">
              <p>
                Approve{' '}
                {validation === ApplicationValidationType.FlexibleRemuneration
                  ? ('tax benefits' as const)
                  : ('learning budget' as const)}
              </p>
              <Switch
                slotProps={{
                  input: {
                    id:
                      validation ===
                      ApplicationValidationType.FlexibleRemuneration
                        ? (ApplicationValidationStateType.TaxBenefits as const)
                        : (ApplicationValidationStateType.LearningBudget as const),
                  },
                }}
                onChange={event => {
                  setReview({
                    ...review,
                    [event.target.id as ApplicationValidationStateType]:
                      event.target.checked,
                  });
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end gap-3">
          <Button
            primary
            onClick={() => {
              onClose(review);
            }}
          >
            Submit
          </Button>
          <Button onClick={() => onClose(undefined)}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
}

function EducationProviderReviewModal({
  applicationsToReview,
  validationsToReview,
  userRoles,
  onClose,
  ...props
}: PaymentFeaturesReviewModalProps) {
  const [application] = applicationsToReview ?? [];
  const [validationToReview] = validationsToReview ?? [];

  if (!application || !validationToReview) {
    return null;
  }

  const {
    user: {
      firstName,
      lastName,
      email,
      profile: { linkedInUrl, skills, currentRole } = {},
    },
    course: { name: courseName, cohorts: courseCohorts } = {},
    cohort: cohortId,
  } = application;

  const cohort = courseCohorts?.find(cohort => cohort._id === cohortId);

  if (
    !application ||
    !application.user ||
    !validationToReview ||
    !userRoles.includes(UserRole.EducationProvider) ||
    !cohort
  ) {
    throw new Error('Invalid application or validation');
  }

  const cohortStartDate = cohort.startDate
    ? new Date(cohort.startDate).toLocaleDateString()
    : 'TBD';

  const userName = [firstName, lastName].filter(Boolean).join(' ');

  const modalTitle =
    validationToReview === ApplicationValidationType.Skills
      ? "Please verify the user's skills for this application"
      : validationToReview === ApplicationValidationType.Capacity
        ? 'Please verify user enrollment capacity'
        : "Please verify the user's eligibility for admission";

  const modalDescription =
    validationToReview === ApplicationValidationType.Skills
      ? `By clicking 'Approve' will confirm the user's eligibility for the
          course automatically. If rejected, the user will be notified via email
          with additional information.`
      : validationToReview === ApplicationValidationType.Capacity
        ? `By clicking 'Approve' will confirm the user's enrollment in the ${courseName} starting ${cohortStartDate}. If rejected, the user will be notified via email with additional information.`
        : `By clicking 'Approve' will confirm the user's eligibility for admission. If rejected, the user will be notified via email with additional information.`;

  return (
    <Modal {...props} title={modalTitle} className="max-w-2xl">
      <div className="flex flex-col gap-4">
        <p className="text-lg">{modalDescription}</p>
        <p className="text-lg font-medium">Student's overview</p>
        <div className="flex flex-col gap-3">
          <div className="div">
            <p className="font-medium">{userName}</p>
            <p>{email}</p>
          </div>
          {[
            ApplicationValidationType.Skills,
            ApplicationValidationType.AdmissionProcess,
          ].includes(validationToReview) && (
            <div>
              <p className="font-medium">LinkedIn</p>
              <p>
                <a href={linkedInUrl} target="_blank" rel="noreferrer">
                  {linkedInUrl}
                </a>
              </p>
            </div>
          )}
          {[
            ApplicationValidationType.Capacity,
            ApplicationValidationType.AdmissionProcess,
          ].includes(validationToReview) && (
            <div>
              <p className="font-medium">Job role</p>
              <p>{currentRole}</p>
            </div>
          )}
          <div>
            <p className="font-medium">Skills</p>
            <p>{skills?.join(', ') || '(none)'}</p>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <Button
            onClick={() => {
              onClose({
                [validationToReview]: false,
              });
            }}
          >
            Reject
          </Button>
          <Button
            primary
            onClick={() => {
              onClose({
                [validationToReview]: true,
              });
            }}
          >
            <div className="flex items-center gap-2">
              Approve <Check />
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
