import { Button } from '@/modules/common/components/Button';
import { formatDate } from '@/modules/common/lib/formatters';
import { ICompany } from '@/types/company';
import {
  ITaxBenefitsProviderCompanyLink,
  TaxBenefitsProviderCompanyLinkStatus,
} from '@/types/tax-benefits-provider';
import { createColumnHelper } from '@tanstack/react-table';
import { TableHeader } from './ListOfCompaniesTable.components';

const columnHelper = createColumnHelper<ICompany>();

type GetListOfCompaniesTableColumnsArgs = {
  onInviteReview: (companyLinkId: string, companyName: string) => void;
  onNavigateToApplications: (companyId: string) => void;
};

export function getListOfCompaniesTableColumns({
  onInviteReview,
  onNavigateToApplications,
}: GetListOfCompaniesTableColumnsArgs) {
  const getLatestLink = (company: ICompany) => {
    const latestLinks = [
      ...(company.taxBenefitsProviderCompanyLinks || []),
    ].sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));

    if (latestLinks.length === 0) {
      return null;
    }

    const [lastLink] = latestLinks;
    return lastLink;
  };

  const getPartnershipStatusInfo = (
    {
      status,
      _id: companyLinkId,
      updatedAt,
      company,
    }: ITaxBenefitsProviderCompanyLink,
    companyName: string,
  ) => {
    if (!companyLinkId || !status) {
      return null;
    }

    switch (status) {
      case TaxBenefitsProviderCompanyLinkStatus.Invited:
        return {
          label: 'Review invite',
          onClick: () => onInviteReview(companyLinkId, companyName),
          helpText: 'Review the partnership invite and accept or reject it.',
        };
      case TaxBenefitsProviderCompanyLinkStatus.Canceled:
        return {
          label: <span className="text-red-500">Canceled</span>,
          helpText: `The company canceled an invite on ${formatDate(updatedAt, 'short')}.`,
        };
      case TaxBenefitsProviderCompanyLinkStatus.Terminated:
        return {
          label: <span className="text-red-500">Terminated</span>,
          helpText: `The company terminated a partnership on ${formatDate(updatedAt, 'short')}.`,
        };
      case TaxBenefitsProviderCompanyLinkStatus.Accepted:
        return {
          label: 'Review applications',
          helpText: `Partnership is active since ${formatDate(updatedAt, 'short')}.`,
          onClick: () => onNavigateToApplications(company),
        };
      case TaxBenefitsProviderCompanyLinkStatus.Rejected:
        return {
          label: <span className="text-red-500">Rejected</span>,
          helpText: `You rejected this partnership on ${formatDate(updatedAt, 'short')}.`,
        };
    }
  };

  return [
    columnHelper.accessor('name', {
      header: () => <TableHeader>Name</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex gap-3 items-center">
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">{original.name}</div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('legalName', {
      header: () => <TableHeader>Legal name</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex gap-3 items-center">
            <div className="flex flex-col gap-2">
              <div className="text-xs font-medium">{original.legalName}</div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('taxBenefitsProvider.status', {
      header: () => <TableHeader>Partnership status</TableHeader>,
      cell: ({ row: { original } }) => {
        const lastLink = getLatestLink(original);

        if (!lastLink) {
          return null;
        }

        const buttonProps = getPartnershipStatusInfo(lastLink, original.name);

        if (!buttonProps) {
          return null;
        }

        const { label, helpText } = buttonProps;

        return (
          <div className="flex gap-3 items-center">
            <div className="flex flex-col gap-1">
              <p className="text-xs font-medium">{label}</p>
              <p className="text-xs text-gray-500">{helpText}</p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <TableHeader>Actions</TableHeader>,
      cell: ({ row: { original } }) => {
        const lastLink = getLatestLink(original);

        if (!lastLink) {
          return null;
        }

        const buttonProps = getPartnershipStatusInfo(lastLink, original.name);

        if (!buttonProps) {
          return null;
        }

        const { label, onClick } = buttonProps;

        return (
          <div className="flex flex-col gap-2">
            {onClick ? (
              <Button small onClick={onClick} primary>
                {label}
              </Button>
            ) : (
              <div className="text-xs font-medium">&mdash;</div>
            )}
          </div>
        );
      },
    }),
  ];
}
