import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { TextField } from '@/modules/common/components/TextField';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { UserRole } from '@/types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCompanyMutation } from '../hooks/useCompanyMutation';

type CompanyBasicSettingsProps = React.FormHTMLAttributes<HTMLFormElement>;
type FormValues = {
  name: string;
  legalName?: string;
  address?: string;
  domain?: string;
  alternativeDomains?: string;
  learningBudget?: boolean;
};

export function CompanyBasicSettings({ ...props }: CompanyBasicSettingsProps) {
  const { employer, isLoading, currentUser } = useCurrentUserContext() ?? {};
  const { mutateAsync: updateCompany, isPending } = useCompanyMutation(
    employer?._id ?? '',
  );
  const isEmployerUser = currentUser?.roles.includes(UserRole.Employer);
  const { showNotification } = useNotificationContext();
  const CompanyBasicSettingsFormSchema = yup.object().shape({
    name: yup.string().required('Company name is required'),
    legalName: yup.string(),
    address: yup.string(),
    alternativeDomains: yup.string(),
    learningBudget: isEmployerUser
      ? yup.boolean()
      : yup.boolean().optional().default(undefined),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    disabled: isLoading,
    values: {
      name: employer?.name ?? '',
      legalName: employer?.legalName ?? '',
      address: employer?.address ?? '',
      domain: employer?.domain ?? '',
      alternativeDomains: employer?.alternativeDomains?.join(',') ?? '',
      learningBudget: isEmployerUser
        ? employer?.learningBudget ?? false
        : undefined,
    },
    resolver: yupResolver(CompanyBasicSettingsFormSchema),
  });

  const updateCompanySettings = async (updatedData: FormValues) => {
    try {
      await updateCompany({
        updatedData: {
          ...updatedData,
          alternativeDomains:
            updatedData.alternativeDomains?.split(',').map(d => d.trim()) || [],
        },
      });
      showNotification(
        'Company settings updated successfully',
        NotificationStyle.SUCCESS,
      );
    } catch {
      showNotification(
        'Failed to update company settings',
        NotificationStyle.ERROR,
      );
    }
  };

  return (
    <form
      {...props}
      onSubmit={handleSubmit(updateCompanySettings)}
      className={`flex flex-col w-full gap-5 ${props.className ?? ''}`}
    >
      <h2 className="text-lg font-medium leading-8">Company information</h2>
      <div className="flex gap-5">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              errorMessage={errors.name?.message as string}
              slotProps={{
                label: {
                  className: 'block mb-3',
                },
              }}
              name="name"
              id="name"
              className="flex-1"
              label="Company name"
              placeholder="Company name"
            />
          )}
        />
        <Controller
          name="legalName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              errorMessage={errors.legalName?.message as string}
              slotProps={{
                label: {
                  className: 'block mb-3',
                },
              }}
              name="legalName"
              id="legalName"
              className="flex-1"
              label="Company legal name"
              placeholder="Company legal name"
            />
          )}
        />
      </div>
      <div className="flex gap-5">
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              errorMessage={errors.address?.message as string}
              name="address"
              id="address"
              className="flex-1"
              label="Company address"
              placeholder="Company address"
              slotProps={{
                label: {
                  className: 'block mb-3',
                },
              }}
            />
          )}
        />
        <Controller
          disabled
          name="domain"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              errorMessage={errors.domain?.message as string}
              name="domain"
              id="domain"
              className="flex-1"
              label="Company domain"
              placeholder="Company domain"
              slotProps={{
                label: {
                  className: 'block mb-3',
                },
              }}
            />
          )}
        />
      </div>
      <div className="flex gap-5">
        <Controller
          name="alternativeDomains"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              errorMessage={errors.alternativeDomains?.message as string}
              name="address"
              id="address"
              className="flex-1"
              label="Company alternative domains"
              placeholder="Company alternative domains, comma separated"
              slotProps={{
                label: {
                  className: 'block mb-3',
                },
              }}
            />
          )}
        />
      </div>
      <div className="flex gap-5">
        {isEmployerUser && (
          <div className="flex flex-col flex-1 flex-grow">
            <label
              htmlFor="learningBudget"
              className="mb-3 text-sm font-medium"
            >
              Learning budget
            </label>
            <div className="flex flex-1 gap-2 items-center align-center">
              <Controller
                name="learningBudget"
                control={control}
                render={({ field }) => (
                  <input
                    id="learningBudget"
                    type="checkbox"
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />
              <label htmlFor="learningBudget">Enable learning budget</label>
            </div>
            <label
              htmlFor="learningBudget"
              className="mt-2 text-sm text-gray-500"
            >
              If enabled, the company employees will be able to use their
              learning budget to purchase courses. You will be able to
              accept/reject requests for such purchases.
            </label>
          </div>
        )}
      </div>
      <Button
        primary
        small
        type="submit"
        disabled={isLoading}
        isLoading={isPending}
      >
        Update
      </Button>
    </form>
  );
}
