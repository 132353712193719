import { PropsWithChildren } from 'react';

type CourseItemProps = {
  label: string;
  icon: React.ReactNode;
  hasInput?: boolean;
  inline?: boolean;
} & PropsWithChildren<React.HtmlHTMLAttributes<HTMLLabelElement>>;

export function CourseItem({
  label,
  children,
  icon,
  inline,
  hasInput,
  ...props
}: CourseItemProps) {
  return (
    <label
      {...props}
      className={`flex ${inline ? 'flex-row items-center' : 'flex-col'} text-sm text-mydra-black ${hasInput || inline ? 'gap-1' : ''}`}
    >
      <div className="flex items-start items-center gap-1 font-medium">
        {icon}
        <p>{label}</p>
      </div>
      {children}
    </label>
  );
}
