import { ApiPaginationParams } from '@/types/api';
import {
  ApiIndividualLandingPage,
  ApiPaginatedLandingPages,
  ILandingPageCreationPayload,
  ILandingPageListFilters,
  ILandingPageRegenerationParams,
  ILandingPageUpdatePayload,
} from '@/types/landing-page';
import qs from 'query-string';
import { ApiService } from './api';

export class LandingPagesService {
  static PATH = '/landing-pages';

  static async getLandingPages(
    filters: ILandingPageListFilters = {},
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedLandingPages> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${LandingPagesService.PATH}?${queryString}`);
  }

  static async postLandingPage(
    payload: ILandingPageCreationPayload,
  ): Promise<ApiIndividualLandingPage> {
    return ApiService.post(LandingPagesService.PATH, payload);
  }

  static async patchLandingPage(
    id: string,
    payload: ILandingPageUpdatePayload,
    regenerationParams?: ILandingPageRegenerationParams,
  ): Promise<ApiIndividualLandingPage> {
    const queryString = qs.stringify(regenerationParams ?? {});

    return ApiService.patch(
      `${LandingPagesService.PATH}/${id}?${queryString}`,
      payload,
    );
  }

  static async deleteLandingPage(id: string): Promise<void> {
    return ApiService.delete(`${LandingPagesService.PATH}/${id}`);
  }
}
