type CoursePublicationStatusProps = {
  published: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function CoursePublicationStatus({
  published,
  ...props
}: CoursePublicationStatusProps) {
  return (
    <div
      {...props}
      className={`inline-block leading-6 text-mydra-black text-xs rounded-sm px-2 ${published ? 'bg-mydra-yellow' : 'bg-gray-300'} ${props.className ?? ''}`}
    >
      {published ? 'Published' : 'Draft'}
    </div>
  );
}
