import { ICategory } from '@/types/category';
import { ICourse } from '@/types/course';

enum CourseImageVariant {
  THUMBNAIL = 'thumbnail',
  BANNER = 'banner',
}

type ImageInfo = {
  src: string;
  width: number;
  height: number;
  variant: CourseImageVariant;
};

export function useCourseCategoryImage(
  course: Pick<ICourse, '_id' | 'categories' | 'educationProvider'>,
): { category: ICategory | null; categoryImage: ImageInfo | null } {
  if (!course) {
    return {
      category: null,
      categoryImage: null,
    };
  }

  const { _id: courseId, categories: [courseCategory] = [] } = course;

  const categoryImages =
    IMAGES_FOR_CATEGORIES[(courseCategory as ICategory)?.slug];

  if (!categoryImages?.length) {
    const defaultImages = DEFAULT_CARD_COVERS.filter(
      image => image?.variant === CourseImageVariant.THUMBNAIL,
    );

    const defaultImageId = uuidToStableIntegerId(
      courseId!,
      defaultImages.length,
    );
    const defaultImage = defaultImages[defaultImageId - 1];

    return {
      category: null,
      categoryImage: defaultImage,
    };
  }

  const categoryImageId =
    uuidToStableIntegerId(courseId!, categoryImages.length) || 1;
  const defaultImage = categoryImages[categoryImageId - 1];

  return {
    category: courseCategory,
    categoryImage: defaultImage,
  };
}

function uuidToStableIntegerId(uuid: string, maxInteger = 4) {
  if (!uuid) {
    throw new Error('uuid is required');
  }

  uuid = uuid.replace(/-/g, '').toLowerCase();

  const highBits = parseInt(uuid.substr(0, 8), 16);
  const lowBits = parseInt(uuid.substr(24, 8), 16);

  const stableId = ((highBits + lowBits) % maxInteger) + 1;

  return stableId;
}

export const IMAGES_FOR_CATEGORIES: Record<string, ImageInfo[]> = {
  ai: [
    {
      src: '/images/category-images/ai-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/ai-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/ai-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/ai-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  cloud: [
    {
      src: '/images/category-images/cloud-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cloud-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cloud-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cloud-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  cybersecurity: [
    {
      src: '/images/category-images/cybersecurity-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cybersecurity-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cybersecurity-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cybersecurity-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  sales: [
    {
      src: '/images/category-images/sales-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/sales-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/sales-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/sales-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  data: [
    {
      src: '/images/category-images/data-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/data-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/data-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/data-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  finance: [
    {
      src: '/images/category-images/finance-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/finance-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/finance-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/finance-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  'people-and-hr': [
    {
      src: '/images/category-images/people-and-hr-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/people-and-hr-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/people-and-hr-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/people-and-hr-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  design: [
    {
      src: '/images/category-images/design-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/design-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/design-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/design-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  devops: [
    {
      src: '/images/category-images/devops-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/devops-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/devops-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/devops-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  'artificial-intelligence': [
    {
      src: '/images/category-images/artificial-intelligence-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/artificial-intelligence-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/artificial-intelligence-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/artificial-intelligence-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  'software-engineering': [
    {
      src: '/images/category-images/software-engineering-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/software-engineering-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/software-engineering-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/software-engineering-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  development: [
    {
      src: '/images/category-images/development-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/development-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/development-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/development-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  'it-operations': [
    {
      src: '/images/category-images/it-operations-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/it-operations-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/it-operations-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/it-operations-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  'language-learning': [
    {
      src: '/images/category-images/language-learning-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/language-learning-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/language-learning-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/language-learning-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  languages: [
    {
      src: '/images/category-images/languages-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/languages-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/languages-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/languages-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  leadership: [
    {
      src: '/images/category-images/leadership-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/leadership-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/leadership-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/leadership-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  management: [
    {
      src: '/images/category-images/management-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/management-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/management-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/management-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  marketing: [
    {
      src: '/images/category-images/marketing-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/marketing-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/marketing-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/marketing-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  business: [
    {
      src: '/images/category-images/business-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/business-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/business-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/business-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  'cloud-engineering': [
    {
      src: '/images/category-images/cloud-engineering-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cloud-engineering-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cloud-engineering-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/cloud-engineering-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
  'product-management': [
    {
      src: '/images/category-images/product-management-01.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/product-management-02.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/product-management-03.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
    {
      src: '/images/category-images/product-management-04.jpg',
      width: 924,
      height: 420,
      variant: CourseImageVariant.THUMBNAIL,
    },
  ],
};

export const DEFAULT_CARD_COVERS = [
  {
    src: '/images/category-images/default-01.jpg',
    width: 924,
    height: 420,
    variant: CourseImageVariant.THUMBNAIL,
  },
  {
    src: '/images/category-images/default-02.jpg',
    width: 924,
    height: 420,
    variant: CourseImageVariant.THUMBNAIL,
  },
  {
    src: '/images/category-images/default-03.jpg',
    width: 924,
    height: 420,
    variant: CourseImageVariant.THUMBNAIL,
  },
  {
    src: '/images/category-images/banner-default-01.jpg',
    width: 1600,
    height: 420,
    variant: CourseImageVariant.BANNER,
  },
  {
    src: '/images/category-images/banner-default-02.jpg',
    width: 1600,
    height: 420,
    variant: CourseImageVariant.BANNER,
  },
  {
    src: '/images/category-images/banner-default-03.jpg',
    width: 1600,
    height: 420,
    variant: CourseImageVariant.BANNER,
  },
];
