import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { TextField } from '@/modules/common/components/TextField';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { useCompanyMutation } from '@/modules/company-settings/hooks/useCompanyMutation';
import { ApiErrorResponse } from '@/types/api';
import { IEmployer } from '@/types/employer';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalProps } from '@mui/base';
import Alert from '@mui/material/Alert';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const FORM_SCHEMA = yup.object().shape({
  learningBudgetLimit: yup.number().required('learningBudgetLimit is required'),
});

type CompanyLearningBudgetDialog = Omit<ModalProps, 'children'> & {
  company: IEmployer;
  onSuccess: (learningBudgetLimit?: number) => void;
};

export function CompanyLearningBudgetDialog({
  onSuccess,
  ...props
}: CompanyLearningBudgetDialog) {
  const { showNotification } = useNotificationContext();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ learningBudgetLimit: number }>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: { learningBudgetLimit: undefined },
  });

  const { mutateAsync: updateLearningBudgetLimit, isPending } =
    useCompanyMutation(props.company._id!);

  const handleSetLearningBudgetLimit = async ({
    learningBudgetLimit,
  }: {
    learningBudgetLimit: number;
  }) => {
    try {
      await updateLearningBudgetLimit({ updatedData: { learningBudgetLimit } });
      onSuccess(learningBudgetLimit);
      showNotification(
        `Limit set to ${learningBudgetLimit} successfuly`,
        NotificationStyle.SUCCESS,
      );
    } catch (error) {
      if ((error as ApiErrorResponse).statusCode === 400) {
        return setError('root.serverError', {
          type: 'manual',
          message:
            (error as ApiErrorResponse).message ??
            'An error occurred. Please try again later.',
        });
      }
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleCancel = () => {
    props.onClose?.({}, 'backdropClick');
  };

  return (
    <Modal
      {...props}
      title={`Set Learning Budget limit`}
      subTitle="Allocate learning budget limit for your company that will apply to all employees"
      onClose={(...args) => {
        reset();
        props.onClose?.(...args);
      }}
      className="max-w-xl mx-auto p-8 pb-3"
    >
      <>
        {props.company.learningBudgetLimit && (
          <div className="mb-2">
            <Alert severity="warning">
              Your company has a current Learning Budget Limit of:{' '}
              <span className="font-bold">
                {props.company.learningBudgetLimit} €
              </span>
            </Alert>
          </div>
        )}
        <form
          onSubmit={handleSubmit(handleSetLearningBudgetLimit)}
          className="flex flex-col justify-end gap-3 p-2"
        >
          {errors.root?.serverError?.message && (
            <ul className="text-red-500 list-disc">
              {errors.root.serverError.message
                ?.split(';')
                .map((error, index) => (
                  <li key={index}>
                    {error}
                    <br />
                  </li>
                ))}
            </ul>
          )}
          <div className="flex flex-col gap-6">
            <div>
              <Controller
                control={control}
                name="learningBudgetLimit"
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Budget limit"
                    placeholder="€"
                  />
                )}
              />
              {errors.learningBudgetLimit?.message && (
                <p className="text-sm text-red-500">
                  {errors.learningBudgetLimit.message}
                </p>
              )}
            </div>
          </div>
          <hr className="absolute left-0 right-0 mb-14" />
          <div className="mt-14 flex justify-end space-x-4">
            <Button
              small
              type="submit"
              onClick={handleCancel}
              className="w-32 mt-6"
            >
              Cancel
            </Button>
            <Button
              primary
              small
              type="submit"
              className="w-32 mt-6"
              isLoading={isPending}
            >
              Save
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
}
