import React, {
  ComponentProps,
  ElementType,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { ActiveEditingSectionContextProvider } from './ActiveEditingSectionProvider';

type WithActiveSectionContextProps = PropsWithChildren<
  React.HTMLAttributes<HTMLElement>
>;

export function withActiveSectionContext<T extends ElementType>(
  Component: T,
): FunctionComponent<
  Omit<ComponentProps<T>, keyof WithActiveSectionContextProps> &
    WithActiveSectionContextProps
> {
  return function WrappedActiveSectionComponent(props) {
    return (
      <ActiveEditingSectionContextProvider>
        <Component {...(props as ComponentProps<T>)} />
      </ActiveEditingSectionContextProvider>
    );
  };
}
