import { PaginationButton } from '@/modules/common/components/PaginationButton';
import { IEmployee } from '@/types/employer';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { RowData, Table } from '@tanstack/react-table';

export function TableHeader({
  children,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <div {...props} className={`flex w-full ${props.className ?? ''}`}>
      <span className={`text-xs text-text w-full ${props.className ?? ''}`}>
        {children}
      </span>
    </div>
  );
}

type TableFooterProps<T extends RowData> = {
  tableInstance: Table<T>;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

export function TableFooter({
  tableInstance,
  ...props
}: TableFooterProps<IEmployee>) {
  function formatTableFooter(
    pageIndex: number,
    pageSize: number,
    totalResults: number,
  ): string {
    const startIndex = pageIndex * pageSize + 1;
    let endIndex = startIndex + pageSize - 1;
    if (endIndex > totalResults) {
      endIndex = totalResults;
    }

    return `Show results ${startIndex}-${endIndex} of ${totalResults}`;
  }

  const {
    pagination: { pageIndex, pageSize },
  } = tableInstance.getState();

  const columns = tableInstance.getAllColumns();
  const rowCount = tableInstance.getRowCount();
  const footerMessage = formatTableFooter(pageIndex, pageSize, rowCount);
  const pageOptions = tableInstance.getPageOptions();

  const canNavigateToNextPage = tableInstance.getCanNextPage();
  const canNavigateToPreviousPage = tableInstance.getCanPreviousPage();

  return (
    <>
      {pageOptions.length > 1 ? (
        <tfoot {...props} className="w-full">
          <tr>
            <td colSpan={columns.length}>
              <div className="flex w-full py-2">
                <div className="flex items-center gap-6 ml-auto">
                  <p className="text-xs leading-7 tracking-tight text-text">
                    {footerMessage}
                  </p>
                  <div className="flex gap-2">
                    <PaginationButton
                      onClick={tableInstance.previousPage}
                      disabled={!canNavigateToPreviousPage}
                    >
                      <ChevronLeft />
                    </PaginationButton>
                    {pageOptions.slice(pageIndex, pageIndex + 2).map(page => (
                      <PaginationButton
                        key={page}
                        onClick={() => tableInstance.setPageIndex(page)}
                        className={
                          pageIndex === page ? '!bg-gray-background' : ''
                        }
                      >
                        {page + 1}
                      </PaginationButton>
                    ))}
                    <PaginationButton
                      onClick={tableInstance.nextPage}
                      disabled={!canNavigateToNextPage}
                    >
                      <ChevronRight />
                    </PaginationButton>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      ) : null}
    </>
  );
}
