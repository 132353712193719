import { Button } from '@/modules/common/components/Button';
import { MenuItem } from '@/modules/common/components/MenuItem';
import { formatCurrencyValue } from '@/modules/common/lib/formatters';
import { ICohort, ICourse } from '@/types/course';
import { IEducationProviderAgreement } from '@/types/education-provider-agreement';
import {
  DeleteOutline,
  PublishOutlined,
  UnpublishedOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { createColumnHelper } from '@tanstack/react-table';
import { format, isValid } from 'date-fns';
import { useCourseCategoryImage } from '../../hooks/useCourseCategoryImage';
import { CoursePublicationStatus } from '../CousePublicationStatus';
import { TableHeader } from './ListCoursesTable.components';

const columnHelper = createColumnHelper<ICourse>();

type GetListCoursesTableColumnsArgs = {
  onCourseEdit: (id: string) => void;
  onCourseDelete: (id: string) => void;
  onCourseView: (id: string) => void;
  onCourseUpdate: (args: { id: string; updatedData: Partial<ICourse> }) => void;
};

export function getListCoursesTableColumns({
  onCourseEdit,
  onCourseDelete,
  onCourseView,
  onCourseUpdate,
}: GetListCoursesTableColumnsArgs) {
  return [
    columnHelper.display({
      id: 'checkbox',
      header: () => null,
      cell: ({ row }) => {
        const isSelected = row.getIsSelected();

        return (
          <div className="flex flex-1 justify-center p-4 align-center">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => row.toggleSelected()}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor('name', {
      header: () => (
        <TableHeader className="absolute left-0">Title</TableHeader>
      ),
      cell: ({ row: { original } }) => {
        const { categoryImage, category } =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useCourseCategoryImage(original) ?? {};

        return (
          <div className="flex gap-3 items-center">
            {categoryImage && (
              <img
                src={categoryImage.src}
                width={categoryImage.width}
                height={categoryImage.height}
                alt={category?.name}
                className="object-cover w-20 h-20 rounded-md aspect-square"
              />
            )}
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">{original.name}</div>
              {original.updatedAt && isValid(new Date(original.updatedAt)) && (
                <div className="text-xxs text-text">
                  Last update {format(original.updatedAt, 'PPPp')}
                </div>
              )}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('cost.amount', {
      header: () => <TableHeader>PVP Price</TableHeader>,
      cell: ({
        row: {
          original: { cohorts = [] },
        },
      }) => {
        try {
          const maxPrice = Math.max(
            ...cohorts.map((cohort: ICohort) => cohort.cost?.amount ?? 0),
          );
          const maxPriceCurrency = cohorts.find(cohort => cohort.cost)?.cost
            ?.currency;

          if (maxPrice === 0) {
            return <span className="text-base text-mydra-black">Free</span>;
          }

          if (!maxPrice || !maxPriceCurrency) {
            return <span className="text-base text-mydra-black">-</span>;
          }

          return (
            <div>
              <span className="text-base text-mydra-black">
                {formatCurrencyValue(maxPrice.toString(), maxPriceCurrency)}
              </span>
            </div>
          );
        } catch (e) {
          console.error(e);
          return <span className="text-base text-mydra-black">-</span>;
        }
      },
    }),
    columnHelper.accessor('agreement.fees.discount', {
      header: () => <TableHeader>Price / Discount (%)</TableHeader>,
      cell: ({
        row: {
          original: { agreements = [], cohorts = [] },
        },
      }) => {
        try {
          if (!Array.isArray(agreements) || !agreements.length) {
            return <span className="text-base text-mydra-black">-</span>;
          }

          const maxDiscount = Math.max(
            ...(agreements as IEducationProviderAgreement[]).map(agreement => {
              return Math.max(...agreement.fees.map(fee => fee.discount));
            }),
          );

          const maxPrice = Math.max(
            ...cohorts.map((cohort: ICohort) => cohort.cost?.amount ?? 0),
          );

          const maxPriceCurrency = cohorts.find(cohort => cohort.cost)?.cost
            ?.currency;

          if (maxPrice === 0) {
            return <span className="text-base text-mydra-black">Free</span>;
          }

          if (!maxDiscount || !maxPrice || !maxPriceCurrency) {
            return <span className="text-base text-mydra-black">-</span>;
          }

          return (
            <div className="flex flex-col gap-2">
              <span className="text-base text-mydra-black">
                {formatCurrencyValue(
                  (maxPrice * (1 - maxDiscount)).toString(),
                  maxPriceCurrency,
                )}
              </span>
              <div className="text-xs">({maxDiscount * 100}% discount)</div>
            </div>
          );
        } catch (e) {
          console.error(e);
          return <span className="text-base text-mydra-black">-</span>;
        }
      },
    }),
    columnHelper.accessor('categories', {
      header: () => <TableHeader>Category</TableHeader>,
      cell: ({ row: { original } }) => {
        if (
          !Array.isArray(original.categories) ||
          !original.categories.length
        ) {
          return <span className="text-base text-mydra-black">-</span>;
        }

        return (
          <div>
            <span className="text-sm text-mydra-black">
              {original.categories[0].name}
            </span>
          </div>
        );
      },
    }),
    columnHelper.accessor('published', {
      header: () => <TableHeader>Status</TableHeader>,
      cell: ({
        row: {
          original: { published },
        },
      }) => {
        return (
          <div>
            <CoursePublicationStatus published={!!published} />
          </div>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <TableHeader className="text-right">Actions</TableHeader>,
      cell: ({ row: { original } }) => (
        <div className="flex gap-2 ml-auto">
          <div className="ml-auto">
            <Button
              onClick={() => onCourseEdit(original._id!)}
              small
              dropdownItems={
                <>
                  <MenuItem
                    className="border-b last-of-type:border-none"
                    icon={<VisibilityOutlined />}
                    onClick={() => {
                      onCourseView(original._id!);
                    }}
                  >
                    View course{' '}
                  </MenuItem>
                  <MenuItem
                    className="border-b last-of-type:border-none"
                    icon={
                      !original.published ? (
                        <PublishOutlined />
                      ) : (
                        <UnpublishedOutlined />
                      )
                    }
                    onClick={() => {
                      onCourseUpdate({
                        id: original._id!,
                        updatedData: { published: !original.published },
                      });
                    }}
                  >
                    {original?.published ? 'Unpublish' : 'Publish'}
                  </MenuItem>
                  <MenuItem
                    className="border-b last-of-type:border-none"
                    icon={<DeleteOutline />}
                    onClick={() => {
                      onCourseDelete(original._id!);
                    }}
                  >
                    Delete
                  </MenuItem>
                </>
              }
            >
              Edit
            </Button>
          </div>
        </div>
      ),
    }),
  ];
}
