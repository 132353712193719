import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { TextField } from '@/modules/common/components/TextField';
import { useEmployeeMutation } from '@/modules/common/hooks/useEmployer';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ApiErrorResponse } from '@/types/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalProps } from '@mui/base';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const FORM_SCHEMA = yup.object().shape({
  learningBudget: yup.number().required('learningBudget is required'),
});

type EmployeeLearningBudgetDialog = Omit<ModalProps, 'children'> & {
  employee: string;
  onSuccess: (learningBudget?: number) => void;
};

export function EmployeeLearningBudgetDialog({
  onSuccess,
  ...props
}: EmployeeLearningBudgetDialog) {
  const { showNotification } = useNotificationContext();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ learningBudget: number }>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: { learningBudget: undefined },
  });

  const userContext = useCurrentUserContext();
  const { mutateAsync: updateEmployee, isPending } = useEmployeeMutation();

  const handleSetLearningBudget = async ({
    learningBudget,
  }: {
    learningBudget: number;
  }) => {
    try {
      await updateEmployee({
        id: userContext!.currentUser.company! as string,
        employeeId: props.employee,
        updatedData: { learningBudget },
      });
      onSuccess(learningBudget);
      showNotification(
        `Budget set to ${learningBudget} successfuly`,
        NotificationStyle.SUCCESS,
      );
    } catch (error) {
      if ((error as ApiErrorResponse).statusCode === 400) {
        return setError('root.serverError', {
          type: 'manual',
          message:
            (error as ApiErrorResponse).message ??
            'An error occurred. Please try again later.',
        });
      }
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleCancel = () => {
    props.onClose?.({}, 'backdropClick');
  };

  return (
    <Modal
      {...props}
      title={`Set Employee Learning Budget limit`}
      subTitle="Allocate learning budget limit for a specific employee that will have priority over team or company defined budget limits"
      onClose={(...args) => {
        reset();
        props.onClose?.(...args);
      }}
      className="max-w-xl mx-auto p-8 pb-3"
    >
      <form
        onSubmit={handleSubmit(handleSetLearningBudget)}
        className="flex flex-col justify-end gap-3 p-2"
      >
        {errors.root?.serverError?.message && (
          <ul className="text-red-500 list-disc">
            {errors.root.serverError.message?.split(';').map((error, index) => (
              <li key={index}>
                {error}
                <br />
              </li>
            ))}
          </ul>
        )}
        <div className="flex flex-col gap-6">
          <div>
            <Controller
              control={control}
              name="learningBudget"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Budget limit"
                  placeholder="€"
                />
              )}
            />
            {errors.learningBudget?.message && (
              <p className="text-sm text-red-500">
                {errors.learningBudget.message}
              </p>
            )}
          </div>
        </div>
        <hr className="absolute left-0 right-0 mb-14" />
        <div className="mt-14 flex justify-end space-x-4">
          <Button
            small
            type="submit"
            onClick={handleCancel}
            className="w-32 mt-6"
          >
            Cancel
          </Button>
          <Button
            primary
            small
            type="submit"
            className="w-32 mt-6"
            isLoading={isPending}
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
