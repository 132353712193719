export enum NotificationStyle {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export type NotificationState = {
  message: string;
  style: NotificationStyle;
};

export type NotificationContextValue = {
  notification: NotificationState;
  isOpen: boolean;
  showNotification: (message: string, style: NotificationStyle) => void;
  clearNotification: () => void;
};
