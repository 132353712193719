import {
  useBlocker,
  useNavigate,
  useParams,
  useSearch,
} from '@tanstack/react-router';

import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import {
  ICourse,
  ICourseCreatePayload,
  TranslatableFields,
} from '@/types/course';

import { useActiveEditingSectionContext } from '../hooks/useActiveEditingSectionContext';
import {
  useCourseCreation,
  useCourseCreationWithAi,
  useCourseMutation,
  useGetCourse,
} from '../hooks/useCourses';
import { withActiveSectionContext } from '../providers/ActiveEditingSectionProviderHOC';

import { APP_ENV, EXTERNAL_ROUTES } from '@/constants';
import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ChevronLeft } from '@mui/icons-material';
import { useCallback, useEffect } from 'react';

import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { CourseAdditionalResources } from '../components/CourseAdditionalResources';
import { CourseAdministrativeDetails } from '../components/CourseAdministrativeDetails';
import { CourseCategories } from '../components/CourseCategories';
import { CourseCohorts } from '../components/CourseCohorts';
import { CourseDescription } from '../components/CourseDescription';
import { CourseEngagementAndOutcomes } from '../components/CourseEngagementAndOutcomes';
import { CourseHeader } from '../components/CourseHeader';
import { CourseInformation } from '../components/CourseInformation';
import { CourseInstructors } from '../components/CourseInstructors';
import { CourseMotivation } from '../components/CourseMotivation';
import { CourseNavBarActions } from '../components/CourseNavBarActions';
import { CourseNavBarTitle } from '../components/CourseNavBarTitle';
import { CourseProgram } from '../components/CourseProgram/CourseProgram';
import { CourseTestimonials } from '../components/CourseTestimonials';
import { CourseWebAddress } from '../components/CourseWebAddress';
import { UpsertCourseFormSchema } from './UpsertCoursePage.constants';

export const UpsertCoursePage = withActiveSectionContext(
  function UpsertCoursePage() {
    const navigate = useNavigate();
    const { enableAi: enableAICreation } = useSearch({
      strict: false,
    });

    const { educationProvider } = useCurrentUserContext() ?? {};

    const params = useParams({
      select: params => {
        if ((params as Record<'courseId', string>).courseId) {
          return {
            courseId: (params as Record<'courseId', string>).courseId,
          };
        }
        return {};
      },
      strict: false,
    });

    const { clearActiveEditingSection, activeEditingSection } =
      useActiveEditingSectionContext();
    const { showNotification } = useNotificationContext();

    const { data: { data: course } = {}, isLoading } = useGetCourse(
      params?.courseId ?? '',
      {
        enabled: !!params?.courseId,
      },
    );

    const { _id } = course ?? ({} as ICourse);

    const isEditing = !!_id;

    const { mutateAsync: editCourseMutation, isPending: isUpdatingCourse } =
      useCourseMutation(course?._id ?? undefined);
    const {
      mutateAsync: createCourseWithAi,
      isPending: isCreatingCourseWithAi,
    } = useCourseCreationWithAi();
    const { mutateAsync: createCourseMutation, isPending: isCreatingCourse } =
      useCourseCreation();

    const methods = useForm<ICourse & ICourseCreatePayload>({
      // @ts-expect-error FIXME: fix types
      values: {
        name: course?.name ?? '',
        cohorts: course?.cohorts ?? [],
        educationProviderId: educationProvider?._id ?? '',
        type: course?.type ?? '',
        categoryIds: [course?.categories?.[0]?._id].filter(Boolean) as string[],
        subCategories: course?.subCategories ?? [],
        language: course?.language ?? '',
        level: course?.level ?? '',
        url: course?.url ?? '',
        imageUrl: course?.imageUrl ?? '',
        brochureUrl: course?.brochureUrl ?? '',
        hasCertificate: course?.hasCertificate ?? false,
        published: course?.published ?? false,
        courseDetailTranslations: [],
        summary: course?.summary ?? '',
        learningDescription: course?.learningDescription ?? '',
        targets: course?.targets ?? [],
        skills: course?.skills ?? [],
        learningAreas: course?.learningAreas ?? [],
        prerequisites: course?.prerequisites ?? [],
        programmeStructure: course?.programmeStructure ?? [],
        instructors: course?.instructors ?? [],
        courseMotivation: course?.courseMotivation ?? undefined,
        testimonials: course?.testimonials ?? [],
      },
      mode: 'onChange',
      // @ts-expect-error FIXME: fix types
      resolver: yupResolver(UpsertCourseFormSchema),
    });

    const {
      setValue,
      formState: { errors, isValid, isDirty },
      trigger,
      getValues,
      handleSubmit,
    } = methods;

    const [courseName, published] = getValues(['name', 'published']);

    const processCourseTranslatableFields = (data: Partial<ICourse>) => {
      return Object.entries(data).reduce(
        (acc, [key, value]) => {
          if (key in TranslatableFields) {
            return {
              ...acc,
              translatableFields: {
                ...acc.translatableFields,
                [key]: value,
              },
            };
          } else {
            return {
              ...acc,
              nonTranslatableFields: {
                ...acc.nonTranslatableFields,
                [key]: value,
              },
            };
          }
        },
        {
          translatableFields: {},
          nonTranslatableFields: {},
        },
      );
    };

    const updateCourse = useCallback(
      async (data: Partial<ICourse>) => {
        if (!isEditing) {
          for (const [key, value] of Object.entries(data)) {
            setValue(key as keyof ICourse, value, { shouldDirty: true });
            trigger(key as keyof ICourse);
          }
          clearActiveEditingSection();
          return;
        }

        try {
          const { nonTranslatableFields, translatableFields } =
            processCourseTranslatableFields(data);

          await editCourseMutation({
            id: _id!,
            updatedData: {
              ...nonTranslatableFields,
              courseDetailTranslations: [
                {
                  language: 'en',
                  ...translatableFields,
                },
              ],
            },
          });
          clearActiveEditingSection();
          showNotification(
            'Course updated successfully',
            NotificationStyle.SUCCESS,
          );
        } catch {
          showNotification('Failed to update course', NotificationStyle.ERROR);
        }
      },
      [
        editCourseMutation,
        _id,
        clearActiveEditingSection,
        showNotification,
        isEditing,
        setValue,
        trigger,
      ],
    );

    const createCourse = async (values: ICourseCreatePayload & ICourse) => {
      try {
        const { translatableFields } = processCourseTranslatableFields(values);

        await createCourseMutation({
          educationProviderId: educationProvider?._id ?? '',
          courseDetailTranslations: [
            {
              language: 'en',
              ...translatableFields,
            },
          ],
          cohorts: values.cohorts!,
          type: values.type,
          skills: values.skills,
          url: values.url,
          imageUrl: values.imageUrl,
          language: values.language!,
          level: values.level!,
          brochureUrl: values.brochureUrl!,
          hasCertificate: values.hasCertificate!,
          published: values.published!,
          categoryIds: values.categoryIds!,
          subCategories: values.subCategories,
        });
        showNotification(
          'Course created successfully',
          NotificationStyle.SUCCESS,
        );
      } catch {
        showNotification(
          'Failed to create the course',
          NotificationStyle.ERROR,
        );
      }
    };

    useBlocker(
      () =>
        window.confirm(
          'Are you sure you want to leave without saving changes?',
        ),
      !!activeEditingSection || isDirty,
    );

    useEffect(() => {
      if (Object.keys(errors).length > 0) {
        showNotification(
          'Please fix the errors before submitting the form',
          NotificationStyle.ERROR,
        );
      }
    }, [errors, showNotification]);

    if (isLoading || (isEditing && !course)) {
      return null;
    }

    const useAiToCreateCourse = async ({ url }: Partial<ICourse>) => {
      try {
        await createCourseWithAi({
          educationProviderId: educationProvider?._id ?? '',
          courseUrl: url!,
          environment: APP_ENV === 'production' ? 'Production' : 'Staging',
        });
        showNotification(
          'Course created successfully',
          NotificationStyle.SUCCESS,
        );
        await navigate({ to: '/courses' });
      } catch {
        showNotification(
          'Failed to create the course',
          NotificationStyle.ERROR,
        );
      }
    };

    const isMutatingCourse =
      isUpdatingCourse || isCreatingCourse || isCreatingCourseWithAi;

    return (
      <FormProvider {...methods}>
        <AdminLayout
          beforeLogo={
            <button
              onClick={() =>
                navigate({
                  to: '/courses',
                })
              }
            >
              <ChevronLeft sx={{ fontSize: 32 }} />
            </button>
          }
          afterLogo={
            <CourseNavBarTitle
              course={{
                name: courseName ?? '',
                published,
              }}
            />
          }
          beforeUserMenu={
            <CourseNavBarActions
              onPublish={async () => {
                if (isEditing) {
                  handleSubmit(values =>
                    updateCourse({ ...values, published: true }),
                  )();
                  if (isValid) {
                    await navigate({
                      to: '/courses',
                    });
                  }
                } else {
                  handleSubmit(values =>
                    createCourse({ ...values, published: true }),
                  )();
                  if (isValid) {
                    await navigate({
                      to: '/courses',
                    });
                  }
                }
              }}
              onFinishLater={async () => {
                handleSubmit(values =>
                  updateCourse({ ...values, published: false }),
                )();
                if (isValid) {
                  await navigate({
                    to: '/courses',
                  });
                }
              }}
              onPreview={
                isEditing
                  ? async () => {
                      window.location.href =
                        EXTERNAL_ROUTES.MARKETPLACE_COURSE_URL.replace(
                          ':id',
                          course?._id ?? '',
                        );
                    }
                  : undefined
              }
            />
          }
          hideSidebar
          hideUserMenu
          stickyNavBar
        >
          <CourseHeader
            onSubmit={updateCourse}
            disabled={enableAICreation || isMutatingCourse}
          />
          <div className="grid grid-cols-12 mx-5 gap-x-5 py-9">
            <div className="flex flex-col col-start-2 col-end-8 gap-6">
              <CourseWebAddress
                isLoading={isCreatingCourseWithAi}
                onSubmit={values => {
                  enableAICreation && !isEditing
                    ? // eslint-disable-next-line react-hooks/rules-of-hooks
                      useAiToCreateCourse(values)
                    : updateCourse(values);
                }}
                enableAICreation={enableAICreation}
              />
              <CourseDescription
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseCohorts
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseProgram
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseMotivation
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseInstructors
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseTestimonials
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
            </div>
            <aside className="flex flex-col col-start-8 col-end-12 gap-6">
              <CourseInformation
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseCategories
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseEngagementAndOutcomes
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseAdditionalResources
                disabled={enableAICreation || isMutatingCourse}
                onSubmit={updateCourse}
              />
              <CourseAdministrativeDetails
                disabled={enableAICreation || isMutatingCourse}
                course={course}
              />
            </aside>
          </div>
        </AdminLayout>
      </FormProvider>
    );
  },
);
