import { ApplicationService } from '@/services/application';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiReactQueryOptions,
} from '@/types/api';
import {
  ApiIndividualApplication,
  ApiPaginatedApplications,
  ApplicationValidationType,
  IApplicationPayload,
} from '@/types/application';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApplicationsSearchParams } from '../types';

export const getApplicationsQueryOptions = (
  filters?: ApplicationsSearchParams,
  pagination?: ApiPaginationParams,
  include?: string[],
  options?: ApiReactQueryOptions<ApiPaginatedApplications>,
) => ({
  queryKey: [ApplicationService.PATH, filters, pagination, include],
  queryFn: async () =>
    await ApplicationService.getApplications(filters, pagination, include),
  ...options,
});

export const useGetApplications = (
  filters: ApplicationsSearchParams = {},
  pagination: ApiPaginationParams = {},
  include?: string[],
  options?: ApiReactQueryOptions<ApiPaginatedApplications>,
) => {
  return useQuery<ApiPaginatedApplications, ApiErrorResponse>(
    getApplicationsQueryOptions(filters, pagination, include, options),
  );
};

export const getApplicationQueryOptions = (
  id: string,
  options?: ApiReactQueryOptions<ApiIndividualApplication>,
) => ({
  queryKey: [ApplicationService.PATH, id],
  queryFn: async () => await ApplicationService.getApplication(id),
  ...options,
});

export const useGetApplication = (
  id: string,
  options?: ApiReactQueryOptions<ApiIndividualApplication>,
) =>
  useQuery<ApiIndividualApplication, ApiErrorResponse>(
    getApplicationQueryOptions(id, options),
  );

export function useApplicationMutation(applicationId: string | undefined) {
  const queryClient = useQueryClient();

  const mutationKey = [ApplicationService.PATH, applicationId];
  return useMutation<
    ApiIndividualApplication,
    ApiErrorResponse,
    { id: string; updatedData: Partial<IApplicationPayload> }
  >({
    mutationKey: mutationKey,
    mutationFn: async ({ id = applicationId, updatedData }) => {
      if (!id) {
        throw new Error('Application ID is required for mutation');
      }

      return ApplicationService.patchApplication(id!, updatedData);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ApplicationService.PATH],
        exact: false,
      });
    },
  });
}

export function useReviewApplicationMutation(
  applicationId: string | undefined,
) {
  const queryClient = useQueryClient();
  const mutationKey = [ApplicationService.PATH, applicationId];

  return useMutation<
    ApiIndividualApplication,
    ApiErrorResponse,
    {
      id: string;
      updatedData: {
        isApproved: boolean;
        validationType: ApplicationValidationType;
        comments?: string;
      };
    }
  >({
    mutationKey: mutationKey,
    mutationFn: async ({ id = applicationId, updatedData }) => {
      if (!id) {
        throw new Error('Application ID is required for mutation');
      }

      return ApplicationService.reviewApplication(id!, updatedData);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ApplicationService.PATH],
        exact: false,
      });
    },
  });
}
