import {
  MenuItem as MenuItemBase,
  MenuItemProps as MenuItemBaseProps,
} from '@mui/base';
import React, { forwardRef } from 'react';

export type MenuItemProps = MenuItemBaseProps & {
  icon?: React.ReactElement;
};

export const MenuItem = forwardRef(function MenuItem(
  { children, icon, ...props }: MenuItemProps,
  ref: React.ForwardedRef<HTMLLIElement>,
) {
  const IconComponent = icon
    ? () => React.cloneElement(icon, { size: 24 })
    : () => null;

  return (
    <MenuItemBase
      ref={ref}
      {...props}
      className={`text-sm font-medium leading-6 text-text hover:bg-mydra-light-purple ${props.className ?? ''} cursor-pointer flex gap-3 px-4 py-2`}
    >
      {icon && <IconComponent />}
      {children}
    </MenuItemBase>
  );
});
