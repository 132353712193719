import {
  ApiIndividualResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ISortingParams,
} from '@/types/api';

import {
  ApiPaginatedTaxBenefitProviderLinks,
  ApiPaginatedTaxBenefitProviders,
  ITaxBenefitsProviderCompanyLink,
  ITaxBenefitsProviderCompanyLinkCreatePayload,
  ITaxBenefitsProviderPartialUpdatePayload,
} from '@/types/tax-benefits-provider';
import qs from 'query-string';
import { ApiService } from './api';

export class TaxBenefitsProviderService extends ApiService {
  static PATH = '/tax-benefit-providers';
  static LINKS_PATH = '/tax-benefit-providers/company-links';

  static async getTaxBenefitsProviders(
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedTaxBenefitProviders> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${this.PATH}?${queryString}`);
  }

  static async getTaxBenefitsCompanyLinks(
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
    sorting: ISortingParams = {},
  ): Promise<ApiPaginatedTaxBenefitProviderLinks> {
    const queryString = qs.stringify({
      ...filters,
      ...pagination,
      ...(sorting && {
        sort: Object.entries(sorting).reduce(
          (acc: string[], [fieldName, sort]) => {
            return [...acc, `${fieldName}[${sort}]`];
          },
          [],
        ),
      }),
    });
    return ApiService.get(`${this.LINKS_PATH}?${queryString}`);
  }

  static async postTaxBenefitsCompanyLink(
    payload: ITaxBenefitsProviderCompanyLinkCreatePayload,
  ): Promise<ApiIndividualResponse<ITaxBenefitsProviderCompanyLink>> {
    return ApiService.post(this.LINKS_PATH, payload);
  }

  static async patchTaxBenefitsCompanyLink(
    id: string,
    payload: ITaxBenefitsProviderPartialUpdatePayload,
  ): Promise<ApiIndividualResponse<ITaxBenefitsProviderCompanyLink>> {
    return ApiService.patch(`${this.LINKS_PATH}/${id}`, payload);
  }
}
