import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ListEmployeesPage } from '@/modules/employees/pages/ListEmployeesPage';
import { EmployeesSearchParams } from '@/modules/employees/types';
import { createFileRoute } from '@tanstack/react-router';
import * as yup from 'yup';

export const Route = createFileRoute('/_admin/employees/')({
  validateSearch: (search: Record<string, unknown>): EmployeesSearchParams => {
    return {
      page: yup.number().integer().min(1).optional().validateSync(search.page),
      limit: yup
        .number()
        .integer()
        .min(1)
        .optional()
        .validateSync(search.limit),
      email: yup.string().optional().validateSync(search.email),
      tab: yup
        .string()
        .oneOf(['teams', 'invitations', 'employees'])
        .optional()
        .validateSync(search.tab),
    };
  },
  component: () => {
    return (
      <AdminLayout>
        <ListEmployeesPage />
      </AdminLayout>
    );
  },
});
