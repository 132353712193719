import {
  FormControl,
  FormControlProps,
  Input,
  InputInputSlotPropsOverrides,
  InputOwnerState,
  SlotComponentProps,
} from '@mui/base';
import { forwardRef } from 'react';

type TextFieldProps = FormControlProps<'input'> & {
  errorMessage?: string;
  helperText?: string;
  label?: string;
  slotProps?: FormControlProps['slotProps'] & {
    input?: SlotComponentProps<
      'input',
      InputInputSlotPropsOverrides,
      InputOwnerState
    >;
    label?: SlotComponentProps<'label', object, InputOwnerState>;
  };
};

export const TextField = forwardRef(function TextField(
  props: TextFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { id, type, slotProps, label, placeholder, error, helperText, name } =
    props;
  const {
    errorMessage,
    id: _id,
    name: _name,
    ...propsWithoutErrorMessage
  } = props;

  return (
    <FormControl
      error={error || !!errorMessage}
      className="flex flex-col gap-2"
      {...propsWithoutErrorMessage}
      ref={ref}
    >
      {({ error }) => (
        <>
          <label
            {...slotProps?.label}
            htmlFor={id}
            className={`text-sm text-mydra-black font-medium ${
              typeof slotProps?.label === 'object'
                ? slotProps.label.className
                : ''
            }`}
          >
            {label}
          </label>
          <Input
            placeholder={placeholder}
            id={id}
            name={name}
            type={type}
            slotProps={{
              input: {
                autoComplete: 'current-password',
                className: `w-full border rounded-xl px-3 py-2`,
                ...slotProps?.input,
              },
            }}
          />
          {error && errorMessage ? (
            <p className="text-sm text-red-500">{errorMessage}</p>
          ) : helperText ? (
            <p className="text-sm text-text">{helperText}</p>
          ) : (
            <></>
          )}
        </>
      )}
    </FormControl>
  );
});
