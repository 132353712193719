import { PaginationSearchParams } from '@/types/pagination';

export enum InviteType {
  User = 'user',
  EducationProvider = 'education_provider',
}

export type EmployeesSearchParams = PaginationSearchParams & {
  email?: string;
  tab?: string;
};
