import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from '@mui/base';
import { Refresh } from '@mui/icons-material';
import React from 'react';
import { DropDown, DropDownMenuProps } from '../DropDown';

type ButtonProps = BaseButtonProps & {
  isLoading?: boolean;
  dropdownItems?: React.ReactNode;
  small?: boolean;
  primary?: boolean;
  alert?: boolean;
  slotProps?: BaseButtonProps['slotProps'] & {
    dropDownProps?: DropDownMenuProps['slotProps'];
    wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  };
};

export function Button({
  alert,
  primary,
  small,
  className,
  dropdownItems,
  isLoading,
  ...props
}: ButtonProps) {
  const hasDropDownItems = React.Children.count(dropdownItems) > 0;

  const slotProps: ButtonProps['slotProps'] = {
    ...props.slotProps,
    root: {
      ...props?.slotProps?.root,
      className: `
        font-sans font-medium
        leading-6
        text-center

      ${hasDropDownItems ? 'rounded-l-lg' : 'rounded-xl'}

      ${
        alert
          ? 'bg-mydra-dark-red focus:bg-mydra-dark-red hover:bg-red-700 text-white active:bg-mydra-dark-red'
          : primary
            ? 'bg-mydra-black focus:bg-mydra-dark-purple hover:bg-mydra-dark-purple text-white active:bg-mydra-dark-purple'
            : 'bg-white text-mydra-black border hover:bg-gray-background active:bg-mydra-black active:text-white'
      }

      ${
        small
          ? `${hasDropDownItems ? 'text-sm px-3 py-2' : 'text-sm py-2 px-3'}`
          : 'text-base py-3 px-4'
      }
      ${
        props.disabled
          ? '!bg-mydra-medium-gray cursor-not-allowed !text-text'
          : `cursor-pointer`
      }
      transition-colors	duration-[2000]
      ${className ?? ''}`,
    },
    dropDownProps: {
      button: {
        className: `
          bg-gray-background pr-2 border-r border-y rounded-r-lg
      `,
      },
      menu: {
        className: `
          !p-0
      `,
      },
    },
  };

  return (
    <div
      {...slotProps.wrapperProps}
      className={`${hasDropDownItems ? 'flex' : 'block'} ${slotProps.wrapperProps?.className ?? ''}`}
    >
      <BaseButton
        disabled={isLoading || props.disabled}
        {...props}
        slotProps={{
          root: slotProps.root,
        }}
      >
        {isLoading ? <Refresh className="animate-spin" /> : props.children}
      </BaseButton>
      {hasDropDownItems && (
        <DropDown toggleButtonLabel="" slotProps={slotProps?.dropDownProps}>
          {dropdownItems}
        </DropDown>
      )}
    </div>
  );
}
