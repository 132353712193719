import { Button } from '@/modules/common/components/Button';
import { useEffect } from 'react';
import { useActiveEditingSectionContext } from '../../hooks/useActiveEditingSectionContext';

export type CourseEditingSectionProps = {
  sectionName: string;
  defaultSection: React.ReactNode;
  editingSection?: React.ReactNode;
  onSave?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  sectionTitle?: string;
  onToggle?: (isEditing: boolean) => void;
  hasError?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseEditingSection({
  sectionName,
  sectionTitle,
  editingSection,
  defaultSection,
  onSave,
  onCancel,
  onToggle,
  hasError,
  isLoading,
  disabled,
  ...props
}: CourseEditingSectionProps) {
  const {
    activeEditingSection,
    setActiveEditingSection,
    clearActiveEditingSection,
  } = useActiveEditingSectionContext();

  const isEditing =
    sectionName === activeEditingSection || activeEditingSection === 'all';

  useEffect(() => {
    onToggle?.(isEditing);
  }, [onToggle, isEditing]);

  return (
    <fieldset
      {...props}
      className={`text-sm ${props.className ?? ''} rounded-lg border p-4
        ${hasError ? 'border-red-500 ' : ''}
        ${disabled ? 'cursor-not-allowed' : ''}
      `}
    >
      <div
        className={`
        relative
          ${editingSection ? 'pr-20' : ''}
          ${disabled ? 'opacity-50' : ''}
      `}
      >
        {sectionTitle && (
          <h3 className="mb-5 text-lg font-medium leading-7 text-mydra-black">
            {sectionTitle}
          </h3>
        )}
        {isEditing ? (
          <>
            {editingSection}
            <div className="flex items-center gap-2 mt-3">
              <Button
                primary
                small
                isLoading={isLoading}
                disabled={isLoading || disabled}
                onClick={onSave}
              >
                Save
              </Button>
              <Button
                small
                disabled={isLoading || disabled}
                onClick={() => {
                  clearActiveEditingSection();
                  onCancel?.();
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            {!!editingSection && (
              <button
                className={`absolute top-0 right-0 px-4 py-2 text-xs font-medium border rounded-lg`}
                onClick={() => setActiveEditingSection(sectionName)}
                disabled={disabled}
              >
                Edit
              </button>
            )}
            {defaultSection}
          </>
        )}
      </div>
    </fieldset>
  );
}
