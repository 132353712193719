import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { TextField } from '@/modules/common/components/TextField';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ApiErrorResponse } from '@/types/api';
import { ITeam } from '@/types/team';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalProps } from '@mui/base';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAddTeamMember } from '../../hooks/useTeams';

const FORM_SCHEMA = yup.object().shape({
  email: yup.string().required('user email is required'),
});

type AddTeamMemberDialog = Omit<ModalProps, 'children'> & {
  onSuccess: (email?: string) => void;
  team: ITeam;
};

export function AddTeamMemberDialog({
  onSuccess,
  ...props
}: AddTeamMemberDialog) {
  const { showNotification } = useNotificationContext();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: { email: '' },
  });

  const { mutateAsync: addTeamMember, isPending } = useAddTeamMember();

  const handleAddTeamMemberRequest = async ({ email }: { email: string }) => {
    try {
      await addTeamMember({
        teamId: props.team._id!,
        email,
      });
      onSuccess(email);
      showNotification(
        `Team member ${email} added successfuly`,
        NotificationStyle.SUCCESS,
      );
    } catch (error) {
      if ((error as ApiErrorResponse).statusCode === 400) {
        return setError('root.serverError', {
          type: 'manual',
          message:
            (error as ApiErrorResponse).message ??
            'An error occurred. Please try again later.',
        });
      }
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleCancel = () => {
    props.onClose?.({}, 'backdropClick');
  };

  return (
    <Modal
      {...props}
      title={`Add Team Member`}
      subTitle={`Insert the email of a user to add to the ${props.team.name} team.`}
      onClose={(...args) => {
        reset();
        props.onClose?.(...args);
      }}
      className="max-w-xl mx-auto p-8 pb-3"
    >
      <form
        onSubmit={handleSubmit(handleAddTeamMemberRequest)}
        className="flex flex-col justify-end gap-3 p-2"
      >
        {errors.root?.serverError?.message && (
          <ul className="text-red-500 list-disc">
            {errors.root.serverError.message?.split(';').map((error, index) => (
              <li key={index}>
                {error}
                <br />
              </li>
            ))}
          </ul>
        )}
        <div className="flex flex-col gap-6">
          <div>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Email"
                  placeholder="ex. john@mycompany.com"
                />
              )}
            />
            {errors.email?.message && (
              <p className="text-sm text-red-500">{errors.email.message}</p>
            )}
          </div>
        </div>
        <hr className="absolute left-0 right-0 mb-14" />
        <div className="mt-14 flex justify-end space-x-4">
          <Button
            small
            type="submit"
            onClick={handleCancel}
            className="w-32 mt-6"
          >
            Cancel
          </Button>
          <Button
            primary
            small
            type="submit"
            className="w-32 mt-6"
            isLoading={isPending}
          >
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
}
