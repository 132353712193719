import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { CompanySettingsPage } from '@/modules/company-settings/pages/CompanySettingsPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_admin/settings/')({
  component: () => {
    return (
      <AdminLayout>
        <CompanySettingsPage />
      </AdminLayout>
    );
  },
});
