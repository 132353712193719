import { Button } from '@/modules/common/components/Button';
import { TextField } from '@/modules/common/components/TextField';
import { AuthLoginPayload } from '@/types/auth';
import { Controller, useFormContext } from 'react-hook-form';

type LoginFormProps = {
  onSubmit: (data: AuthLoginPayload) => Promise<void>;
  onForgotPassword: () => void;
  defaultEmail?: string;
};

export function LoginForm({
  onSubmit,
  onForgotPassword,
  ...props
}: LoginFormProps) {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useFormContext<AuthLoginPayload>();

  async function handleOnSubmit(data: AuthLoginPayload) {
    try {
      await onSubmit(data);
    } catch {
      reset(
        {},
        {
          keepValues: true,
          keepErrors: true,
        },
      );
      setError('root.serverError', {
        type: 'manual',
        message: 'E-mail or password is incorrect. Please try again.',
      });
    }
  }

  return (
    <form
      onSubmit={e => handleSubmit(handleOnSubmit)(e)}
      {...props}
      className="flex flex-col gap-6 text-mydra-black"
    >
      <div className="flex flex-col gap-6 mt-8 max-w-96 text-mydra-black">
        {errors.root?.serverError && (
          <p className="text-red-500">{errors.root.serverError.message}</p>
        )}
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              errorMessage={errors.email?.message as string}
              id="email"
              type="email"
              label="E-mail"
              placeholder="user@company.com"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              errorMessage={errors.password?.message as string}
              id="password"
              type="password"
              label="Password"
              placeholder="Password"
            />
          )}
        />
        <div className="order-last">
          <Button className="self-start mt-8" small type="submit" primary>
            Login
          </Button>
        </div>
        <button
          className="ml-auto"
          onClick={e => {
            e.preventDefault();
            onForgotPassword?.();
          }}
        >
          Forgot your password?
        </button>
      </div>
    </form>
  );
}
