import { useState } from 'react';

type ImageWithFallbackProps = {
  fallback?: React.ReactNode;
  fallbackSrc?: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export function ImageWithFallback({
  fallback,
  fallbackSrc,
  src,
  ...props
}: ImageWithFallbackProps) {
  const [hasError, setHasError] = useState(false);

  return hasError && fallback !== undefined && !fallbackSrc ? (
    fallback
  ) : (
    <img
      key={hasError.toString()}
      {...props}
      src={hasError || (!src && fallbackSrc) ? fallbackSrc : src}
      onError={() => {
        setHasError(true);
      }}
    />
  );
}
