import { PropsWithChildren, createContext, useCallback, useState } from 'react';
import {
  NotificationContextValue,
  NotificationState,
  NotificationStyle,
} from './NotificationProvider.types';

export const NotificationContext = createContext<NotificationContextValue>({
  notification: {
    message: '',
    style: NotificationStyle.INFO,
  },
  showNotification: () => {},
  clearNotification: () => {},
  isOpen: false,
});

export function NotificationProvider({ children }: PropsWithChildren) {
  const [notification, setNotification] = useState<NotificationState>({
    message: '',
    style: NotificationStyle.INFO,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showNotification = useCallback(
    (message: string, style: NotificationStyle = NotificationStyle.INFO) => {
      setNotification({
        message,
        style,
      });
      setIsOpen(true);
    },
    [],
  );

  const clearNotification = useCallback(() => {
    setIsOpen(false);
    setNotification({
      message: '',
      style: NotificationStyle.INFO,
    });
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notification,
        isOpen,
        showNotification,
        clearNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
