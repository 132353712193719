import { Modal, ModalProps } from '@/modules/common/components/Modal';
import { Button } from '../Button';

type ConfirmationModalProps = Omit<
  ModalProps,
  'onClose' | 'showCloseButton' | 'children'
> & {
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
};

export function ConfirmationModal({
  onConfirm,
  onCancel,
  isLoading,
  ...props
}: ConfirmationModalProps) {
  return (
    <Modal {...props} onClose={onCancel} showCloseButton={false}>
      <>
        <div className="flex gap-3 justify-end">
          <Button primary onClick={onConfirm} isLoading={isLoading}>
            Yes
          </Button>
          <Button onClick={onCancel} isLoading={isLoading}>
            No
          </Button>
        </div>
      </>
    </Modal>
  );
}
