import { MydraLogo } from '@/modules/common/components/MydraLogo';
import { LoginPageSearchParams } from '@/routes/login';
import { AuthLoginPayload } from '@/types/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { getRouteApi } from '@tanstack/react-router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ConfirmResetPasswordDialog } from '../components/ConfirmResetPasswordDialog copy';
import { LoginForm } from '../components/LoginForm';
import { ResetPasswordDialog } from '../components/ResetPasswordDialog';
import { useUserLogin } from '../hooks/useAuth';

const LOGIN_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email('Please provide a valid e-mail address')
    .required("E-mail can't be empty"),
  password: yup.string().required("Password can't be empty"),
});

const routeApi = getRouteApi('/login');

export function LoginPage() {
  const { useSearch, useNavigate } = routeApi;
  const { redirectTo = '/', token } = useSearch() as LoginPageSearchParams;
  const navigate = useNavigate();

  const [isConfirmResetPasswordDialogOpen, setConfirmResetPasswordDialogOpen] =
    useState(false);
  const [isResetPasswordDialogOpen, setResetPasswordDialogOpen] =
    useState(!!token);

  const { mutateAsync: login } = useUserLogin({
    retry: false,
  });

  const methods = useForm<AuthLoginPayload>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(LOGIN_SCHEMA),
  });

  const email = methods.watch('email');

  return (
    <>
      <FormProvider {...methods}>
        <div className="self-stretch w-full min-h-screen overflow-hidden">
          <div className="grid h-full grid-cols-12 ">
            <div className="flex flex-col self-center col-span-6 px-20">
              <MydraLogo />
              <h1 className="mt-8 text-2xl font-medium">
                Log in to your account
              </h1>
              <p className="mt-2 text-sm text-text">
                Enter your email address and password to log in.
              </p>
              <LoginForm
                onSubmit={async (data: AuthLoginPayload) => {
                  await login(data);
                  if (redirectTo) {
                    navigate({
                      to: redirectTo ?? '/',
                    });
                  }
                }}
                onForgotPassword={() => setConfirmResetPasswordDialogOpen(true)}
              />
            </div>
            <div className="relative flex col-span-6 bg-mydra-violet">
              <img
                src="/images/dashboard.jpg"
                className="object-cover object-left "
              />
            </div>
          </div>
        </div>
      </FormProvider>
      <ConfirmResetPasswordDialog
        open={isConfirmResetPasswordDialogOpen}
        onClose={() => setConfirmResetPasswordDialogOpen(false)}
        onSuccess={() => setConfirmResetPasswordDialogOpen(false)}
        defaultEmail={email}
        key={email}
      />
      <ResetPasswordDialog
        open={isResetPasswordDialogOpen}
        onClose={() => setResetPasswordDialogOpen(false)}
        onSuccess={(email?: string) => {
          setResetPasswordDialogOpen(false);
          methods.setValue('email', email ?? '', {
            shouldValidate: true,
          });

          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.delete('token');
          history.replaceState({}, '', currentUrl.toString());
        }}
        token={token}
      />
    </>
  );
}
