import { Button } from '@/modules/common/components/Button';
import { DropDown } from '@/modules/common/components/DropDown';
import { MenuItem } from '@/modules/common/components/MenuItem';

import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { CoursesSearchParams } from '@/routes/_admin/courses';
import { IEducationProviderWithoutUserObjects } from '@/types/education-provider';
import { AutoAwesome, List } from '@mui/icons-material';
import { keepPreviousData } from '@tanstack/react-query';
import { Link, getRouteApi } from '@tanstack/react-router';
import { PaginationState } from '@tanstack/react-table';
import { ListCoursesTable } from '../components/ListCoursesTable/ListCoursesTable';
import { useGetCategories } from '../hooks/useCategories';
import { useGetCourses } from '../hooks/useCourses';

const routeApi = getRouteApi('/_admin/courses/');

export function ListCoursesPage() {
  const search = routeApi.useSearch() as CoursesSearchParams;
  const navigate = routeApi.useNavigate();

  const {
    currentUser: { educationProvider = '' } = {},
    isLoading: isLoadingUser,
  } = useCurrentUserContext() ?? {};

  const {
    isLoading,
    data: { data: courses = [], metadata: { total = 0, page = 1 } = {} } = {},
  } = useGetCourses(
    {
      educationProvider:
        (educationProvider as IEducationProviderWithoutUserObjects)?._id ?? '',
      ...(search.category && { categories: [search.category as string] }),
      ...(search.published !== undefined && { published: search.published }),
    },
    {
      page: search.page,
      limit: 10,
    },
    {
      enabled: !isLoadingUser,
      placeholderData: keepPreviousData,
    },
  );

  const { data: { data: categories = [] } = {} } = useGetCategories();

  const paginationState = {
    pageIndex: (page ?? 1) - 1 || 0,
    pageSize: 10,
  };

  const onPaginationChange = ({ pageIndex }: PaginationState) => {
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        page: pageIndex + 1,
      }),
    });
  };

  const publishedLabel =
    search.published !== undefined
      ? search.published
        ? 'Published'
        : 'Unpublished'
      : 'All';
  const categoryLabel =
    categories.find(category => category._id === search.category)?.name ??
    'All';

  const showClearFilters =
    Object.entries(search).filter(([key]) => key !== 'page').length > 0;

  return (
    <div className="relative flex flex-col h-full">
      <header className="sticky top-0 w-full bg-white">
        <div className="flex items-center justify-between px-12 py-5 border-b">
          <h1 className="text-2xl font-medium">Your courses</h1>
          <Button
            primary
            onClick={() =>
              navigate({
                to: '/courses/add',
                search: {
                  enableAi: true,
                },
              })
            }
            dropdownItems={
              <>
                <MenuItem
                  className="border-b last-of-type:border-none"
                  onClick={() => {
                    navigate({
                      to: '/courses/add',
                    });
                  }}
                >
                  Add course manually
                </MenuItem>
              </>
            }
          >
            <div className="flex gap-2">
              <AutoAwesome />
              Add course
            </div>
          </Button>
        </div>
        <div className="px-12 border-b">
          <div className="inline-flex items-center gap-2 py-5 text-base font-medium border-b-4 border-black">
            <List />
            Courses list
          </div>
        </div>
        <div className="flex gap-12 px-12 py-6">
          <div className="flex items-center">
            <p className="text-xs font-medium">Category:</p>
            <DropDown
              toggleButtonLabel={categoryLabel}
              slotProps={{
                button: {
                  className: 'text-xs font-medium',
                },
              }}
            >
              {categories.map(category => (
                <MenuItem
                  key={category._id}
                  className="text-xs"
                  onClick={() => {
                    navigate({
                      search: prev => ({
                        ...prev,
                        category: category._id,
                        page: 1,
                      }),
                    });
                  }}
                >
                  {category.name}
                </MenuItem>
              ))}
            </DropDown>
          </div>
          {/**
         * TODO:
        <div className="flex items-center">
          <p className="text-xs font-medium">Price:</p>
        </div>
        */}
          <div className="flex items-center">
            <p className="text-xs font-medium">Status:</p>
            <DropDown
              toggleButtonLabel={publishedLabel}
              slotProps={{
                button: {
                  className: 'text-xs font-medium',
                },
              }}
            >
              <MenuItem
                className="text-xs"
                onClick={() => {
                  navigate({
                    search: prev => ({
                      ...prev,
                      published: true,
                      page: 1,
                    }),
                  });
                }}
              >
                Published
              </MenuItem>
              <MenuItem
                className="text-xs"
                onClick={() => {
                  navigate({
                    search: prev => ({
                      ...prev,
                      published: false,
                      page: 1,
                    }),
                  });
                }}
              >
                Unpublished
              </MenuItem>
              <MenuItem
                className="text-xs"
                onClick={() => {
                  navigate({
                    search: prev =>
                      Object.entries(prev).reduce((acc, [key, value]) => {
                        if (key === 'published') {
                          return acc;
                        }

                        return { ...acc, [key]: value, page: 1 };
                      }, {}),
                  });
                }}
              >
                All
              </MenuItem>
            </DropDown>
          </div>
          {showClearFilters && (
            <div className="flex items-center">
              <Link
                className="text-xs font-medium text-mydra-purple"
                search={{
                  page: 1,
                }}
              >
                Clear
              </Link>
            </div>
          )}
        </div>
      </header>

      <div className="flex-1 px-12">
        <ListCoursesTable
          isLoading={isLoading}
          onPaginationChange={updater => {
            return typeof updater === 'function'
              ? onPaginationChange(updater(paginationState))
              : onPaginationChange(updater);
          }}
          courses={courses}
          paginationState={paginationState}
          rowCount={total}
        />
      </div>
    </div>
  );
}
