import React from 'react';

export function PaginationButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={`p-2 text-sm min-w-10 min-h-10 bg-white border rounded cursor-pointer text-text disabled:cursor-not-allowed disabled:hover:bg-white disabled:border-none hover:bg-gray-background ${props.className ?? ''}`}
    >
      {children}
    </button>
  );
}
