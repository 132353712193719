import { PropsWithChildren, createContext, useState } from 'react';

type ActiveEditingSectionContextValue = {
  activeEditingSection: string | null;
  setActiveEditingSection: (sectionId: string | null) => void;
  clearActiveEditingSection: () => void;
  openAllSections: () => void;
};

export const ActiveEditingSectionContext =
  createContext<ActiveEditingSectionContextValue>({
    activeEditingSection: null,
    setActiveEditingSection: () => {},
    clearActiveEditingSection: () => {},
    openAllSections: () => {},
  });

export const ActiveEditingSectionContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [activeEditingSection, setActiveEditingSection] = useState<
    string | null
  >('');

  const clearActiveEditingSection = () => {
    setActiveEditingSection(null);
  };

  const openAllSections = () => {
    setActiveEditingSection('all');
  };

  return (
    <ActiveEditingSectionContext.Provider
      value={{
        activeEditingSection,
        setActiveEditingSection,
        clearActiveEditingSection,
        openAllSections,
      }}
    >
      {children}
    </ActiveEditingSectionContext.Provider>
  );
};
