import { UpsertCoursePage } from '@/modules/courses/pages/UpsertCoursePage';
import { createFileRoute } from '@tanstack/react-router';
import * as yup from 'yup';

export type AddCoursePageSearchParams = {
  enableAi?: boolean;
};

export const Route = createFileRoute('/_admin/courses/add')({
  validateSearch: (
    search: Record<string, unknown>,
  ): AddCoursePageSearchParams => ({
    enableAi: yup.boolean().optional().validateSync(search.enableAi),
  }),
  component: () => <UpsertCoursePage />,
});
