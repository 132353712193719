import {
  ApiPaginationParams,
  ApiQueryParams,
  IncludeApiQueryParam,
} from '@/types/api';
import {
  ApiIndividualPayment,
  ApiPaginatedPayments,
  ApiPayPayments,
} from '@/types/payment';
import qs from 'query-string';
import { ApiService } from './api';

export class PaymentService extends ApiService {
  static PATH = '/payments';

  static async getPayments(
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
    include?: IncludeApiQueryParam,
  ): Promise<ApiPaginatedPayments> {
    const queryString = qs.stringify({ ...filters, ...pagination, include });
    return ApiService.get(`${this.PATH}?${queryString}`);
  }

  static async getPayment(id: string): Promise<ApiIndividualPayment> {
    return ApiService.get(`${this.PATH}/${id}`);
  }

  static async pay(data: { paymentIds: string[] }): Promise<ApiPayPayments> {
    return ApiService.post(`${this.PATH}/pay`, data);
  }
}
