import { LandingPagesService } from '@/services/landing-pages';
import { ApiPaginationParams } from '@/types/api';
import { ILandingPageListFilters } from '@/types/landing-page';
import { useQuery } from '@tanstack/react-query';

export function useLandingPages({
  filters,
  pagination,
}: {
  filters?: ILandingPageListFilters;
  pagination?: ApiPaginationParams;
}) {
  return useQuery({
    queryKey: ['landing-pages'],
    queryFn: () => LandingPagesService.getLandingPages(filters, pagination),
  });
}
