import { LandingPagesService } from '@/services/landing-pages';
import { ApiErrorResponse, ApiReactMutationOptions } from '@/types/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useLandingPageDeletion(
  options: ApiReactMutationOptions<void, string> = {},
) {
  const mutationKey = [LandingPagesService.PATH];
  const queryClient = useQueryClient();

  return useMutation<void, ApiErrorResponse, string>({
    ...options,
    mutationKey: mutationKey,
    mutationFn: LandingPagesService.deleteLandingPage,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [LandingPagesService.PATH],
        exact: false,
      });
    },
  });
}
