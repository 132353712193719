import { CompanyBasicSettings } from '../components/CompanyBasicSettingsForm';
import { CompanyTaxBenefitsProvider } from '../components/CompanyTaxBenefitsProvider';

export function CompanySettingsPage() {
  return (
    <div className="flex flex-col h-full text-mydra-black">
      <header className="flex justify-between items-center px-12 pt-20">
        <h1 className="text-2xl font-medium">Company settings</h1>
      </header>
      <main className="flex flex-col gap-4 px-12 pb-12">
        <CompanyBasicSettings className="mt-8" />
        <CompanyTaxBenefitsProvider className="mt-10" />
      </main>
    </div>
  );
}
