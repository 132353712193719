import { ImageWithFallback } from '@/modules/common/components/ImageWithFallback';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteOutline, Link, Refresh } from '@mui/icons-material';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { useFileUpload } from '../../hooks/useFileUpload';
import { CourseInstructorsSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';

type FormValues = Pick<ICourse, 'instructors'>;

type CourseInstructorsProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseInstructors({
  onSubmit,
  ...props
}: CourseInstructorsProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const instructors = getValues('instructors');
  const { showNotification } = useNotificationContext();
  const { mutateAsync: uploadFile, isPending: isUploadingFile } =
    useFileUpload();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isSubmitting, errors: formErrors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      instructors: instructors ?? [],
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CourseInstructorsSchema),
  });

  const {
    fields: instructorFields,
    append: appendInstructorField,
    remove: removeInstructorField,
  } = useFieldArray({
    control,
    name: 'instructors',
  });

  const handleAddInstructor = () => {
    appendInstructorField({
      name: '',
      linkedIn: '',
      website: '',
      avatarUrl: '',
      role: '',
      company: '',
      bio: '',
    });
  };

  const handleInstructorPhotoUpload =
    (index: number) => async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        return;
      }

      try {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function (e) {
          clearErrors(`instructors.${index}.avatarUrl`);
          const image = new Image();

          if (!e.target?.result) {
            setError(
              `instructors.${index}.avatarUrl`,
              {
                message: 'Invalid image file. Please try again.',
              },
              {
                shouldFocus: true,
              },
            );
            return;
          }

          image.src = e.target.result as string;

          image.onload = async function () {
            const height = (image as HTMLImageElement).naturalHeight;
            const width = (image as HTMLImageElement).naturalWidth;
            if (height < 300 || width < 300) {
              setError(
                `instructors.${index}.avatarUrl`,
                {
                  message: 'Image must have at least 300 px × 300 px',
                },
                {
                  shouldFocus: true,
                },
              );
              return;
            }

            const formData = new FormData();
            formData.append('file', file);

            const { data: { url = '' } = {} } = await uploadFile(formData);
            setValue(`instructors.${index}.avatarUrl`, url);
          };

          image.onerror = function () {
            setError(
              `instructors.${index}.avatarUrl`,
              {
                message: 'Invalid image file. Please try again.',
              },
              {
                shouldFocus: true,
              },
            );
          };
        };
      } catch {
        showNotification(
          'Failed to upload the image, please try again later.',
          NotificationStyle.ERROR,
        );
      }
    };

  const activeEditingInstructors = watch('instructors');

  const hasError = !!errors.instructors;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      className={`${props.className}`}
      sectionName="course-instructors"
      sectionTitle="Instructors"
      defaultSection={
        <div className="flex flex-col gap-4">
          {instructors?.length ? (
            <>
              {(instructors ?? []).map(
                ({ linkedIn, name, role, website }, index) => (
                  <div key={index} className="flex flex-col gap-3">
                    <p>
                      {[name, role].filter(Boolean).map((text, index) => (
                        <span
                          key={index}
                          className={`${index === 0 ? 'font-bold' : ''}`}
                        >
                          {text || '(no comment set)'}{' '}
                        </span>
                      ))}
                    </p>
                    <div className="p-4 border rounded-xl">
                      <div className="flex gap-1">
                        <Link sx={{ fontSize: 18 }} />
                        <p className="text-sm font-medium text-mydra-black">
                          {linkedIn || '(no LinkedIn set)'}
                        </p>
                      </div>
                    </div>
                    <div className="p-4 border rounded-xl">
                      <div className="flex gap-1">
                        <Link sx={{ fontSize: 18 }} />
                        <p className="text-sm font-medium text-mydra-black">
                          {website || '(no website set)'}
                        </p>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </>
          ) : (
            <p className="text-text">No instructors set</p>
          )}
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-12">
          <button
            className="absolute top-0 right-0 px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
            onClick={handleAddInstructor}
          >
            <div className="flex items-center">
              <Add sx={{ fontSize: 18 }} /> Add Instructor
            </div>
          </button>

          {instructorFields.map((instructorField, index) => (
            <div key={instructorField.id} className="flex flex-col gap-3">
              <div className="flex gap-4">
                <ImageWithFallback
                  src={activeEditingInstructors[index]?.avatarUrl}
                  fallback={
                    <div className="flex items-center justify-center w-20 h-20 rounded-full shrink-0 bg-mydra-gray">
                      <p className="text-xxs">No photo set</p>
                    </div>
                  }
                  alt="Instructor"
                  className="object-cover w-20 h-20 rounded-full shrink-0"
                />
                <label className="flex flex-col w-full">
                  <p>Upload photo</p>
                  <p className="text-sm">
                    Photo must have at least 300 px × 300 px
                  </p>
                  <div className="flex flex-wrap items-center gap-1 mt-2 lg:flex-nowrap">
                    <label
                      htmlFor={`instructor${index}Photo`}
                      className="px-3 py-2 border rounded-lg cursor-pointer shrink-0 active:bg-mydra-gray text-mydra-purple"
                    >
                      {isUploadingFile ? (
                        <Refresh className="animate-spin" />
                      ) : (
                        'Upload photo'
                      )}
                    </label>
                    <input
                      id={`instructor${index}Photo`}
                      type="file"
                      accept="image/*"
                      className="invisible w-0 h-0"
                      disabled={isUploadingFile}
                      onChange={handleInstructorPhotoUpload(index)}
                    />
                    or
                    <input
                      {...register(`instructors.${index}.avatarUrl`)}
                      className="inline-block w-full p-2 border rounded-xl"
                      placeholder="Avatar URL link"
                    />
                  </div>
                  {formErrors?.instructors?.[index]?.avatarUrl?.message && (
                    <p className="mt-1 text-red-500">
                      {formErrors?.instructors?.[index]?.avatarUrl?.message}
                    </p>
                  )}
                </label>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex gap-5">
                  <label className="flex flex-col flex-1 gap-2">
                    <p className="font-medium">Name</p>
                    <input
                      {...register(`instructors.${index}.name`)}
                      className="w-full p-2 border rounded-xl"
                      placeholder="John Doe"
                    />
                    {formErrors?.instructors?.[index]?.name?.message && (
                      <p className="mt-1 text-red-500">
                        {formErrors?.instructors?.[index]?.name?.message}
                      </p>
                    )}
                  </label>
                  <label className="flex flex-col flex-1 gap-2">
                    <p className="font-medium">Job role</p>
                    <input
                      {...register(`instructors.${index}.role`)}
                      className="w-full p-2 border rounded-xl"
                      placeholder="Creative director"
                    />
                    {formErrors?.instructors?.[index]?.role?.message && (
                      <p className="mt-1 text-red-500">
                        {formErrors?.instructors?.[index]?.role?.message}
                      </p>
                    )}
                  </label>
                </div>
                <label className="flex flex-col gap-2">
                  <p className="font-medium">Company</p>
                  <input
                    {...register(`instructors.${index}.company`)}
                    className="w-full p-2 border rounded-xl"
                    placeholder="John Doe"
                  />
                  {formErrors?.instructors?.[index]?.company?.message && (
                    <p className="mt-1 text-red-500">
                      {formErrors?.instructors?.[index]?.company?.message}
                    </p>
                  )}
                </label>
                <label className="flex flex-col gap-2">
                  <p className="font-medium">Bio</p>
                  <TextareaAutosize
                    className="w-full p-2 border rounded-xl"
                    {...register(`instructors.${index}.bio`)}
                    placeholder="Creative Director, OCA. Clients include Meta, Google, Ogilvy, Mckinney, & Nike"
                  />
                  {formErrors?.instructors?.[index]?.bio?.message && (
                    <p className="mt-1 text-red-500">
                      {formErrors?.instructors?.[index]?.bio?.message}
                    </p>
                  )}
                </label>
                <div className="p-4 border rounded-xl">
                  <label className="flex flex-col gap-2">
                    <div className="flex gap-1">
                      <Link sx={{ fontSize: 18 }} />
                      <p className="font-medium">LinkedIn</p>
                    </div>
                    <input
                      {...register(`instructors.${index}.linkedIn`)}
                      className="w-full p-2 border rounded-xl"
                      placeholder="John Doe"
                    />
                    {formErrors?.instructors?.[index]?.linkedIn?.message && (
                      <p className="mt-1 text-red-500">
                        {formErrors?.instructors?.[index]?.linkedIn?.message}
                      </p>
                    )}
                  </label>
                </div>
                <div className="p-4 border rounded-xl">
                  <label className="flex flex-col gap-2">
                    <div className="flex gap-1">
                      <Link sx={{ fontSize: 18 }} />
                      <p className="font-medium">Website</p>
                    </div>
                    <input
                      {...register(`instructors.${index}.website`)}
                      className="w-full p-2 border rounded-xl"
                      placeholder="John Doe"
                    />
                    {formErrors?.instructors?.[index]?.website?.message && (
                      <p className="mt-1 text-red-500">
                        {formErrors?.instructors?.[index]?.website?.message}
                      </p>
                    )}
                  </label>
                </div>
              </div>
              <button
                className="self-start px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
                onClick={() => removeInstructorField(index)}
              >
                <div className="flex items-center gap-2">
                  <DeleteOutline sx={{ fontSize: 18 }} /> Delete
                </div>
              </button>
            </div>
          ))}
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() =>
        reset({
          instructors,
        })
      }
    ></CourseEditingSection>
  );
}
