import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ListOfCompaniesPage } from '@/modules/tax-benefit-providers/pages/ListOfCompanies';
import { ListOfCompaniesPageSearchParams } from '@/types/company';
import { createFileRoute } from '@tanstack/react-router';
import * as yup from 'yup';

export const Route = createFileRoute('/_admin/companies/')({
  component: () => (
    <AdminLayout>
      <ListOfCompaniesPage />
    </AdminLayout>
  ),
  validateSearch: (
    search: Record<string, unknown>,
  ): ListOfCompaniesPageSearchParams => {
    return {
      page: yup.number().integer().min(1).optional().validateSync(search.page),
      limit: yup
        .number()
        .integer()
        .min(1)
        .optional()
        .validateSync(search.limit),
    };
  },
});
