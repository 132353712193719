import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ImageOutlined,
  PictureAsPdf,
  PictureAsPdfOutlined,
} from '@mui/icons-material';
import { useForm, useFormContext } from 'react-hook-form';
import { AdditionalResourcesSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';
import { CourseItem } from '../CourseItem';

type FormValues = Pick<ICourse, 'brochureUrl' | 'imageUrl'>;

type CourseAdditionalResourcesProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseAdditionalResources({
  onSubmit,
  ...props
}: CourseAdditionalResourcesProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [brochureUrl, imageUrl] = getValues(['brochureUrl', 'imageUrl']);

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      brochureUrl: brochureUrl ?? '',
      imageUrl: imageUrl ?? '',
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(AdditionalResourcesSchema),
  });

  const hasError = !!errors.brochureUrl || !!errors.imageUrl;

  return (
    <CourseEditingSection
      hasError={hasError}
      {...props}
      className={`${props.className}`}
      sectionName="course-additional-resources"
      sectionTitle="Additional Resources"
      defaultSection={
        <div className="flex flex-col gap-4">
          <CourseItem
            inline
            label="Course Brochure:"
            icon={<PictureAsPdfOutlined />}
          >
            <a className="underline text-mydra-purple" href={brochureUrl}>
              {brochureUrl ?? 'Not provided'}
            </a>
          </CourseItem>
          <CourseItem inline label="Course Image:" icon={<ImageOutlined />}>
            <a className="underline text-mydra-purple" href={imageUrl}>
              {imageUrl ?? 'Not provided'}
            </a>
          </CourseItem>
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-4">
          <CourseItem hasInput label="Course Brochure" icon={<PictureAsPdf />}>
            <input
              {...register('brochureUrl')}
              className="px-3 py-2 border rounded-xl"
              placeholder="Link URL"
            />
            {formErrors.brochureUrl?.message && (
              <p className="mt-1 text-red-500">
                {formErrors.brochureUrl.message}
              </p>
            )}
          </CourseItem>
          <CourseItem hasInput label="Course Image" icon={<PictureAsPdf />}>
            <input
              {...register('imageUrl')}
              className="px-3 py-2 border rounded-xl"
              placeholder="Link URL"
            />
            {formErrors.imageUrl?.message && (
              <p className="mt-1 text-red-500">{formErrors.imageUrl.message}</p>
            )}
          </CourseItem>
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() => {
        reset({
          brochureUrl,
          imageUrl,
        });
      }}
    />
  );
}
