import { ImageWithFallback } from '@/modules/common/components/ImageWithFallback';
import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteOutline } from '@mui/icons-material';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { CourseTestimonialsSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';

type FormValues = Pick<ICourse, 'testimonials'>;

type CourseTestimonialsProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseTestimonials({
  onSubmit,
  ...props
}: CourseTestimonialsProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const testimonials = getValues('testimonials');

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isSubmitting, errors: formErrors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      testimonials: testimonials ?? [],
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CourseTestimonialsSchema),
  });

  const {
    fields: testimonialFields,
    append: appendTestimonialField,
    remove: removeTestimonialField,
  } = useFieldArray({
    control,
    name: 'testimonials',
  });

  const handleAddTestimonial = () => {
    appendTestimonialField({
      comment: '',
      author: '',
      authorRole: '',
      avatarUrl: '',
    });
  };

  const activeEditingTestimonials = watch('testimonials');
  const hasError = !!errors.testimonials;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      className={`${props.className}`}
      sectionName="course-testimonials"
      sectionTitle="Testimonials"
      defaultSection={
        <div className="flex flex-col">
          {testimonials?.length ? (
            <>
              {(testimonials ?? []).map(
                ({ comment, author, authorRole, avatarUrl }, index) => (
                  <div key={index} className="flex gap-4">
                    <ImageWithFallback
                      src={avatarUrl}
                      fallback={
                        <div className="flex items-center justify-center w-20 h-20 rounded-full shrink-0 bg-mydra-gray">
                          <p className="text-xxs">No photo set</p>
                        </div>
                      }
                      alt="Instructor"
                      className="object-cover w-20 h-20 rounded-full shrink-0"
                    />
                    <blockquote className="p-4 border rounded-xl">
                      <p className="text-sm">{comment}</p>
                      <p className="font-medium">{`${author ?? '(no author name)'} - ${authorRole ?? '(no author role)'}`}</p>
                    </blockquote>
                  </div>
                ),
              )}
            </>
          ) : (
            <p className="text-text">No testimonials set</p>
          )}
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-8">
          <button
            className="absolute top-0 right-0 px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
            onClick={handleAddTestimonial}
          >
            <div className="flex items-center">
              <Add sx={{ fontSize: 18 }} /> Add tetsimonial
            </div>
          </button>

          {testimonialFields.map((testimonialField, index) => (
            <div key={testimonialField.id} className="flex flex-col gap-2">
              <div className="flex flex-col gap-4 p-4 border rounded-xl">
                <label className="flex flex-col gap-2">
                  <p className="font-medium">Comment</p>

                  <TextareaAutosize
                    rows={5}
                    {...register(`testimonials.${index}.comment`)}
                    className="w-full p-2 border rounded-xl"
                    placeholder="Thanks to Mydra I was able to change my career path and earn three times as much as I was earning before, by simply honing the most in-demand skills."
                  />
                </label>
                {formErrors?.testimonials?.[index]?.comment?.message && (
                  <p className="mt-1 text-red-500">
                    {formErrors?.testimonials?.[index]?.comment?.message}
                  </p>
                )}
                <div className="flex gap-4">
                  <label className="flex flex-col flex-1 gap-2">
                    <p className="font-medium">Author name</p>

                    <input
                      {...register(`testimonials.${index}.author`)}
                      className="w-full p-2 border rounded-xl"
                      placeholder="John Doe"
                    />
                  </label>
                  {formErrors?.testimonials?.[index]?.author?.message && (
                    <p className="mt-1 text-red-500">
                      {formErrors?.testimonials?.[index]?.author?.message}
                    </p>
                  )}
                  <label className="flex flex-col flex-1 gap-2">
                    <p className="font-medium">Author role</p>

                    <input
                      {...register(`testimonials.${index}.authorRole`)}
                      className="w-full p-2 border rounded-xl"
                      placeholder="Creative Director at Meta"
                    />
                  </label>
                  {formErrors?.testimonials?.[index]?.authorRole?.message && (
                    <p className="mt-1 text-red-500">
                      {formErrors?.testimonials?.[index]?.authorRole?.message}
                    </p>
                  )}
                </div>
                <div className="flex gap-4">
                  {activeEditingTestimonials?.[index]?.avatarUrl ? (
                    <ImageWithFallback
                      src={activeEditingTestimonials[index]?.avatarUrl}
                      fallback={
                        <div className="flex items-center justify-center w-20 h-20 rounded-full shrink-0 bg-mydra-gray">
                          <p className="text-xxs">Invalid image</p>
                        </div>
                      }
                      alt="Instructor"
                      className="object-cover w-20 h-20 rounded-full shrink-0"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-20 h-20 rounded-full bg-mydra-gray">
                      <p className="text-xxs">No photo set</p>
                    </div>
                  )}
                  <label className="flex flex-col">
                    <p>Photo</p>
                    <input
                      {...register(`testimonials.${index}.avatarUrl`)}
                      className="p-2 mt-2 border rounded-xl"
                      placeholder="Avatar URL link"
                    />
                    {formErrors?.testimonials?.[index]?.avatarUrl?.message && (
                      <p className="mt-1 text-red-500">
                        {formErrors?.testimonials?.[index]?.avatarUrl?.message}
                      </p>
                    )}
                  </label>
                </div>
              </div>

              <button
                className="self-start px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
                onClick={() => removeTestimonialField(index)}
              >
                <div className="flex items-center gap-2">
                  <DeleteOutline sx={{ fontSize: 18 }} /> Delete
                </div>
              </button>
            </div>
          ))}
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() => reset({ testimonials })}
    ></CourseEditingSection>
  );
}
