import { ApiPaginationParams, ApiQueryParams } from '@/types/api';
import {
  ApiIndividualUser,
  ApiPaginatedUser,
  IUser,
  UserInclude,
} from '@/types/user';
import qs from 'query-string';
import { ApiService } from './api';

export class UserService extends ApiService {
  static PATH = '/users';

  static async getUsers(
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedUser> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${UserService.PATH}?${queryString}`);
  }

  static async getUser(id: string): Promise<ApiIndividualUser> {
    return ApiService.get(`${UserService.PATH}/${id}`);
  }

  static async getCurrentUser(
    include?: UserInclude[],
  ): Promise<ApiIndividualUser> {
    const queryString = qs.stringify({ include }, { arrayFormat: 'index' });
    return ApiService.get(`${UserService.PATH}/me?${queryString}`);
  }

  static async createUser(payload: IUser): Promise<ApiIndividualUser> {
    return ApiService.post(UserService.PATH, payload);
  }

  static async updateUser(payload: IUser): Promise<ApiIndividualUser> {
    const { _id: id } = payload;
    return ApiService.put(`${UserService.PATH}/${id}`, payload);
  }
}
