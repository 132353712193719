import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { ICategory } from './category';
import { IEducationProvider } from './education-provider';
import { IEducationProviderAgreement } from './education-provider-agreement';

export type ApiIndividualCourse = ApiIndividualResponse<ICourse>;
export type ApiPaginatedCourse = ApiPaginatedResponse<ICourse>;
export type ApiIndividualCourseId = ApiIndividualResponse<IndividualCourseId>;

export type IndividualCourseId = {
  courseId: string;
};

export enum ValidationType {
  CAPACITY = 'capacity',
  SKILLS = 'skills',
  ADMISSION_PROCESS = 'admission-process',
}

export interface IInstructor {
  name: string;
  linkedIn: string;
  website: string;
  avatarUrl: string;
  role: string;
  company: string;
  bio: string;
}

interface ICost {
  amount?: number;
  currency: string;
}

export interface ICohort {
  _id: string;
  location: string;
  startDate: string | null;
  endDate: string | null;
  format: string;
  cost: ICost | null;
  duration: IDuration | null;
  classType: string;
}

interface ITestimonial {
  comment: string;
  author: string;
  authorRole: string;
  avatarUrl: string;
}

export interface IProgrammeStructure {
  title: string;
  subtitle: string;
  description: string;
}

interface ITarget {
  title: string;
  description: string;
}

interface ICourseMotivation {
  videoUrl: string;
  motivation: string;
  advisor: {
    name: string;
    role: string;
    avatarUrl: string;
  };
}

export interface ICourseDetailTranslation {
  course?: string;
  language?: string;
  name?: string;
  targets?: ITarget[];
  prerequisites?: string[];
  summary?: string;
  learningAreas?: string[];
  learningDescription?: string;
  instructors?: IInstructor[];
  achievements?: string[];
  testimonials?: ITestimonial[];
  programmeStructure?: IProgrammeStructure[];
  courseMotivation?: ICourseMotivation;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum DurationType {
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export interface IDuration {
  quantity: number;
  type: DurationType;
  quantityInDays: number;
}

export interface ICourse {
  _id: string;
  name: string;
  externalId?: string;
  educationProvider: IEducationProvider | string;
  agreement?: IEducationProviderAgreement | string;
  agreements?: IEducationProviderAgreement[] | string[];
  categories: ICategory[];
  subCategories: string[];
  suggestedCategory?: string;
  suggestedSubCategories?: string[];
  cohorts?: ICohort[];
  type: string;
  targets?: ITarget[];
  prerequisites: string[];
  skills: string[];
  url: string;
  imageUrl: string;
  summary: string;
  learningAreas?: string[];
  learningDescription: string;
  language?: string;
  level?: string;
  instructors: IInstructor[];
  note?: string;
  metadata?: string;
  validationType?: ValidationType;
  commentsToValidation?: string;
  videoUrl?: string;
  brochureUrl?: string;
  achievements?: string[];
  testimonials?: ITestimonial[];
  programmeStructure?: IProgrammeStructure[];
  courseMotivation?: ICourseMotivation;
  hasCertificate?: boolean;
  published?: boolean;
  // TODO: remove when change the marketplace to work with cohorts.
  courseDetailTranslations?: ICourseDetailTranslation[];
  cost?: ICost;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface ICourseWithEducationProvider extends ICourse {
  educationProvider: IEducationProvider;
}

export enum TranslatableFields {
  name,
  targets,
  prerequisites,
  summary,
  learningAreas,
  learningDescription,
  instructors,
  achievements,
  testimonials,
  programmeStructure,
  courseMotivation,
}

export type ICourseUpdatePayload = Pick<
  ICourse,
  | 'brochureUrl'
  | 'imageUrl'
  | 'published'
  | 'cohorts'
  | 'skills'
  | 'courseDetailTranslations'
  | 'type'
  | 'language'
  | 'level'
  | 'hasCertificate'
  | 'url'
> & {
  categoryIds: string[];
  subCategories: string[];
};

export type ICourseCreatePayload = Required<ICourseUpdatePayload> & {
  educationProviderId: string;
};

export type ICourseCreatePayloadWithAI = {
  educationProviderId: string;
  context?: string;
  courseUrl: string;
  environment: 'Staging' | 'Production';
};
