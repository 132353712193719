import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { ICompany } from './company';
import { IEducationProviderWithoutUserObjects } from './education-provider';
import { IProfile } from './profile';
import { ITaxBenefitsProvider } from './tax-benefits-provider';
import { ITeam } from './team';

export type ApiIndividualUser = ApiIndividualResponse<IUser>;
export type ApiPaginatedUser = ApiPaginatedResponse<IUser>;

export type IUser = {
  _id?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  avatarUrl?: string;
  roles: string[];
  isActive: boolean;
  educationProvider: string | null;
  jobRole?: string;
  company?: string | ICompany;
  team?: string | Partial<Pick<ITeam, '_id' | 'name' | 'learningBudgetLimit'>>;
  taxBenefitProviders?: ITaxBenefitsProvider[];
  lastLogin?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type IUserWithTeam = Omit<IUser, 'team'> & {
  team?: Omit<ITeam, 'members'> & {
    members: string[];
  };
};

export type IUserWithEducationProvider = Omit<IUser, 'educationProvider'> & {
  educationProvider: IEducationProviderWithoutUserObjects;
};

export type IUserWithProfile = IUser & {
  profile: Pick<IProfile, 'currentRole' | 'linkedInUrl' | 'skills'>;
};

export enum UserInclude {
  TaxBenefitProviders = 'taxBenefitProviders',
  Company = 'company',
  EducationProvider = 'educationProvider',
}

export enum UserRole {
  SuperAdmin = 'superadmin',
  EducationProvider = 'educationprovider',
  User = 'user',
  Applicant = 'applicant',
  Employer = 'employer',
  TaxBenefitsProvider = 'taxbenefitsprovider',
}
