import { LoginPage } from '@/modules/auth/pages/LoginPage';
import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { createFileRoute } from '@tanstack/react-router';

export type LoginPageSearchParams = {
  redirectTo?: string;
  token?: string;
};

export const Route = createFileRoute('/login')({
  component: () => <LoginPage />,
  validateSearch: (search: Record<string, unknown>): LoginPageSearchParams => {
    return search.redirectTo
      ? {
          redirectTo: search.redirectTo.toString().startsWith('/')
            ? search.redirectTo.toString()
            : '',
          token: search.token?.toString() ?? '',
        }
      : {};
  },
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 500 }} reset={() => null} />;
  },
});
