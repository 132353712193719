import { Switch as BaseSwitch, SwitchProps } from '@mui/base/Switch';
import clsx from 'clsx';
import { forwardRef } from 'react';

const resolveSlotProps = (fn: unknown, args: unknown) =>
  typeof fn === 'function' ? fn(args) : fn;

export const Switch = forwardRef<HTMLSpanElement, SwitchProps>((props, ref) => {
  return (
    <BaseSwitch
      ref={ref}
      {...props}
      slotProps={{
        ...props.slotProps,
        root: ownerState => {
          const resolvedSlotProps = resolveSlotProps(
            props.slotProps?.root,
            ownerState,
          );
          return {
            ...resolvedSlotProps,
            className: clsx(
              `group relative inline-block w-[38px] h-[24px] ${
                ownerState.disabled
                  ? 'cursor-not-allowed opacity-40'
                  : 'cursor-pointer'
              }`,
              resolvedSlotProps?.className,
            ),
          };
        },
        input: ownerState => {
          const resolvedSlotProps = resolveSlotProps(
            props.slotProps?.input,
            ownerState,
          );
          return {
            ...resolvedSlotProps,
            className: clsx(
              'absolute top-0 w-full h-full opacity-0 cursor-pointer',
              resolvedSlotProps?.className,
            ),
          };
        },
        track: ownerState => {
          const resolvedSlotProps = resolveSlotProps(
            props.slotProps?.track,
            ownerState,
          );

          return {
            ...resolvedSlotProps,
            className: clsx(
              `absolute top-1 left-0 h-4 w-11 rounded-full
              ${ownerState.checked ? 'bg-mydra-medium-purple' : 'bg-mydra-medium-gray'} `,
              resolvedSlotProps?.className,
            ),
          };
        },
        thumb: ownerState => {
          const resolvedSlotProps = resolveSlotProps(
            props.slotProps?.thumb,
            ownerState,
          );
          return {
            ...resolvedSlotProps,
            className: clsx(
              `block w-6 h-6 rounded-full border shadow-sm drop-shadow-toggle1 drop-shadow-toggle2 drop-shadow-toggle3 transform transition-transform ${
                ownerState.checked
                  ? 'bg-mydra-purple border-transparent'
                  : 'bg-white'
              }`,
              {
                'translate-x-0': !ownerState.checked,
                'translate-x-6': ownerState.checked,
              },
              resolvedSlotProps?.className,
            ),
          };
        },
      }}
    />
  );
});
