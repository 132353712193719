type TagProps = {
  text: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export function Tag({ text, ...props }: TagProps) {
  return (
    <div
      {...props}
      className={`inline-block leading-6 text-mydra-black text-xs rounded-sm px-2 bg-gray-300 ${props.className ?? ''}`}
    >
      {text}
    </div>
  );
}
