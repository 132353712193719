import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { CompanyLearningBudgetDialog } from '@/modules/employees/components/CompanyLearningBudgetDialog';
import { InviteEmployeesDialog } from '@/modules/employees/components/InviteEmployeesDialog';
import { useGetTeams } from '@/modules/employees/hooks/useTeams';
import { Input } from '@mui/base';
import { Tab as TabBase } from '@mui/base/Tab';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { Search } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import ListIcon from '@mui/icons-material/List';
import { keepPreviousData } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { PaginationState } from '@tanstack/react-table';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { InviteUserForm } from '../components/InviteUserForm/InviteUserForm';
import { ListEmployeesTable } from '../components/ListEmployeesTable/ListEmployeesTable';
import { ListInvitations } from '../components/ListInvitations';
import { TeamsTab } from '../components/TeamsTab';
import { useGetEmployees } from '../hooks/useEmployees';
import { useGetInvitations } from '../hooks/useInvitations';
import { useInvitationsMutation } from '../hooks/useInvitationsMutation';
import { EmployeesSearchParams } from '../types';

const routeApi = getRouteApi('/_admin/employees/');

const Tab = ({
  value,
  children,
}: {
  value: string;
  children: React.ReactNode;
}) => {
  return (
    <TabBase
      slotProps={{
        root: ({ selected }) => ({
          className: `inline-flex items-center gap-2 py-5 text-base font-medium ${
            selected ? 'border-b-4 border-black' : ''
          }`,
        }),
      }}
      value={value}
    >
      {children}
    </TabBase>
  );
};

export function ListEmployeesPage() {
  const userContext = useCurrentUserContext();
  const search = routeApi.useSearch() as EmployeesSearchParams;
  const navigate = routeApi.useNavigate();
  const [selectedTab, setSelectedTab] = useState(search.tab || 'employees');
  const { showNotification } = useNotificationContext();

  const [isLearningBudgetDialogOpen, setIsLearningBudgetDialogOpen] =
    useState(false);

  const { data: { data: teams = [] } = {} } = useGetTeams(
    userContext!.currentUser.company! as string,
  );

  const [isInviteEmployeesDialogOpen, setIsInviteEmployeesDialogOpen] =
    useState(false);

  const {
    isLoading,
    data: { data: employees = [], metadata: { total = 0, page = 1 } = {} } = {},
  } = useGetEmployees(
    userContext!.currentUser.company! as string,
    { email: search.email },
    {
      page: search.page,
      limit: 10,
    },
    {
      placeholderData: keepPreviousData,
      enabled: !search.email || search.email.length > 3,
    },
  );

  const {
    isLoading: isLoadingInvitations,
    data: { data: invitations = [] } = {},
  } = useGetInvitations();

  const paginationState = {
    pageIndex: (page ?? 1) - 1 || 0,
    pageSize: 10,
  };

  const { mutateAsync: sendInvitation } = useInvitationsMutation({
    onSuccess: () => {
      showNotification(
        'Invitation sent successfully',
        NotificationStyle.SUCCESS,
      );
    },
    onError: () => {
      showNotification('Failed to send invitation', NotificationStyle.ERROR);
    },
  });

  const debouncedSearchTerm = useMemo(() => {
    return debounce(term => {
      navigate({
        search: (prev: Record<string, unknown>) => ({
          ...prev,
          email: term,
        }),
      });
    }, 300);
  }, [navigate]);

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  const onPaginationChange = ({ pageIndex }: PaginationState) => {
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        page: pageIndex + 1,
      }),
    });
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearchTerm(value);
  };

  return (
    <div className="flex flex-col h-full">
      <header className="flex justify-between items-center px-12 py-5 border-b">
        <h1 className="text-2xl font-medium">Employees</h1>
        <div className="flex justify-end space-x-2">
          <Button small onClick={() => setIsLearningBudgetDialogOpen(true)}>
            Set Learning Budget
          </Button>
          <Button
            small
            primary
            onClick={() => setIsInviteEmployeesDialogOpen(true)}
          >
            Invite Employees
          </Button>
        </div>
      </header>
      <Tabs
        value={selectedTab}
        onChange={(_event, newValue) => {
          setSelectedTab(newValue as string);
          navigate({
            search: () => ({
              tab: newValue as string,
            }),
          });
        }}
      >
        <TabsList className="flex gap-5 px-12 border-b">
          <Tab value={'employees'}>
            <ListIcon />
            Employees
          </Tab>
          <Tab value={'teams'}>
            <GroupsIcon />
            Teams
          </Tab>
          <Tab value={'invitations'}>
            <EmailIcon />
            Invitations
          </Tab>
        </TabsList>
        <TabPanel value="employees" className="flex-1 px-12 pt-8">
          <div className="flex gap-12 px-12 py-6">
            <div className="flex items-center">
              <Input
                id="employeeName"
                slotProps={{
                  input: {
                    className: 'focus:outline-none  bg-gray-background',
                  },
                }}
                className="px-3 py-2 w-full rounded-lg border text-text bg-gray-background"
                startAdornment={<Search />}
                placeholder="Search for employees"
                onChange={onInputChange}
              />
            </div>
          </div>
          <div className="flex-1 p-12">
            <ListEmployeesTable
              isLoading={isLoading}
              onPaginationChange={updater => {
                return typeof updater === 'function'
                  ? onPaginationChange(updater(paginationState))
                  : onPaginationChange(updater);
              }}
              employees={employees}
              paginationState={paginationState}
              rowCount={total}
            />
          </div>
        </TabPanel>
        <TabPanel value="invitations" className="flex-1 px-12 pt-8">
          <h2 className="font-semibold">Invite Employee</h2>
          <div className="flex gap-12">
            <div className="w-full flex items-center pt-6">
              <InviteUserForm teams={teams} onSubmit={sendInvitation} />
            </div>
          </div>
          <hr className="mt-8" />
          <h2 className="mt-6 font-semibold">Pending Invitations</h2>
          <div className="flex-1 mt-6 mb-12">
            <ListInvitations
              isLoading={isLoadingInvitations}
              invitations={invitations}
            />
          </div>
        </TabPanel>
        <TabPanel value="teams" className="flex-1 px-12 pt-8">
          <TeamsTab />
        </TabPanel>
      </Tabs>
      <CompanyLearningBudgetDialog
        company={userContext!.employer!}
        open={isLearningBudgetDialogOpen}
        onClose={() => setIsLearningBudgetDialogOpen(false)}
        onSuccess={() => {
          setIsLearningBudgetDialogOpen(false);
        }}
      />
      <InviteEmployeesDialog
        company={userContext!.employer!._id!}
        open={isInviteEmployeesDialogOpen}
        onClose={() => setIsInviteEmployeesDialogOpen(false)}
        onSuccess={() => {
          setIsLearningBudgetDialogOpen(false);
        }}
      />
    </div>
  );
}
