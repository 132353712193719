import { ApiStripeLinks } from '@/types/education-provider';
import { Await, DeferredPromise, getRouteApi } from '@tanstack/react-router';
import { Suspense } from 'react';
import { StripeLoadingScreen } from '../components/StripeLoadingScreen';
import { FlowModes, StripeOnboardingSearchParams } from '../types';

const routeApi = getRouteApi('/stripe');

export const StripeAccountPage = () => {
  const { mode } = routeApi.useSearch() as StripeOnboardingSearchParams;
  const { links: stripeAccountLinks } = routeApi.useLoaderData() as {
    links: DeferredPromise<ApiStripeLinks>;
  };

  return (
    <Suspense fallback={<StripeLoadingScreen />}>
      <Await promise={stripeAccountLinks}>
        {({ data: links }) => {
          if (mode === FlowModes.LOGIN && links?.loginLink) {
            window.location.href = links.loginLink;
            return null;
          }

          if (mode === FlowModes.CONNECT_ACCOUNT && links?.connectAccountLink) {
            window.location.href = links.connectAccountLink;
            return null;
          }

          throw new Error('Invalid mode');
        }}
      </Await>
    </Suspense>
  );
};
