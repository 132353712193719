import { DurationType } from '@/types/course';
import * as yup from 'yup';

export const CohortsSchema = yup.object().shape({
  cohorts: yup
    .array()
    .min(1, 'Please provide at least one cohort')
    .of(
      yup.object().shape({
        location: yup
          .string()
          .required('Please provide a location for the cohort'),
        format: yup.string().optional().nullable(),
        startDate: yup.date().optional().nullable(),
        endDate: yup.date().when('startDate', ([startDate], schema) => {
          return startDate
            ? schema.required('You must provide an end date')
            : schema.optional().nullable();
        }),
        duration: yup
          .object()
          .nullable()
          .shape({
            quantity: yup
              .number()
              .required('You must provide a quantity')
              .typeError('You must provide a quantity'),
            type: yup
              .string()
              .typeError('You must select a duration type')
              .required()
              .oneOf(Object.values(DurationType)),
          }),
        classType: yup
          .string()
          .oneOf(['live', 'pre-recorded'], 'Please select a valid option')
          .optional()
          .nullable(),
        cost: yup
          .object()
          .optional()
          .nullable()
          .shape({
            amount: yup.number().min(0).required(),
            currency: yup
              .string()
              .required()
              .oneOf(['EUR'], 'Not supported currency'),
          }),
      }),
    ),
});

export const AdditionalResourcesSchema = yup.object().shape({
  imageUrl: yup.string().url('Invalid URL'),
  brochureUrl: yup.string().url('Invalid URL'),
});

export const CourseCategoriesSchema = yup.object().shape({
  categoryIds: yup
    .array()
    .min(1, 'Please set a main category')
    .of(yup.string().required())
    .required('Please set a main category'),
  subCategories: yup
    .array()
    .of(yup.string())
    .required('Subcategory is required'),
});

export const CourseDescriptionSchema = yup.object().shape({
  summary: yup.string().required("Summary can't be empty"),
  learningDescription: yup
    .string()
    .required("Learning description can't be empty"),
});

export const CourseEngagementSchema = yup.object().shape({
  targets: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required("Please provide a target's title"),
        description: yup
          .string()
          .optional()
          .required("Please provide a target's description"),
      }),
    )
    .optional(),
  learningAreas: yup.array().of(yup.string()).optional(),
  prerequisites: yup.array().of(yup.string()).required(),
  skills: yup.array().of(yup.string()).required(),
});

export const CourseHeaderSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
});

export const CourseInformationSchema = yup.object().shape({
  language: yup.string().required('Language is required'),
  level: yup.string().required('Level is required'),
  type: yup.string().required('Type is required'),
  hasCertificate: yup.boolean().required('Certificate is required'),
});

export const CourseInstructorsSchema = yup.object().shape({
  instructors: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Instructor's name is required"),
        linkedIn: yup
          .string()
          .url('Please provide a valid URL')
          .optional()
          .nullable(),
        website: yup
          .string()
          .url('Please provide a valid URL')
          .optional()
          .nullable(),
        avatarUrl: yup
          .string()
          .url('Please provide a valid URL')
          .optional()
          .nullable(),
        role: yup.string().optional().nullable(),
        company: yup.string().optional().nullable(),
        bio: yup.string().optional().nullable(),
      }),
    )
    .optional(),
});

export const CourseMotivationSchema = yup.object().shape({
  courseMotivation: yup.object().shape({
    videoUrl: yup.string().optional().nullable(),
    motivation: yup.string().optional().nullable(),
    advisor: yup.object().shape({
      name: yup.string().optional().nullable(),
      role: yup.string().optional().nullable(),
      avatarUrl: yup.string().optional().nullable(),
    }),
  }),
});

export const CourseProgrammeStructureSchema = yup.object().shape({
  programmeStructure: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().optional().nullable(),
        description: yup.string().optional().nullable(),
      }),
    )
    .optional(),
});

export const CourseTestimonialsSchema = yup.object().shape({
  testimonials: yup
    .array()
    .of(
      yup.object().shape({
        comment: yup.string().required(),
        author: yup.string().required(),
        authorRole: yup.string().optional().nullable(),
        avatarUrl: yup.string().optional().nullable(),
      }),
    )
    .optional(),
});

export const CourseWebAddressSchema = yup.object().shape({
  url: yup
    .string()
    .url('Please provide a valid url')
    .required('Please provide a url for the course'),
});

export const UpsertCourseFormSchema = yup
  .object()
  .concat(CohortsSchema)
  .concat(AdditionalResourcesSchema)
  .concat(CourseCategoriesSchema)
  .concat(CourseDescriptionSchema)
  .concat(CourseEngagementSchema)
  .concat(CourseHeaderSchema)
  .concat(CourseInformationSchema)
  .concat(CourseInstructorsSchema)
  .concat(CourseMotivationSchema)
  .concat(CourseProgrammeStructureSchema)
  .concat(CourseTestimonialsSchema)
  .concat(CourseWebAddressSchema);
