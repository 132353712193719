import {
  Dropdown,
  DropdownProps,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuProps,
} from '@mui/base';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';

export type DropDownMenuProps = DropdownProps &
  React.HTMLAttributes<HTMLDivElement> & {
    toggleButtonLabel: string;
    toggleButtonIcon?: React.ReactElement;
    slotProps?: {
      button?: MenuButtonProps;
      menu?: MenuProps;
    };
  };

export function DropDown({
  children,
  toggleButtonLabel,
  toggleButtonIcon,
  slotProps,
  ...props
}: DropDownMenuProps) {
  return (
    <Dropdown {...props}>
      <MenuButton
        {...slotProps?.button}
        className={`flex items-center text-text ${slotProps?.button?.className ?? ''}`}
      >
        {toggleButtonIcon}
        <span className="ml-2 font-medium">{toggleButtonLabel}</span>
        <ExpandMore />
      </MenuButton>
      <Menu
        {...slotProps?.menu}
        className={`z-40 overflow-hidden bg-white border rounded-lg shadow-user-menu min-w-56 ${slotProps?.menu?.className ?? ''}`}
      >
        {children}
      </Menu>
    </Dropdown>
  );
}
