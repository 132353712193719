import { ApiIndividualFileUploadResponse } from '@/types/file-upload';
import { ApiService } from './api';

export class FileUploadService extends ApiService {
  static PATH = '/files/upload';

  static async postFile(
    file: FormData,
  ): Promise<ApiIndividualFileUploadResponse> {
    return ApiService.post(FileUploadService.PATH, file);
  }
}
