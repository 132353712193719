import { Button } from '@/modules/common/components/Button';
import { MenuItem } from '@/modules/common/components/MenuItem';
import { TableHeader } from '@/modules/common/components/TableHeader';
import { ILandingPage } from '@/types/landing-page';
import {
  DeleteOutline,
  RefreshOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { languageNameMap } from 'language-name-map';

const columnHelper = createColumnHelper<ILandingPage>();

type GetListLandingPagesTableColumnsArgs = {
  onPublishToggle: (
    landingPageId: string,
    isPublished: boolean,
  ) => Promise<void>;
  onPreview: (landingPageId: string) => Promise<void>;
  onDelete: (landingPageId: string) => Promise<void>;
  onRegenerate: (landingPageId: string) => Promise<void>;
};

export function getListLandingPagesTableColumns({
  onPublishToggle,
  onDelete,
  onPreview,
  onRegenerate,
}: GetListLandingPagesTableColumnsArgs) {
  return [
    columnHelper.accessor('title', {
      header: () => <TableHeader>Title</TableHeader>,
      cell: ({ row: { original } }) => {
        return <div className="flex gap-3 items-center">{original.title}</div>;
      },
    }),
    columnHelper.accessor('language', {
      header: () => <TableHeader>Language</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex gap-3 items-center text-sm">
            {languageNameMap[original.language].name}
          </div>
        );
      },
    }),
    columnHelper.accessor('isPublished', {
      header: () => <TableHeader>Published</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex gap-3 items-center text-sm">
            {original.isPublished ? 'Yes' : 'No'}
          </div>
        );
      },
    }),
    columnHelper.accessor('frequencyInHours', {
      header: () => <TableHeader>Update frequency (hours)</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex gap-3 items-center text-sm">
            {original.frequencyInHours}
          </div>
        );
      },
    }),
    columnHelper.accessor('updatedAt', {
      header: () => <TableHeader>Last updated</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex gap-3 items-center text-sm">
            {format(new Date(original.updatedAt), 'MMM d, yyyy h:mm a')}
          </div>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <TableHeader>Actions</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex gap-3 justify-between items-center w-full">
            <Button
              small
              onClick={() => onPreview(original._id!)}
              dropdownItems={
                <>
                  <MenuItem
                    className="px-3 py-2 border-b last-of-type:border-none"
                    onClick={() => onRegenerate(original._id!)}
                    icon={<RefreshOutlined />}
                  >
                    Regenerate
                  </MenuItem>
                  <MenuItem
                    className="px-3 py-2 border-b last-of-type:border-none"
                    onClick={() =>
                      onPublishToggle(original._id!, original.isPublished)
                    }
                    icon={
                      original.isPublished ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )
                    }
                  >
                    {original.isPublished ? 'Unpublish' : 'Publish'}{' '}
                  </MenuItem>
                  <MenuItem
                    className="px-3 py-2 border-b last-of-type:border-none"
                    onClick={() => onDelete(original._id!)}
                    icon={<DeleteOutline />}
                  >
                    Delete
                  </MenuItem>
                </>
              }
            >
              Preview
            </Button>
          </div>
        );
      },
    }),
  ];
}
