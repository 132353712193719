import { EducationProviderService } from '@/services/education-provider';
import { ApiErrorResponse, ApiReactQueryOptions } from '@/types/api';
import { ApiStripeLinks } from '@/types/education-provider';
import { useQuery } from '@tanstack/react-query';

export const getGetConnectStripeAccountLinksQueryOptions = (
  id?: string,
  opts?: ApiReactQueryOptions<ApiStripeLinks>,
) => ({
  queryKey: [EducationProviderService.PATH_STRIPE_LINKS, id],
  queryFn: async () =>
    await EducationProviderService.getConnectStripeAccountLinks(id!),
  enabled: !!id,
  ...opts,
});

export const useGetConnectStripeAccountLinks = (
  id?: string,
  opts?: ApiReactQueryOptions<ApiStripeLinks>,
) =>
  useQuery<ApiStripeLinks, ApiErrorResponse>(
    getGetConnectStripeAccountLinksQueryOptions(id, opts),
  );
