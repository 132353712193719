import { LogoutPage } from '@/modules/auth/pages/LogoutPage';
import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/logout')({
  component: () => <LogoutPage />,
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 500 }} reset={() => null} />;
  },
});
