import { EmployerService } from '@/services/employer';
import { TaxBenefitsProviderService } from '@/services/tax-benefit-providers';
import {
  ApiErrorResponse,
  ApiIndividualResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactMutationOptions,
  ApiReactQueryOptions,
  ISortingParams,
} from '@/types/api';
import {
  ApiPaginatedTaxBenefitProviderLinks,
  ITaxBenefitsProviderCompanyLink,
  ITaxBenefitsProviderCompanyLinkCreatePayload,
  ITaxBenefitsProviderCompanyLinkUpdatePayload,
  ITaxBenefitsProviderPartialUpdatePayload,
} from '@/types/tax-benefits-provider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const getTaxBenefitProviderCompanyLinksQueryOptions = (
  filters?: ApiQueryParams,
  pagination?: ApiPaginationParams,
  sorting?: ISortingParams,
  options?: ApiReactQueryOptions<ApiPaginatedTaxBenefitProviderLinks>,
) => ({
  queryKey: [TaxBenefitsProviderService.PATH, filters, pagination, sorting],
  queryFn: async () =>
    await TaxBenefitsProviderService.getTaxBenefitsCompanyLinks(
      filters,
      pagination,
      sorting,
    ),
  ...options,
});

export const useGetTaxBenefitProviderCompanyLinks = (
  filters: ApiQueryParams = {},
  pagination: ApiPaginationParams = {},
  sorting: ISortingParams = {},
  options?: ApiReactQueryOptions<ApiPaginatedTaxBenefitProviderLinks>,
) => {
  return useQuery<ApiPaginatedTaxBenefitProviderLinks, ApiErrorResponse>(
    getTaxBenefitProviderCompanyLinksQueryOptions(
      filters,
      pagination,
      sorting,
      options,
    ),
  );
};

export function useTaxBenefitsProviderLinkMutation(
  taxBenefitsProviderLinkId?: string,
  options?: Omit<
    ApiReactMutationOptions<
      ApiIndividualResponse<ITaxBenefitsProviderCompanyLink>,
      {
        id: string;
        data:
          | ITaxBenefitsProviderCompanyLinkUpdatePayload
          | ITaxBenefitsProviderCompanyLinkCreatePayload;
      }
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  const mutationKey = [
    TaxBenefitsProviderService.LINKS_PATH,
    taxBenefitsProviderLinkId,
  ];
  return useMutation<
    ApiIndividualResponse<ITaxBenefitsProviderCompanyLink>,
    ApiErrorResponse,
    {
      id: string;
      data:
        | ITaxBenefitsProviderCompanyLinkUpdatePayload
        | ITaxBenefitsProviderCompanyLinkCreatePayload;
    }
  >({
    ...options,
    mutationKey: mutationKey,
    mutationFn: async ({ id = taxBenefitsProviderLinkId, data }) => {
      if (id) {
        return TaxBenefitsProviderService.patchTaxBenefitsCompanyLink(
          id!,
          data as ITaxBenefitsProviderPartialUpdatePayload,
        );
      } else {
        return TaxBenefitsProviderService.postTaxBenefitsCompanyLink(
          data as ITaxBenefitsProviderCompanyLinkCreatePayload,
        );
      }
    },
    onSettled: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: [TaxBenefitsProviderService.LINKS_PATH, EmployerService.PATH],
        exact: false,
      });
      options?.onSettled?.(...args);
    },
  });
}
