import { ImageWithFallback } from '@/modules/common/components/ImageWithFallback';
import { Modal, ModalProps } from '@/modules/common/components/Modal';
import { ITaxBenefitsProvider } from '@/types/tax-benefits-provider';
import { AccountBalanceOutlined } from '@mui/icons-material';

type TaxBenefitsSelectorModalProps = Omit<
  ModalProps,
  'title' | 'children' | 'onClose'
> & {
  taxBenefitsProviders: ITaxBenefitsProvider[];
  onClose: (provider?: ITaxBenefitsProvider) => void;
};

export function TaxBenefitsSelectorModal({
  onClose,
  taxBenefitsProviders,
  ...props
}: TaxBenefitsSelectorModalProps) {
  return (
    <Modal
      {...props}
      onClose={() => onClose?.(undefined)}
      title="Select a Tax Benefits Provider"
      className={`${props.className} max-h-full max-w-2xl`}
      showCloseButton
    >
      <div className="flex overflow-hidden flex-col gap-4">
        <p className="sticky top-0">
          You can select a tax benefits provider to help you manage your tax
          benefits.
        </p>
        <div className="flex overflow-y-auto flex-col gap-4 max-h-64">
          {taxBenefitsProviders.map(provider => (
            <button
              key={provider._id}
              className="flex relative flex-1 gap-2 items-center p-4 rounded-lg border border-gray-300"
              onClick={() => onClose?.(provider)}
            >
              {provider.logoUrl ? (
                <ImageWithFallback
                  src={provider.logoUrl}
                  alt={provider.name}
                  className="object-contain w-12 h-12 aspect-square"
                  fallback={
                    <AccountBalanceOutlined className="!w-16 !h-16 text-mydra-medium-gray" />
                  }
                />
              ) : (
                <AccountBalanceOutlined className="!w-16 !h-16 text-mydra-medium-gray" />
              )}
              <p className="w-full font-bold text-left">{provider.name}</p>
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}
