import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { ImageWithFallback } from '@/modules/common/components/ImageWithFallback';
import {
  ITaxBenefitsProvider,
  ITaxBenefitsProviderWithLinkStatus,
  TaxBenefitsProviderCompanyLinkStatus,
} from '@/types/tax-benefits-provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountBalanceOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCompanyMutation } from '../hooks/useCompanyMutation';
import { useGetTaxBenefitProviders } from '../hooks/useTaxBenefitProviders';
import { CancelPartnershipConfirmationModal } from './CancelPartnershipConfirmationModal';
import { TaxBenefitsSelectorModal } from './TaxBenefitsSelectorModal';

type CompanyTaxBenefitsProviderProps = React.HTMLAttributes<HTMLDivElement>;

enum TaxBenefitsReviewMode {
  Provider = 'provider',
  Independent = 'independent',
}

type FormValues = {
  enableTaxBenefits: boolean;
  taxBenefitsReviewMode: TaxBenefitsReviewMode;
  taxBenefitsProvider?: ITaxBenefitsProviderWithLinkStatus | null;
};

const FORM_SCHEMA = yup.object().shape({
  enableTaxBenefits: yup.boolean().required(),
  taxBenefitsReviewMode: yup
    .string()
    .oneOf(Object.values(TaxBenefitsReviewMode), 'Please select a valid mode')
    .required('Please select a mode'),
  taxBenefitsProvider: yup
    .mixed()
    .when('taxBenefitsReviewMode', ([taxBenefitsReviewMode]) => {
      if (taxBenefitsReviewMode === TaxBenefitsReviewMode.Independent) {
        return yup.mixed().nullable().oneOf([null]);
      }
      return yup.object().required('Please select a provider');
    }),
});

export function CompanyTaxBenefitsProvider(
  props: CompanyTaxBenefitsProviderProps,
) {
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const {
    currentUser,
    employer: {
      taxBenefitsProvider: companyTaxBenefitsProvider = null,
      _id: employerId = '',
      settings: companySettings = {},
    } = {},
    isLoading: isLoadingUserContext,
  } = useCurrentUserContext() ?? {};

  const { skipTaxBenefitsProviderValidation = null } = companySettings;

  const {
    isLoading: isLoadingTaxBenefitsProviders,
    data: { data: taxBenefitsProviders = [] } = {},
  } = useGetTaxBenefitProviders({}, { page: 1, limit: 5000 });

  const isLoading = isLoadingTaxBenefitsProviders || isLoadingUserContext;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    watch,
  } = useForm<FormValues>({
    disabled: isLoading,
    resolver: yupResolver(FORM_SCHEMA) as Resolver<FormValues>,
    values: {
      enableTaxBenefits:
        !!companyTaxBenefitsProvider || !!skipTaxBenefitsProviderValidation,
      taxBenefitsProvider: companyTaxBenefitsProvider,
      taxBenefitsReviewMode: skipTaxBenefitsProviderValidation
        ? TaxBenefitsReviewMode.Independent
        : TaxBenefitsReviewMode.Provider,
    },
  });
  const enableTaxBenefits = watch('enableTaxBenefits');
  const taxBenefitsReviewMode = watch('taxBenefitsReviewMode');
  const taxBenefitsProvider = watch('taxBenefitsProvider');

  const { mutateAsync: updateEmployer } = useCompanyMutation(employerId);

  const onCancelPartnership = async () => {
    setValue('taxBenefitsProvider', null, { shouldDirty: true });
    setValue('taxBenefitsReviewMode', TaxBenefitsReviewMode.Independent, {
      shouldDirty: true,
    });
  };

  const onProviderSelect = async (provider?: ITaxBenefitsProvider) => {
    if (!provider || !currentUser) {
      setValue('taxBenefitsProvider', null, { shouldDirty: true });
      return;
    }

    return setValue(
      'taxBenefitsProvider',
      provider as ITaxBenefitsProviderWithLinkStatus,
      { shouldDirty: true },
    );
  };

  const onSubmit = async (data: FormValues) => {
    await updateEmployer({
      id: employerId,
      updatedData: {
        taxBenefitsProvider: data.enableTaxBenefits
          ? data.taxBenefitsProvider?._id ?? null
          : null,
        settings: {
          ...companySettings,
          skipTaxBenefitsProviderValidation:
            data.taxBenefitsReviewMode === TaxBenefitsReviewMode.Independent,
        },
      },
    });
  };

  useEffect(() => {
    if (taxBenefitsReviewMode === TaxBenefitsReviewMode.Independent) {
      setValue('taxBenefitsProvider', null, { shouldDirty: true });
    }
  }, [taxBenefitsReviewMode, setValue]);

  return (
    <div {...props} className={`${props.className}`}>
      <div>
        <h2 className="text-lg font-medium leading-8 text-mydra-black">
          Tax Benefits
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="enableTaxBenefits"
            render={({ field }) => (
              <label className="flex gap-2">
                <input
                  disabled={field.disabled}
                  type="checkbox"
                  checked={!!field.value}
                  onChange={e => field.onChange(e.target.checked)}
                />
                Enable the option to pay using tax benefits.
              </label>
            )}
          />

          <div className="flex flex-col pl-4 mt-4">
            <label
              className={`flex gap-2 ${!enableTaxBenefits ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <input
                {...register('taxBenefitsReviewMode')}
                className="disabled:cursor-not-allowed"
                disabled={
                  !enableTaxBenefits || taxBenefitsProviders?.length === 0
                }
                type="radio"
                value={TaxBenefitsReviewMode.Provider}
              />
              Select a provider to authorize the tax benefits.
            </label>
            <label
              className={`flex gap-2 ${!enableTaxBenefits ? 'opacity-50' : ''}`}
            >
              <input
                {...register('taxBenefitsReviewMode')}
                className="disabled:cursor-not-allowed"
                disabled={!enableTaxBenefits}
                type="radio"
                value={TaxBenefitsReviewMode.Independent}
              />
              Approve tax benefits independently.
            </label>
          </div>
          {isDirty && (
            <div className="flex gap-3 mt-8">
              <Button primary small type="submit">
                Save
              </Button>
              <Button
                small
                onClick={e => {
                  e.preventDefault();
                  reset();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </form>

        {taxBenefitsReviewMode === TaxBenefitsReviewMode.Provider && (
          <div
            className={`mt-8 ${!enableTaxBenefits ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <h3 className="text-lg font-medium leading-8 text-mydra-black">
              Tax benefits provider
            </h3>
            <p className="mt-2 text-sm text-mydra-black">
              Select your tax benefits provider - this provider will handle your
              employees tax benefits applications on your behalf.
            </p>

            {!taxBenefitsProvider ? (
              <>
                <Button
                  disabled={
                    isLoading ||
                    !taxBenefitsProviders?.length ||
                    !enableTaxBenefits
                  }
                  isLoading={isLoading}
                  className={`p-6 mt-6 w-40 h-40 rounded-lg border bg-gray-background`}
                  onClick={() => setIsSelectionModalOpen(true)}
                >
                  <div className={`flex flex-col gap-2 items-center`}>
                    <AccountBalanceOutlined />
                    <p className="text-sm">Click to select a provider</p>
                  </div>
                </Button>
                {errors.taxBenefitsProvider && (
                  <p className="text-sm text-red-500">
                    {errors.taxBenefitsProvider.message}
                  </p>
                )}
              </>
            ) : (
              <div className="flex px-4 py-6 my-6 rounded-xl border bg-gray-background">
                <div className="flex flex-1 gap-8">
                  <div className="flex flex-shrink-0 gap-2 items-center">
                    {taxBenefitsProvider.logoUrl ? (
                      <ImageWithFallback
                        src={taxBenefitsProvider.logoUrl}
                        alt={taxBenefitsProvider.name}
                        className="w-16 h-16 bg-white bg-cover rounded-lg aspect-square"
                        fallback={
                          <AccountBalanceOutlined className="!w-16 !h-16 text-mydra-medium-gray" />
                        }
                      />
                    ) : (
                      <AccountBalanceOutlined className="!w-16 !h-16 text-mydra-medium-gray" />
                    )}
                    <p className="text-sm font-bold">
                      {taxBenefitsProvider.name}
                    </p>
                  </div>
                  <dl className="flex flex-wrap gap-8 items-center text-left">
                    {taxBenefitsProvider.legalName && (
                      <div className="flex flex-col text-sm">
                        <dt className="text-sm font-bold">Legal name</dt>
                        <dd className="text-sm">
                          {taxBenefitsProvider.legalName}
                        </dd>
                      </div>
                    )}
                    {taxBenefitsProvider.address && (
                      <div className="flex flex-col text-sm">
                        <dt className="text-sm font-bold">Address</dt>
                        <dd className="text-sm">
                          {taxBenefitsProvider.address}
                        </dd>
                      </div>
                    )}
                    <div className="flex flex-col text-sm">
                      <dt className="text-sm font-bold">Status</dt>
                      <dd className="text-sm capitalize">
                        {taxBenefitsProvider.status?.toLowerCase() ??
                          '(unknown)'}
                      </dd>
                    </div>
                  </dl>
                  <div className="flex flex-col flex-1 items-end">
                    <Button
                      primary
                      isLoading={isLoading}
                      disabled={!taxBenefitsProvider.status}
                      small
                      slotProps={{
                        wrapperProps: {
                          className: 'my-auto',
                        },
                      }}
                      onClick={() => setIsCancelModalOpen(true)}
                    >
                      Cancel{' '}
                      {taxBenefitsProvider.status ===
                        TaxBenefitsProviderCompanyLinkStatus.Invited ||
                      !taxBenefitsProvider.status
                        ? 'invitation'
                        : 'partnership'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <TaxBenefitsSelectorModal
        taxBenefitsProviders={taxBenefitsProviders}
        open={isSelectionModalOpen}
        onClose={(provider?: ITaxBenefitsProvider) => {
          onProviderSelect(provider);
          setIsSelectionModalOpen(false);
        }}
      />
      {taxBenefitsProvider && (
        <CancelPartnershipConfirmationModal
          open={isCancelModalOpen}
          status={taxBenefitsProvider.status}
          onClose={async (cancel: boolean) => {
            if (cancel) {
              await onCancelPartnership?.();
            }
            setIsCancelModalOpen(false);
          }}
        />
      )}
    </div>
  );
}
