import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ModalProps } from '@mui/base';
import { MouseEvent } from 'react';
import { useRemoveTeamMember } from '../../hooks/useTeams';

type RemoveTeamMemberDialog = Omit<ModalProps, 'children'> & {
  onSuccess: () => void;
  team: string;
  user: string;
};

export function RemoveTeamMemberDialog({
  onSuccess,
  ...props
}: RemoveTeamMemberDialog) {
  const { showNotification } = useNotificationContext();

  const { team: teamId, user: userId } = props;

  const { mutateAsync: removeMember, isPending } = useRemoveTeamMember(
    teamId,
    userId,
  );

  const handleRemoveMember = async () => {
    try {
      await removeMember({ teamId, userId });
      onSuccess();
      showNotification(`Member removed successfuly`, NotificationStyle.SUCCESS);
    } catch {
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleCancel = (ev: MouseEvent) => {
    props.onClose?.(ev, 'backdropClick');
  };

  return (
    <Modal
      {...props}
      title={`Remove member from team`}
      subTitle="Are you sure you want to remove this user from the team?"
      onClose={(...args) => {
        props.onClose?.(...args);
      }}
      className="max-w-xl mx-auto p-8 pb-3"
    >
      <div>
        <p>
          By clicking 'Remove' you will permanently remove this user from the
          team. Are you sure you want to proceed?
        </p>
        <hr className="absolute left-0 right-0 mt-16" />
        <div className="mt-14 flex justify-end space-x-4">
          <Button
            small
            type="submit"
            className="w-32 mt-6"
            isLoading={isPending}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            alert
            small
            type="submit"
            className="w-32 mt-6"
            isLoading={isPending}
            onClick={handleRemoveMember}
          >
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
}
