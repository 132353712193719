import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { useGetEmployers } from '@/modules/common/hooks/useEmployer';
import { ListOfCompaniesPageSearchParams } from '@/types/company';
import { List } from '@mui/icons-material';
import { getRouteApi } from '@tanstack/react-router';
import { PaginationState } from '@tanstack/react-table';
import { ListOfCompaniesTable } from '../components/ListEmployeesTable';

const routeApi = getRouteApi('/_admin/companies/');

export function ListOfCompaniesPage() {
  const userContext = useCurrentUserContext();
  const search = routeApi.useSearch() as ListOfCompaniesPageSearchParams;
  const navigate = routeApi.useNavigate();

  const {
    isLoading,
    isRefetching,
    data: { data: companies = [], metadata: { total = 0, page = 1 } = {} } = {},
    refetch: refetchCompanies,
  } = useGetEmployers(
    {
      taxBenefitsProvider: userContext?.taxBenefitsProvider?._id ?? '',
    },
    {
      page: search.page,
      limit: 10,
    },
    { enabled: !!userContext?.taxBenefitsProvider },
  );

  const paginationState = {
    pageIndex: (page ?? 1) - 1 || 0,
    pageSize: 10,
  };

  const onPaginationChange = ({ pageIndex }: PaginationState) => {
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        page: pageIndex + 1,
      }),
    });
  };

  return (
    <div className="flex flex-col h-full">
      <header className="flex justify-between items-center px-12 py-5 border-b">
        <h1 className="text-2xl font-medium">Managed Companies</h1>
      </header>
      <div className="px-12 border-b">
        <div className="inline-flex gap-2 items-center py-5 text-base font-medium border-b-4 border-black">
          <List />
          List
        </div>
      </div>
      <div className="flex gap-12 px-12 py-6">
        <div className="flex items-center"></div>
      </div>
      <div className="flex-1 p-12">
        <ListOfCompaniesTable
          isLoading={isLoading || isRefetching}
          onPaginationChange={updater => {
            return typeof updater === 'function'
              ? onPaginationChange(updater(paginationState))
              : onPaginationChange(updater);
          }}
          onCompanyLinkUpdate={refetchCompanies}
          companies={companies}
          paginationState={paginationState}
          rowCount={total}
        />
      </div>
    </div>
  );
}
