import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ListLandingPagesPage } from '@/modules/landing-pages/pages/ListLandingPagesPage';
import { ListLandingPagesPageSearchParams } from '@/types/landing-page';
import { createFileRoute } from '@tanstack/react-router';
import * as yup from 'yup';

export const Route = createFileRoute('/_admin/landing-pages/')({
  component: () => (
    <AdminLayout>
      <ListLandingPagesPage />
    </AdminLayout>
  ),
  validateSearch: (
    search: Record<string, unknown>,
  ): ListLandingPagesPageSearchParams => {
    return {
      page: yup.number().integer().min(1).optional().validateSync(search.page),
      limit: yup
        .number()
        .integer()
        .min(1)
        .optional()
        .validateSync(search.limit),
    };
  },
});
