import { EXTERNAL_ROUTES } from '@/constants';
import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal/Modal';
import { ICourse } from '@/types/course';
import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useCourseDeletion, useCourseMutation } from '../../hooks/useCourses';
import { TableFooter } from './ListCoursesTable.components';
import { getListCoursesTableColumns } from './ListCoursesTable.constants';

import {
  Add,
  CancelPresentation,
  Close,
  DeleteOutline,
} from '@mui/icons-material';
import { useNavigate } from '@tanstack/react-router';
import pluralize from 'pluralize';

type ListCoursesTable = {
  courses: ICourse[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
} & React.TableHTMLAttributes<HTMLTableElement>;

export function ListCoursesTable({
  courses,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  ...props
}: ListCoursesTable) {
  const navigate = useNavigate();
  const { mutateAsync: updateCourse } = useCourseMutation(undefined);
  const { mutateAsync: deleteCourse } = useCourseDeletion(undefined);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [coursesIdsToDelete, setCoursesIdsToDelete] = useState<string[]>([]);

  const columns = getListCoursesTableColumns({
    onCourseEdit: (id: string) =>
      navigate({
        to: `/courses/${id}/edit`,
      }),
    onCourseDelete: (id: string) => {
      setCoursesIdsToDelete([id]);
      setDeleteModalOpen(true);
    },
    onCourseView: (id: string) => {
      window.open(
        EXTERNAL_ROUTES.MARKETPLACE_COURSE_URL.replace(':id', id),
        '_blank',
        'noopener noreferrer',
      );
    },
    onCourseUpdate: updateCourse,
  });

  const table = useReactTable<ICourse>({
    data: courses,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel<ICourse>(),
  });

  const rows = table.getRowModel().rows;

  const deleteSelectedCourses = async () => {
    if (!coursesIdsToDelete.length) {
      return;
    }

    setDeleteModalOpen(false);
    // TODO: avoid multiple gets after delete
    await Promise.all(coursesIdsToDelete.map(id => deleteCourse(id)));
    table.resetRowSelection();
  };

  const handleDeleteMultiple = async () => {
    const selectedRowModel = table.getSelectedRowModel();

    if (selectedRowModel.rows.length === 0) {
      return;
    }

    setCoursesIdsToDelete(selectedRowModel.rows.map(row => row.original._id!));
    setDeleteModalOpen(true);
  };

  const hasNoCourses = rows.length === 0 && !isLoading;

  return (
    <>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoCourses ? 'h-full' : ''}`}
      >
        <thead>
          {table.getIsSomeRowsSelected() && (
            <tr>
              <th
                colSpan={table.getVisibleFlatColumns().length}
                className="sticky top-0 p-0 align-top bg-white"
              >
                <div className="flex items-center w-full gap-3 p-4 text-xs font-medium rounded-md bg-gray-background">
                  <p>
                    {pluralize(
                      'item',
                      table.getSelectedRowModel().rows.length,
                      true,
                    )}{' '}
                    selected
                  </p>
                  <button
                    className="flex items-center p-1 ml-10 rounded-md text-mydra-purple hover:bg-mydra-medium-gray hover:text-black"
                    onClick={() => table.resetRowSelection()}
                  >
                    <Close className="w-6 h-6" />
                    Deselect
                  </button>
                  <button
                    className="flex items-center p-1 ml-auto rounded-md text-text hover:bg-mydra-medium-gray hover:text-black"
                    onClick={handleDeleteMultiple}
                  >
                    <DeleteOutline className="w-6 h-6" />
                    Delete
                  </button>
                </div>
              </th>
            </tr>
          )}
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="sticky top-0 p-3 align-top bg-white first:px-0 last:px-0"
                  >
                    {/* Handles all possible header column def scenarios for `header` */}
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoCourses ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="h-full p-0 text-center"
              >
                <div className="flex flex-col justify-center h-full p-3 align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className=" text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">{`You don’t have any course yet`}</p>
                    <p className="mt-2">
                      {/* TODO: use a different phrase for when the selection yields no results */}
                      After you add a course, they will be displayed here.
                    </p>
                    <Button
                      primary
                      className="mt-8"
                      onClick={() =>
                        navigate({
                          to: '/courses/add',
                        })
                      }
                    >
                      <div className="flex items-center">
                        <Add />
                        Add course
                      </div>
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
      <Modal
        open={isDeleteModalOpen}
        title="Are you sure you want to delete this course?"
      >
        <div className="flex justify-end gap-3">
          <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          <Button primary onClick={deleteSelectedCourses}>
            Yes, Please
          </Button>
        </div>
      </Modal>
    </>
  );
}
