import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { CreateTeamDialog } from '@/modules/employees/components/CreateTeamDialog';
import { ListTeams } from '@/modules/employees/components/ListTeams';
import { useGetTeams } from '@/modules/employees/hooks/useTeams';
import { ITeam } from '@/types/team';
import { Input } from '@mui/base';
import { Search } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

export function TeamsTab() {
  const [isCreateTeamDialogOpen, setCreateTeamDialogOpen] = useState(false);
  const [filteredTeams, setFilteredTeams] = useState<ITeam[]>([]);

  const userContext = useCurrentUserContext();

  const { isLoading: isLoadingTeams, data: { data: teams = [] } = {} } =
    useGetTeams(userContext!.currentUser.company! as string);

  const debouncedSearchTerm = debounce(term => {
    const filtered = teams.filter(t => {
      return (
        t.name.toLowerCase().indexOf(term.toLowerCase()) !== -1 //||
        // t.description?.toLowerCase().indexOf(term.toLowerCase()) !== -1
      );
    });

    setFilteredTeams(filtered);
  }, 300);

  useEffect(() => {
    setFilteredTeams(teams);
  }, [teams]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearchTerm(value);
  };

  return (
    <>
      <h2 className="font-semibold">Teams</h2>
      <p>Create and manage teams for more efficient team management</p>
      <div className="flex justify-end">
        <Button
          primary
          small
          type="submit"
          isLoading={isLoadingTeams}
          onClick={() => setCreateTeamDialogOpen(true)}
        >
          Create a Team
        </Button>
      </div>
      <div className="flex gap-12 py-6">
        <div className="flex items-center w-full">
          <Input
            id="teamName"
            slotProps={{
              input: {
                className: 'focus:outline-none  bg-gray-background',
              },
            }}
            className="px-6 py-2 w-full rounded-lg border text-text bg-gray-background"
            startAdornment={<Search />}
            placeholder="Search teams"
            onChange={onInputChange}
          />
        </div>
      </div>
      <div className="flex-1 mt-8">
        <CreateTeamDialog
          open={isCreateTeamDialogOpen}
          onClose={() => setCreateTeamDialogOpen(false)}
          onSuccess={() => {
            setCreateTeamDialogOpen(false);
          }}
        />
        <ListTeams
          isLoading={isLoadingTeams}
          teams={filteredTeams.length ? filteredTeams : teams}
        />
      </div>
    </>
  );
}
