import { ConfirmationModal } from '@/modules/common/components/ConfirmationModal';
import { useCallback, useMemo, useState } from 'react';

type ConfirmationAction = () => Promise<void>;

export const useLandingPageMutationConfirmation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ConfirmationAction>(async () => {});
  const [title, setTitle] = useState<string | null>(null);
  const [subTitle, setSubTitle] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const showConfirmation = (
    titleText: string,
    subTitleText: string,
    onConfirm: ConfirmationAction,
  ) => {
    setIsOpen(true);
    setAction(() => onConfirm);
    setTitle(titleText);
    setSubTitle(subTitleText);
  };

  const showPublishConfirmation = (
    onConfirm: ConfirmationAction,
    isPublished = false,
  ) => {
    showConfirmation(
      isPublished ? 'Unpublish landing page' : 'Publish landing page',
      `Are you sure you want to ${
        isPublished ? 'unpublish' : 'publish'
      } this landing page?`,
      onConfirm,
    );
  };

  const showDeleteConfirmation = (onConfirm: ConfirmationAction) => {
    showConfirmation(
      'Delete landing page',
      'Are you sure you want to delete this landing page?',
      onConfirm,
    );
  };

  const handleOnConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      await action();
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  }, [action]);

  const Modal = useMemo(
    () => (
      <ConfirmationModal
        open={isOpen}
        isLoading={isLoading}
        title={title || ''}
        subTitle={subTitle || ''}
        onConfirm={handleOnConfirm}
        onCancel={() => setIsOpen(false)}
      />
    ),
    [title, subTitle, isLoading, isOpen, handleOnConfirm],
  );

  return {
    Modal,
    showPublishConfirmation,
    showDeleteConfirmation,
  };
};
