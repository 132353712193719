import { Modal as ModalBase, ModalProps as ModalBaseProps } from '@mui/base';
import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

export type ModalProps = ModalBaseProps & {
  title: string;
  subTitle?: string;
  showCloseButton?: boolean;
};

export function Modal({
  title,
  subTitle,
  children,
  showCloseButton,
  ...props
}: ModalProps) {
  return (
    <ModalBase
      {...props}
      aria-labelledby="modal-title"
      aria-describedby="unstyled-modal-description"
      className={`fixed inset-0 z-50 flex items-center justify-center rounded-xl`}
      slots={{ ...props.slots, backdrop: Backdrop }}
    >
      <div
        className={`z-50 flex flex-col p-10 bg-white outline-none rounded-xl absolute ${props.className ?? ''}`}
      >
        {showCloseButton && (
          <IconButton
            aria-label="close"
            onClick={event => props.onClose?.(event, 'escapeKeyDown')}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2,
            }}
          >
            <Close />
          </IconButton>
        )}
        <h2
          id="modal-title"
          className="text-2xl leading-8 break-words whitespace-pre-wrap"
        >
          {title}
        </h2>
        <h3 className="text-sm leading-6 text-text">{subTitle}</h3>
        <div className="w-full mt-6 overflow-hidden">{children}</div>
      </div>
    </ModalBase>
  );
}

const Backdrop = ({
  children,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className="fixed inset-0 z-40 flex items-center justify-center bg-modal-backdrop"
  >
    {children}
  </div>
);
