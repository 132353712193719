import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ModalProps } from '@mui/base';
import { MouseEvent } from 'react';
import { useTeamDeletion } from '../../hooks/useTeams';

type DeleteTeamDialog = Omit<ModalProps, 'children'> & {
  onSuccess: () => void;
  team: string;
};

export function DeleteTeamDialog({ onSuccess, ...props }: DeleteTeamDialog) {
  const { showNotification } = useNotificationContext();

  const { mutateAsync: deleteTeam, isPending } = useTeamDeletion(props.team);

  const handleDeleteTeam = async () => {
    try {
      await deleteTeam(props.team);
      onSuccess();
      showNotification(`Team deleted successfuly`, NotificationStyle.SUCCESS);
    } catch {
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleCancel = (ev: MouseEvent) => {
    props.onClose?.(ev, 'backdropClick');
  };

  return (
    <Modal
      {...props}
      title={`Delete Team`}
      subTitle="Are you sure you want to delete this team?"
      onClose={(...args) => {
        props.onClose?.(...args);
      }}
      className="max-w-lg mx-auto p-8 pb-3"
    >
      <div>
        <p>
          By clicking 'Delete' you will permanently delete this team and it's
          team members will be unassigned to it. Are you sure you want to
          proceed?
        </p>
        <hr className="absolute left-0 right-0 mt-10" />
        <div className="mt-14 flex justify-end space-x-4">
          <Button type="submit" small className="w-32" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            small
            alert
            type="submit"
            className="w-32"
            isLoading={isPending}
            onClick={handleDeleteTeam}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
