import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { ImageWithFallback } from '@/modules/common/components/ImageWithFallback';
import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from 'react-hook-form';
import { useActiveEditingSectionContext } from '../../hooks/useActiveEditingSectionContext';
import { CourseHeaderSchema } from '../../pages/UpsertCoursePage.constants';

type FormValues = Pick<ICourse, 'name'>;

type CourseHeaderProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SECTION_NAME = 'course-header';

export function CourseHeader({
  onSubmit,
  disabled,
  ...props
}: CourseHeaderProps) {
  const { educationProvider } = useCurrentUserContext() ?? {};

  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const name = getValues('name');

  const { activeEditingSection, setActiveEditingSection } =
    useActiveEditingSectionContext();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      name: name ?? '',
    },
    resolver: yupResolver(CourseHeaderSchema),
  });

  return (
    <div {...props} className={`bg-course-header ${props.className ?? ''}`}>
      <div
        className={`grid grid-cols-12 mx-5 gap-x-5 ${disabled ? 'opacity-50 grayscale' : ''}`}
      >
        <div className="col-span-6 col-start-2 pb-3 pt-9">
          {educationProvider?.logoUrl && (
            <ImageWithFallback
              src={educationProvider.logoUrl}
              fallback={
                <div className="w-20 h-20 bg-transparent rounded-full" />
              }
              alt={educationProvider.name ?? 'education provider'}
              className="w-20 h-20 rounded-full"
              width={80}
              height={80}
            />
          )}
          <h1 className="mt-3 text-2xl">
            {name ?? <span className="text-text">No name set</span>}
          </h1>
          {activeEditingSection === SECTION_NAME ? (
            <form
              action="/"
              method="post"
              onSubmit={e => handleSubmit(onSubmit)(e)}
            >
              <input
                {...register('name')}
                className="w-full px-3 py-2 mt-3 bg-transparent border rounded-lg border-text text-text"
              />
              {formErrors?.name?.message && (
                <p className="mt-1 text-red-500">{formErrors.name.message}</p>
              )}
              <div className="flex items-center gap-2 mt-3">
                <Button
                  primary
                  type="submit"
                  small
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  small
                  disabled={isSubmitting}
                  onClick={() => {
                    reset();
                    setActiveEditingSection('');
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          ) : (
            <>
              {errors?.name?.message && (
                <p className="mt-1 text-red-500">
                  {errors?.name?.message?.toString?.() ?? ''}
                </p>
              )}
              <button
                className="text-xs font-medium text-mydra-purple"
                onClick={() => setActiveEditingSection(SECTION_NAME)}
              >
                Edit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
