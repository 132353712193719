import React from 'react';
import { MydraLogo } from '../MydraLogo';
import { UserMenu } from './components/UserMenu';

type NavBarProps = React.HTMLAttributes<HTMLDivElement> & {
  beforeLogo?: React.ReactNode;
  afterLogo: React.ReactNode;
  beforeUserMenu?: React.ReactNode;
  showUserMenu?: boolean;
};

export function NavBar({
  beforeLogo,
  afterLogo,
  beforeUserMenu,
  showUserMenu,
  ...props
}: NavBarProps) {
  return (
    <div
      {...props}
      className={`${props.className ?? ''} flex justify-between px-6 py-3 min-h-16 bg-white border-b items-center`}
    >
      <div className="flex items-center">
        {beforeLogo}
        <MydraLogo />
        <div className="ml-8">{afterLogo}</div>
      </div>
      <div className="flex items-center gap-11">
        {beforeUserMenu}
        {showUserMenu && <UserMenu />}
      </div>
    </div>
  );
}
