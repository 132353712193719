import { EmployerService } from '@/services/employer';
import { ApiErrorResponse, ApiPaginationParams } from '@/types/api';
import {
  ApiIndividualEmployee,
  ApiIndividualEmployer,
  ApiPaginatedCompanies,
  IEmployersFilters,
} from '@/types/employer';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

export const UseEmployerQueryOptions = (id: string) => ({
  queryKey: [EmployerService.PATH, id],
  queryFn: async () => await EmployerService.getEmployer(id),
  enabled: !!id,
});

export const useGetEmployer = (id: string) =>
  useQuery<ApiIndividualEmployer, ApiErrorResponse>(
    UseEmployerQueryOptions(id),
  );

export function useEmployeeMutation() {
  const queryClient = useQueryClient();
  const mutationKey = [EmployerService.PATH, 'patchEmployee'];

  return useMutation<
    ApiIndividualEmployee,
    ApiErrorResponse,
    {
      id: string;
      employeeId: string;
      updatedData: {
        isAdmin?: boolean;
        learningBudget?: number;
      };
    }
  >({
    mutationKey: mutationKey,
    mutationFn: async ({ id, employeeId, updatedData }) => {
      if (!id || !employeeId) {
        throw new Error('Employer and employee id are required for mutation');
      }

      return EmployerService.patchEmployee(id, employeeId, updatedData);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [EmployerService.PATH],
        exact: false,
      });
    },
  });
}

export const UseEmployersQueryOptions = (
  filters: IEmployersFilters,
  pagination: ApiPaginationParams = {},
  options?: Omit<
    UseQueryOptions<ApiPaginatedCompanies, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) => ({
  queryKey: [EmployerService.PATH],
  queryFn: () => EmployerService.getEmployers(filters, pagination),
  ...options,
});

export function useGetEmployers(
  filters: IEmployersFilters,
  pagination: ApiPaginationParams = {},
  options?: Omit<
    UseQueryOptions<ApiPaginatedCompanies, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<ApiPaginatedCompanies, ApiErrorResponse>(
    UseEmployersQueryOptions(filters, pagination, options),
  );
}

export function useInviteEmployees() {
  const mutationKey = [EmployerService.PATH, 'users/invite'];

  return useMutation<void, ApiErrorResponse, { id: string; file: FormData }>({
    mutationKey: mutationKey,
    mutationFn: async ({ id, file }) => {
      if (!id || !file) {
        throw new Error('Employer and file are required for mutation');
      }
      return EmployerService.inviteEmployees(id, file);
    },
  });
}
