import { Button } from '@/modules/common/components/Button';
import { Modal, ModalProps } from '@/modules/common/components/Modal';
import { TaxBenefitsProviderCompanyLinkStatus } from '@/types/tax-benefits-provider';
import { useEffect, useState } from 'react';

type CancelPartnershipConfirmationModalProps = Omit<
  ModalProps,
  'title' | 'children' | 'onClose'
> & {
  onClose: (cancel: boolean) => void;
  status: TaxBenefitsProviderCompanyLinkStatus;
};

export function CancelPartnershipConfirmationModal({
  onClose,
  status,
  ...props
}: CancelPartnershipConfirmationModalProps) {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [props.open]);

  return (
    <Modal
      {...props}
      onClose={() => onClose?.(false)}
      title={`Are you sure you want to cancel the ${status === TaxBenefitsProviderCompanyLinkStatus.Invited ? 'invitation' : 'partnership'}?`}
      className={`${props.className} max-h-full max-w-md`}
      showCloseButton
    >
      <div className="flex gap-4">
        <Button
          isLoading={isLoading}
          onClick={() => {
            setLoading(true);
            onClose(true);
          }}
        >
          Yes
        </Button>
        <Button disabled={isLoading} onClick={() => onClose(false)} primary>
          No
        </Button>
      </div>
    </Modal>
  );
}
