import { CourseService } from '@/services/course';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactQueryOptions,
} from '@/types/api';
import {
  ApiIndividualCourse,
  ApiIndividualCourseId,
  ApiPaginatedCourse,
  ICourseCreatePayload,
  ICourseCreatePayloadWithAI,
  ICourseUpdatePayload,
} from '@/types/course';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const getCoursesQueryOptions = (
  filters?: ApiQueryParams,
  pagination?: ApiPaginationParams,
  options?: ApiReactQueryOptions<ApiPaginatedCourse>,
) => ({
  queryKey: [CourseService.PATH, filters, pagination],
  queryFn: async () => await CourseService.getCourses(filters, pagination),
  ...options,
});

export const useGetCourses = (
  filters: ApiQueryParams = {},
  pagination: ApiPaginationParams = {},
  options?: ApiReactQueryOptions<ApiPaginatedCourse>,
) =>
  useQuery<ApiPaginatedCourse, ApiErrorResponse>(
    getCoursesQueryOptions(filters, pagination, options),
  );

export function useCourseMutation(courseId: string | undefined) {
  const queryClient = useQueryClient();

  const mutationKey = [CourseService.PATH, courseId];
  return useMutation<
    ApiIndividualCourse,
    ApiErrorResponse,
    { id: string; updatedData: Partial<ICourseUpdatePayload> }
  >({
    mutationKey: mutationKey,
    mutationFn: async ({ id = courseId, updatedData }) => {
      if (!id) {
        throw new Error('Course ID is required for mutation');
      }

      return CourseService.patchCourse(id!, updatedData);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CourseService.PATH],
        exact: false,
      });
    },
  });
}

export function useCourseCreation() {
  const queryClient = useQueryClient();

  const mutationKey = [CourseService.PATH];
  return useMutation<
    ApiIndividualCourse,
    ApiErrorResponse,
    ICourseCreatePayload
  >({
    mutationKey: mutationKey,
    mutationFn: CourseService.postCourse,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CourseService.PATH],
        exact: false,
      });
    },
  });
}

export function useCourseCreationWithAi() {
  const queryClient = useQueryClient();

  const mutationKey = [CourseService.PATH];
  return useMutation<
    ApiIndividualCourseId,
    ApiErrorResponse,
    ICourseCreatePayloadWithAI
  >({
    mutationKey: mutationKey,
    mutationFn: CourseService.postCourseWithAi,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CourseService.PATH],
        exact: false,
      });
    },
  });
}

export function useCourseDeletion(courseId: string | undefined) {
  const queryClient = useQueryClient();

  const mutationKey = [CourseService.PATH, courseId];
  return useMutation<ApiIndividualCourse, ApiErrorResponse, string | undefined>(
    {
      mutationKey: mutationKey,
      mutationFn: async (id = courseId) => {
        if (!id) {
          throw new Error('Course ID is required for mutation');
        }

        return CourseService.deleteCourse(id!);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [CourseService.PATH],
          exact: false,
        });
      },
    },
  );
}

export const getCourseQueryOptions = (
  id: string,
  options?: ApiReactQueryOptions<ApiIndividualCourse>,
) => ({
  queryKey: [CourseService.PATH, id],
  queryFn: async () => await CourseService.getCourse(id),
  ...options,
});

export const useGetCourse = (
  id: string,
  options?: ApiReactQueryOptions<ApiIndividualCourse>,
) =>
  useQuery<ApiIndividualCourse, ApiErrorResponse>(
    getCourseQueryOptions(id, options),
  );
