import { InvitationService } from '@/services/invitation';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactQueryOptions,
} from '@/types/api';
import { ApiPaginatedInvitations } from '@/types/invitation';
import { useQuery } from '@tanstack/react-query';

export const getInvitationsQueryOptions = (
  filters?: ApiQueryParams,
  pagination?: ApiPaginationParams,
  options?: ApiReactQueryOptions<ApiPaginatedInvitations>,
) => ({
  queryKey: [InvitationService.PATH, filters, pagination],
  queryFn: async () => InvitationService.getInvitations(pagination),
  ...options,
});

export const useGetInvitations = () => {
  return useQuery<ApiPaginatedInvitations, ApiErrorResponse>(
    getInvitationsQueryOptions(),
  );
};
