import { ApiError } from '@/services/api';
import { BackHandOutlined, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from '@tanstack/react-router';
import { AdminLayout } from '../../layouts/AdminLayout';
import { Button } from '../Button';

type ErrorMessageProps = Omit<
  React.HTMLAttributes<HTMLDivElement> & {
    error: unknown;
    reset: () => void;
  },
  'children'
>;

export function ErrorMessage({ error, reset, ...props }: ErrorMessageProps) {
  const navigate = useNavigate();

  const ERROR_MESSAGE_MAP = new Map([
    [
      404,
      {
        title: 'Nothing to see here...',
        icon: <VisibilityOff />,
        description:
          'Well this is a bit embarrassing. This isn’t where you’re supposed to be. Let’s get you back to Mydra.',
        action: (
          <>
            <Button
              primary
              onClick={async () => {
                reset();
                await navigate({ to: '/' });
              }}
            >
              Back to home
            </Button>
          </>
        ),
      },
    ],
    [
      403,
      {
        title: 'Access denied!',
        icon: <BackHandOutlined />,
        description: `Sorry folks, you're not authorized to view this page.. Let's get you back to familiar territory.`,
        action: (
          <div className="flex gap-4 justify-center">
            <Button
              onClick={async () => {
                reset();
                await navigate({ to: '/logout' });
                //router.invalidate();
              }}
            >
              Log out
            </Button>
            <Button
              primary
              onClick={async () => {
                reset();
                await navigate({
                  to: '/',
                });
              }}
            >
              Back to home
            </Button>
          </div>
        ),
      },
    ],
    [
      500,
      {
        title: `I’s not you, it’s me.`,
        icon: <BackHandOutlined />,
        description: `Well this is a bit embarrassing. This isn’t where you’re supposed to be.  Let’s get you back to Mydra.`,
        action: (
          <>
            <Button
              primary
              onClick={async () => {
                reset();
                await navigate({ to: '/' });
              }}
            >
              Back to home
            </Button>
          </>
        ),
      },
    ],
  ]);

  const errorMessage =
    ERROR_MESSAGE_MAP.get((error as ApiError).statusCode ?? 500) ??
    ERROR_MESSAGE_MAP.get(500);

  return (
    <AdminLayout>
      <div
        {...props}
        className={`bg-mydra-gray flex justify-center items-center h-full ${props.className ?? ''}`}
      >
        <div className="max-w-lg text-center">
          {errorMessage?.icon}
          <h1 className="mt-3 text-2xl font-medium text-mydra-black">
            {errorMessage?.title}
          </h1>
          <p className="mt-5">{errorMessage?.description}</p>
          <div className="mt-5">{errorMessage?.action}</div>
        </div>
      </div>
    </AdminLayout>
  );
}
