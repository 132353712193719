import { Button } from '@/modules/common/components/Button';
import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoAwesome } from '@mui/icons-material';
import { useForm, useFormContext } from 'react-hook-form';
import { CourseWebAddressSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';

type FormValues = Pick<ICourse, 'url'>;

type CourseAddressProps = {
  enableAICreation?: boolean;
  onSubmit: (data: Pick<ICourse, 'url'>) => void;
  isLoading?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseWebAddress({
  onSubmit,
  enableAICreation,
  isLoading,
  ...props
}: CourseAddressProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const url = getValues('url');

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      url: url ?? '',
    },
    resolver: yupResolver(CourseWebAddressSchema),
  });

  const hasError = !!errors.url;

  return (
    <CourseEditingSection
      {...props}
      className={`
        ${enableAICreation ? 'bg-mydra-violet border border-black' : ''}
      `}
      hasError={hasError}
      sectionName="course-address"
      onSave={handleSubmit(onSubmit)}
      onCancel={() =>
        reset({
          url,
        })
      }
      isLoading={isSubmitting || isLoading}
      defaultSection={
        <div className="flex flex-col">
          {enableAICreation ? (
            <div className="flex flex-col gap-2">
              <p className="font-medium">
                Build your course page in seconds with the AI course generator.{' '}
                <br />
                Please, Insert your course URL page.
              </p>
              <div className="flex gap-2">
                <input
                  {...register('url')}
                  className="flex-1 px-3 py-2 border border-black rounded-xl"
                  placeholder="https://maven.com/nick-st-pierre/midjourney-for-creative"
                />

                <Button
                  onClick={handleSubmit(onSubmit)}
                  className="text-white !bg-mydra-black"
                  disabled={isSubmitting}
                  isLoading={isSubmitting || isLoading}
                >
                  <div className="flex gap-2">
                    <AutoAwesome />
                    Generate
                  </div>
                </Button>
              </div>
              {formErrors.url?.message && (
                <p className="mt-1 text-red-500">{formErrors.url.message}</p>
              )}
            </div>
          ) : (
            <>
              <p className="font-medium">Course URL Link</p>
              {url ? (
                <div className="flex items-baseline gap-2">
                  <p className="text-text">{url}</p>
                </div>
              ) : (
                <p className="text-text">No url set</p>
              )}
            </>
          )}
        </div>
      }
      editingSection={
        enableAICreation ? null : (
          <form
            action="/"
            method="post"
            onSubmit={e => handleSubmit(onSubmit)(e)}
          >
            <label htmlFor="url" className="font-medium">
              Course URL Link
            </label>
            <input
              id="url"
              {...register('url')}
              className="w-full px-3 py-2 mt-3 bg-transparent border rounded-lg border-text text-text"
            />
            {formErrors.url?.message && (
              <p className="mt-1 text-red-500">{formErrors.url.message}</p>
            )}
          </form>
        )
      }
    />
  );
}
