import { Switch } from '@/modules/common/components/Switch';
import { IEmployee, IEmployer } from '@/types/employer';
import { ITeam } from '@/types/team';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import RemoveIcon from '@mui/icons-material/Remove';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { createColumnHelper } from '@tanstack/react-table';
import { format, isValid } from 'date-fns';
import { TableHeader } from './ListEmployeesTable.components';

const columnHelper = createColumnHelper<IEmployee>();

type GetListEmployeeTableColumnsArgs = {
  employer: IEmployer;
  teams: ITeam[];
  onUpdateEmployeeTeam: (employeeId: string, teamId: string) => void;
  onUpdateEmployeeAdmin: (employeeId: string, isAdmin: boolean) => void;
  onUpdateEmployeeLearningBudget: (employeeId: string) => void;
};

export function getListEmployeesTableColumns({
  employer,
  teams,
  onUpdateEmployeeAdmin,
  onUpdateEmployeeTeam,
  onUpdateEmployeeLearningBudget,
}: GetListEmployeeTableColumnsArgs) {
  return [
    columnHelper.accessor('firstName', {
      header: () => <TableHeader>Employee</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex items-center gap-3">
            {original.avatarUrl && (
              <img
                src={original.avatarUrl}
                alt={original.firstName}
                className="object-cover w-20 h-20 rounded-md aspect-square"
              />
            )}
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">{`${original.firstName} ${original.lastName}`}</div>
              <div className="text-xxs text-text ">{original.email}</div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('roles', {
      header: () => <TableHeader>Admin</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <Switch
            onChange={event => {
              onUpdateEmployeeAdmin(original._id!, event.target.checked);
              original.isAdmin = event.target.checked;
            }}
            defaultChecked={original.isAdmin}
          />
        );
      },
    }),
    columnHelper.accessor('team', {
      header: () => <TableHeader>Team</TableHeader>,
      cell: ({ row: { original } }) => {
        const team = original.team as ITeam;
        let currentValue = team ? team._id : '';

        return (
          <span className="text-sm text-mydra-black">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
              <Select
                value={currentValue}
                label="Assign"
                onChange={e => {
                  onUpdateEmployeeTeam(e.target.value, original.email);
                  team._id = e.target.value;
                  team.name = e.target.name;
                  currentValue = e.target.value;
                }}
              >
                {teams.map(t => (
                  <MenuItem key={t._id} value={t._id}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        );
      },
    }),
    columnHelper.accessor('profile', {
      header: () => <TableHeader>Learning Budget</TableHeader>,
      cell: ({ row: { original } }) => {
        const team = original.team as ITeam;

        let icon = null;
        let title = '';
        let budget = null;

        if (original.profile?.learningBudget) {
          icon = <PersonIcon />;
          title = 'Defined individually';
          budget = original.profile.learningBudget;
        } else if (team?.learningBudgetLimit ?? false) {
          icon = <GroupsIcon />;
          title = 'Defined for the team';
          budget = team.learningBudgetLimit;
        } else if (employer.learningBudgetLimit) {
          icon = <BusinessIcon />;
          title = 'Defined for the company';
          budget = employer.learningBudgetLimit;
        } else {
          icon = <EditIcon fontSize="small" sx={{ color: '#777' }} />;
          title = 'Set Learning Budget Limit';
          budget = 0;
        }
        return (
          <span className="text-sm text-mydra-black flex items-center">
            <Tooltip title={title}>
              {budget && budget > 0 ? (
                <Chip
                  icon={icon}
                  label={`${budget} €`}
                  onClick={() => onUpdateEmployeeLearningBudget(original._id!)}
                />
              ) : (
                <span
                  className="cursor-pointer text-sm text-red"
                  onClick={() => onUpdateEmployeeLearningBudget(original._id!)}
                >
                  {icon} <span className="underline text-[#777]">Set</span>
                </span>
              )}
            </Tooltip>
          </span>
        );
      },
    }),
    columnHelper.accessor('lastLogin', {
      header: () => <TableHeader>Last login</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div>
            <span className="text-sm text-mydra-black">
              {original.lastLogin && isValid(new Date(original.lastLogin)) ? (
                format(original.lastLogin, 'PPPp')
              ) : (
                <RemoveIcon />
              )}
            </span>
          </div>
        );
      },
    }),
  ];
}
