import { FileUploadService } from '@/services/file-upload';
import { ApiErrorResponse } from '@/types/api';
import { ApiIndividualFileUploadResponse } from '@/types/file-upload';
import { useMutation } from '@tanstack/react-query';

export function useFileUpload() {
  const mutationKey = [FileUploadService.PATH];

  return useMutation<
    ApiIndividualFileUploadResponse,
    ApiErrorResponse,
    FormData
  >({
    mutationKey: mutationKey,
    mutationFn: FileUploadService.postFile,
  });
}
