import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { TextField } from '@/modules/common/components/TextField';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ApiErrorResponse } from '@/types/api';
import { ITeamPayload } from '@/types/team';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalProps } from '@mui/base';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCreateTeam } from '../../hooks/useTeams';

const FORM_SCHEMA = yup.object().shape({
  name: yup.string().required('name is required'),
});

type CreateTeamDialog = Omit<ModalProps, 'children'> & {
  onSuccess: (email?: string) => void;
};

export function CreateTeamDialog({ onSuccess, ...props }: CreateTeamDialog) {
  const { showNotification } = useNotificationContext();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<ITeamPayload>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: { name: '' },
  });

  const { mutateAsync: createTeam, isPending } = useCreateTeam();

  const handleCreateTeamRequest = async ({ name }: ITeamPayload) => {
    try {
      await createTeam({ name });
      onSuccess(name);
      showNotification(
        `Team ${name} created successfuly`,
        NotificationStyle.SUCCESS,
      );
    } catch (error) {
      if ((error as ApiErrorResponse).statusCode === 400) {
        return setError('root.serverError', {
          type: 'manual',
          message:
            (error as ApiErrorResponse).message ??
            'An error occurred. Please try again later.',
        });
      }
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  const handleCancel = () => {
    props.onClose?.({}, 'backdropClick');
  };

  return (
    <Modal
      {...props}
      title={`Create a new team`}
      subTitle="A team is a department of group, each having its own lists and settings."
      onClose={(...args) => {
        reset();
        props.onClose?.(...args);
      }}
      className="max-w-xl mx-auto p-8 pb-3"
    >
      <form
        onSubmit={handleSubmit(handleCreateTeamRequest)}
        className="flex flex-col justify-end gap-3 p-2"
      >
        {errors.root?.serverError?.message && (
          <ul className="text-red-500 list-disc">
            {errors.root.serverError.message?.split(';').map((error, index) => (
              <li key={index}>
                {error}
                <br />
              </li>
            ))}
          </ul>
        )}
        <div className="flex flex-col gap-6">
          <div>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Name"
                  placeholder="ex. Marketing"
                />
              )}
            />
            {errors.name?.message && (
              <p className="text-sm text-red-500">{errors.name.message}</p>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Description (optional)"
                  placeholder=""
                />
              )}
            />
            {errors.description?.message && (
              <p className="text-sm text-red-500">
                {errors.description.message}
              </p>
            )}
          </div>
        </div>
        <hr className="absolute left-0 right-0 mb-14" />
        <div className="mt-14 flex justify-end space-x-4">
          <Button
            small
            type="submit"
            onClick={handleCancel}
            className="w-32 mt-6"
          >
            Cancel
          </Button>
          <Button
            primary
            small
            type="submit"
            className="w-32 mt-6"
            isLoading={isPending}
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
