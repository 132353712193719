import { TeamService } from '@/services/team';
import {
  ApiErrorResponse,
  ApiQueryParams,
  ApiReactMutationOptions,
  ApiReactQueryOptions,
} from '@/types/api';
import { ApiIndividualTeam, ApiListTeams, ITeamPayload } from '@/types/team';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const getTeamsQueryOptions = (
  filters: ApiQueryParams,
  options?: ApiReactQueryOptions<ApiListTeams>,
) => ({
  queryKey: [TeamService.PATH, filters],
  queryFn: async () => TeamService.getTeams(filters.company as string),
  ...options,
});

export const useGetTeams = (company: string) => {
  return useQuery<ApiListTeams, ApiErrorResponse>(
    getTeamsQueryOptions({ company }),
  );
};

export const getTeamQueryOptions = (
  filters: ApiQueryParams,
  options?: ApiReactQueryOptions<ApiIndividualTeam>,
) => ({
  queryKey: [TeamService.PATH, filters],
  queryFn: async () => TeamService.getTeam(filters.team as string),
  ...options,
});

export const useGetTeam = (team: string) => {
  return useQuery<ApiIndividualTeam, ApiErrorResponse>(
    getTeamQueryOptions({ team }),
  );
};

export const useCreateTeam = (
  options: ApiReactMutationOptions<ApiIndividualTeam, ITeamPayload> = {},
) => {
  const mutationKey = [TeamService.PATH];
  const queryClient = useQueryClient();

  return useMutation<ApiIndividualTeam, ApiErrorResponse, ITeamPayload>({
    ...options,
    mutationKey: mutationKey,
    mutationFn: TeamService.postTeam,
    onSettled: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: [TeamService.PATH],
        exact: false,
      });

      options?.onSettled?.(...args);
    },
  });
};

export function useTeamMutation() {
  const queryClient = useQueryClient();
  const mutationKey = [TeamService.PATH, 'patchTeam'];

  return useMutation<
    ApiIndividualTeam,
    ApiErrorResponse,
    {
      id: string;
      updatedData: {
        name?: string;
        description?: string;
        learningBudgetLimit: number;
      };
    }
  >({
    mutationKey: mutationKey,
    mutationFn: async ({ id, updatedData }) => {
      if (!id) {
        throw new Error('Team id is required for mutation');
      }

      return TeamService.patchTeam(id, updatedData);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [TeamService.PATH],
        exact: false,
      });
    },
  });
}

export function useTeamDeletion(teamId: string) {
  const queryClient = useQueryClient();

  const mutationKey = [TeamService.PATH, teamId];
  return useMutation<void, ApiErrorResponse, string | undefined>({
    mutationKey: mutationKey,
    mutationFn: async (id = teamId) => {
      if (!id) {
        throw new Error('Team ID is required for mutation');
      }

      return TeamService.deleteTeam(id);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [TeamService.PATH],
        exact: false,
      });
    },
  });
}

export function useAddTeamMember() {
  const queryClient = useQueryClient();

  const mutationKey = [TeamService.PATH];
  return useMutation<void, ApiErrorResponse, { teamId: string; email: string }>(
    {
      mutationKey: mutationKey,
      mutationFn: async ({ teamId, email }) => {
        if (!teamId || !email) {
          throw new Error('Team ID and user Email are required for mutation');
        }

        return TeamService.addTeamMember(teamId, { email });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [TeamService.PATH],
          exact: false,
        });
      },
    },
  );
}

export function useRemoveTeamMember(teamId: string, userId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    ApiErrorResponse,
    { teamId: string; userId: string }
  >({
    mutationKey: [TeamService.PATH, teamId, userId],
    mutationFn: async ({ teamId, userId }) => {
      if (!teamId || !userId) {
        throw new Error('Team ID and User ID are required for mutation');
      }

      return TeamService.removeTeamMember(teamId, userId);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [TeamService.PATH],
        exact: false,
      });
    },
  });
}
