import { ImageWithFallback } from '@/modules/common/components/ImageWithFallback';
import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { OndemandVideo, SentimentSatisfiedOutlined } from '@mui/icons-material';
import { useForm, useFormContext } from 'react-hook-form';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { CourseMotivationSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';

type FormValues = Pick<ICourse, 'courseMotivation'>;

type CourseMotivationProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseMotivation({
  onSubmit,
  ...props
}: CourseMotivationProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const courseMotivation = getValues('courseMotivation');

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      courseMotivation: {
        videoUrl: courseMotivation?.videoUrl ?? '',
        motivation: courseMotivation?.motivation ?? '',
        advisor: {
          name: courseMotivation?.advisor?.name ?? '',
          role: courseMotivation?.advisor?.role ?? '',
          avatarUrl: courseMotivation?.advisor?.avatarUrl ?? '',
        },
      },
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CourseMotivationSchema),
  });

  const hasError = !!errors.courseMotivation;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      className={`${props.className}`}
      sectionName="course-motivation"
      sectionTitle="Course Motivation"
      defaultSection={
        <div className="flex flex-col gap-3 text-sm">
          <p className="text-sm text-mydra-black">
            {courseMotivation?.motivation ?? (
              <span className="text-text">No course motivation set</span>
            )}
          </p>
          <div className="p-4 border rounded-xl">
            <div className="flex gap-1 font-medium ">
              <OndemandVideo
                sx={{
                  fontSize: '1rem',
                }}
              />
              Video URL link
            </div>
            {courseMotivation?.videoUrl ? (
              <p>{courseMotivation?.videoUrl ?? ''}</p>
            ) : (
              <p className="text-text">No video url set</p>
            )}
          </div>
          <div className="p-4 border rounded-xl">
            <div className="flex items-center gap-2 mb-3 font-medium">
              <ImageWithFallback
                src={courseMotivation?.advisor?.avatarUrl}
                fallback={
                  <SentimentSatisfiedOutlined
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                }
                alt="avatar"
                className="w-8 h-8 rounded-full"
              />
              Advisor
            </div>
            {!courseMotivation?.advisor?.name &&
            !courseMotivation?.advisor?.role ? (
              <p className="text-text">No advisor set</p>
            ) : (
              <>
                <p>{courseMotivation?.advisor?.name ?? ''}</p>
                <p>{courseMotivation?.advisor?.role ?? ''}</p>
              </>
            )}
          </div>
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <ReactTextareaAutosize
              {...register(`courseMotivation.motivation`)}
              rows={3}
              className="p-4 text-sm border rounded-xl"
              placeholder="This course is designed to help you..."
            />
            {formErrors.courseMotivation?.motivation && (
              <p className="text-red-500">
                {formErrors.courseMotivation.motivation.message}
              </p>
            )}
            <label className="flex flex-col gap-2 p-4 border rounded-xl">
              <div className="flex items-center gap-1 font-medium">
                <OndemandVideo
                  sx={{
                    fontSize: '1rem',
                  }}
                />
                Video URL link
              </div>
              <input
                {...register(`courseMotivation.videoUrl`)}
                className="p-2 text-sm border rounded-xl"
                placeholder="https://www.youtube.com/watch?v=example"
              />
              {formErrors.courseMotivation?.videoUrl && (
                <p className="text-red-500">
                  {formErrors.courseMotivation.videoUrl.message}
                </p>
              )}
            </label>

            <label className="flex flex-col gap-2 p-4 border rounded-xl">
              <div className="flex items-center gap-1 font-medium">
                <OndemandVideo
                  sx={{
                    fontSize: '1rem',
                  }}
                />
                Advisor
              </div>
              <input
                {...register(`courseMotivation.advisor.name`)}
                className="p-2 text-sm border rounded-xl"
                placeholder="John Doe"
              />
              {formErrors.courseMotivation?.advisor?.name && (
                <p className="text-red-500">
                  {formErrors.courseMotivation.advisor.name.message}
                </p>
              )}
              <input
                {...register(`courseMotivation.advisor.role`)}
                className="p-2 text-sm border rounded-xl"
                placeholder="Marketing Specialist"
              />
              {formErrors.courseMotivation?.advisor?.role && (
                <p className="text-red-500">
                  {formErrors.courseMotivation.advisor.role.message}
                </p>
              )}
              <input
                {...register(`courseMotivation.advisor.avatarUrl`)}
                className="p-2 text-sm border rounded-xl"
                placeholder="https://www.example.com/avatar.jpg"
              />
              {formErrors.courseMotivation?.advisor?.avatarUrl && (
                <p className="text-red-500">
                  {formErrors.courseMotivation.advisor.avatarUrl.message}
                </p>
              )}
            </label>
          </div>
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() => reset({ courseMotivation })}
    />
  );
}
