import { Button } from '@/modules/common/components/Button';
import { Tag } from '@/modules/common/components/Tag';
import { formatCurrencyValue } from '@/modules/common/lib/formatters';
import { roundHalfToEven } from '@/modules/common/lib/moneyHandler';
import {
  ApplicationStatus,
  ApplicationValidationReviewer,
  ApplicationValidationStatus,
  IApplicationValidation,
  IApplicationWithOrderAndCourse,
} from '@/types/application';
import { ICompany } from '@/types/company';
import { ICourse } from '@/types/course';
import { IUser, UserRole } from '@/types/user';
import { createColumnHelper } from '@tanstack/react-table';
import pluralize from 'pluralize';
import { ApplicationStatus as ApplicationStatusTag } from '../ApplicationStatus';
import { TableHeader } from './ListApplicationsTable.components';

const columnHelper = createColumnHelper<IApplicationWithOrderAndCourse>();

const getPrice = (application: IApplicationWithOrderAndCourse) => {
  const cohort = (application.course as ICourse).cohorts?.find(
    cohort => cohort._id === application.cohort,
  );

  if (cohort?.cost?.amount) {
    return formatCurrencyValue(
      cohort.cost.amount.toString(),
      cohort.cost.currency,
    );
  }

  return '-';
};

export const getReviewerPendingValidations = (
  validations: IApplicationValidation[],
  reviewer: ApplicationValidationReviewer,
) => {
  return validations.filter(validation =>
    validation.reviews.some(
      review =>
        review.reviewer === reviewer &&
        review.status === ApplicationValidationStatus.Pending,
    ),
  );
};

const showReviewAction = (
  application: IApplicationWithOrderAndCourse,
  userRoles: string[],
) => {
  const { statuses = [], validations = [] } = application;

  if (statuses[statuses.length - 1] !== ApplicationStatus.PendingValidation) {
    return false;
  }

  if (userRoles.includes(UserRole.TaxBenefitsProvider)) {
    const taxBenefitsProviderValidations = getReviewerPendingValidations(
      validations,
      ApplicationValidationReviewer.FlexibleRemunerationProvider,
    );

    console.log(taxBenefitsProviderValidations);

    return !!taxBenefitsProviderValidations?.length;
  }

  if (userRoles.includes(UserRole.EducationProvider)) {
    const educationProviderValidations = getReviewerPendingValidations(
      validations,
      ApplicationValidationReviewer.EducationProvider,
    );

    return !!educationProviderValidations?.length;
  }

  const employerValidations = getReviewerPendingValidations(
    validations,
    ApplicationValidationReviewer.Employer,
  );
  return !!employerValidations?.length;
};

type GetListApplicationsTableColumnsArgs = {
  onApplicationReview: (application: IApplicationWithOrderAndCourse) => void;
  userRoles: string[];
};

export function getListApplicationsTableColumns({
  onApplicationReview,
  userRoles,
}: GetListApplicationsTableColumnsArgs) {
  return [
    columnHelper.display({
      id: 'checkbox',
      header: () => null,
      cell: ({ row }) => {
        const isSelected = row.getIsSelected();
        const isEducationProvider = userRoles.includes(
          UserRole.EducationProvider,
        );

        const isDisabled =
          isEducationProvider || !showReviewAction(row.original, userRoles);

        return (
          <div className="flex justify-center flex-1 p-4 align-center">
            <input
              type="checkbox"
              className="disabled:cursor-not-allowed"
              checked={isSelected}
              disabled={isDisabled}
              title={
                isDisabled
                  ? 'Education provider reviews are done on a one-by-one basis'
                  : ''
              }
              onChange={() => row.toggleSelected()}
            />
          </div>
        );
      },
    }),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider)
      ? [
          columnHelper.accessor('user.company.name', {
            header: () => <TableHeader>Company</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {((original.user as IUser)?.company as ICompany)?.name ??
                      ''}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor('user', {
      header: () => <TableHeader>Employee</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <div className="text-sm font-medium">{`${(original.user as IUser)?.firstName} ${(original.user as IUser)?.lastName}`}</div>
              <div className="text-xxs text-text">
                {(original.user as IUser).email}
              </div>
            </div>
          </div>
        );
      },
    }),
    ...(userRoles.includes(UserRole.EducationProvider)
      ? [
          columnHelper.accessor('user.profile.currentRole', {
            header: () => <TableHeader>Job Role</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div className="text-xs">
                  {original.user.profile?.currentRole ?? '(not informed)'}
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor('course.name', {
      header: () => <TableHeader>Course</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div>
            <p className="text-xs font-semibold text-mydra-black">
              {original.course?.name}
            </p>
            <p className="text-xs text-mydra-black">
              {original.course?.educationProvider?.name}
            </p>
          </div>
        );
      },
    }),
    ...(userRoles.includes(UserRole.EducationProvider)
      ? [
          columnHelper.accessor('course.skills', {
            header: () => <TableHeader>Skills</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div className="flex flex-wrap gap-1 text-xs">
                  {(original.course?.skills ?? []).map(skillName => (
                    <Tag
                      className="!rounded-full bg-mydra-violet"
                      key={skillName}
                      text={skillName}
                    />
                  ))}
                </div>
              );
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider) ||
    userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('course.cohorts.cost', {
            header: () => <TableHeader>Price</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {getPrice(original)}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('learningBudget', {
            header: () => <TableHeader>Learning Budget</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {original.learningBudget?.amount
                      ? formatCurrencyValue(
                          original.learningBudget.amount.toString(),
                          original.learningBudget.currency,
                        )
                      : '-'}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('flexibleRemuneration', {
            header: () => <TableHeader>Tax Benefits</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {original.flexibleRemuneration ? 'Yes' : 'No'}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider) ||
    userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('applicationTerm', {
            header: () => <TableHeader>Type</TableHeader>,
            cell: ({
              row: { original: { applicationTerm = '', order } = {} },
            }) => {
              if (!order) {
                return (
                  <span className="text-sm capitalize text-mydra-black">
                    {applicationTerm.toLocaleLowerCase()}
                  </span>
                );
              }

              const { totalAmount, learningBudgetAmount } = order;

              return (
                <div>
                  <span className="text-sm capitalize text-mydra-black">
                    {learningBudgetAmount?.amount
                      ? learningBudgetAmount.amount === totalAmount
                        ? 'learning budget in full'
                        : `learning budget + ${applicationTerm.toLowerCase()}`
                      : applicationTerm.toLocaleLowerCase()}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor('statuses', {
      header: () => <TableHeader>Status</TableHeader>,
      cell: ({ row: { original } }) => (
        <ApplicationStatusTag
          status={original.statuses![original.statuses!.length - 1]}
        />
      ),
    }),
    ...(userRoles.includes(UserRole.TaxBenefitsProvider) ||
    userRoles.includes(UserRole.Employer)
      ? [
          columnHelper.accessor('order.userPaymentAmount.amount', {
            header: () => <TableHeader>Payment details</TableHeader>,
            cell: ({ row: { original } }) => {
              const totalAmount = original.order.userPaymentAmount.amount;
              const numberOfInstalments =
                original.applicationData?.contractChoices
                  ?.fixedNumberOfInstalments ?? 1;
              const amountPerInstalment = roundHalfToEven(
                totalAmount / numberOfInstalments,
              );

              return (
                <div className="flex flex-col">
                  <p>
                    {formatCurrencyValue(
                      totalAmount.toString(),
                      original.order.userPaymentAmount.currency,
                      { maximumFractionDigits: 2 },
                    )}
                  </p>
                  <p className="text-xs">
                    {numberOfInstalments}{' '}
                    {pluralize('installment', numberOfInstalments)} of{' '}
                    {formatCurrencyValue(
                      amountPerInstalment.toString(),
                      original.order.userPaymentAmount.currency,
                      { maximumFractionDigits: 2 },
                    )}
                  </p>
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.display({
      id: 'actions',
      header: () => <TableHeader className="text-right">Actions</TableHeader>,
      cell: ({ row: { original } }) => {
        const isReviewActionVisible = showReviewAction(original, userRoles);

        return (
          <div
            className={`${isReviewActionVisible ? 'flex gap-2 ml-auto' : 'w-full'}`}
          >
            <div className={isReviewActionVisible ? 'ml-auto' : 'text-center'}>
              {isReviewActionVisible ? (
                <Button
                  small
                  onClick={() => {
                    onApplicationReview(original);
                  }}
                >
                  Review
                </Button>
              ) : (
                <span className="text-center">&mdash;</span>
              )}
            </div>
          </div>
        );
      },
    }),
  ];
}
