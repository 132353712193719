import { TableHeader } from '@/modules/common/components/TableHeader';
import { formatCurrencyValue } from '@/modules/common/lib/formatters';
import { ICompany } from '@/types/company';
import { ICourse } from '@/types/course';
import { IPayment } from '@/types/payment';
import { IUser, UserRole } from '@/types/user';
import { createColumnHelper } from '@tanstack/react-table';
import { format, isValid } from 'date-fns';
import { PaymentStatus as PaymentStatusTag } from '../PaymentStatus';

const columnHelper = createColumnHelper<IPayment>();

export function getListPaymentsTableColumns(userRoles: UserRole[]) {
  return [
    ...(userRoles.includes(UserRole.TaxBenefitsProvider)
      ? [
          columnHelper.accessor('user.company.name', {
            header: () => <TableHeader>Company</TableHeader>,
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <span className="text-sm text-mydra-black">
                    {((original.user as IUser)?.company as ICompany)?.name ??
                      ''}
                  </span>
                </div>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor('user.firstName', {
      header: () => <TableHeader>Employee</TableHeader>,
      cell: ({ row: { original } }) => {
        const user = original.user as IUser;

        return (
          <div className="flex gap-3 items-center">
            {user.avatarUrl && (
              <img
                src={user.avatarUrl}
                alt={user.firstName}
                className="object-cover w-20 h-20 rounded-md aspect-square"
              />
            )}
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">{`${user.firstName} ${user.lastName}`}</div>
              <div className="text-xxs text-text">{user.email}</div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('order.item.course.name', {
      header: () => <TableHeader>Course</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <span className="text-sm text-mydra-black">
            {(original.order?.item.course as ICourse).name}
          </span>
        );
      },
    }),
    columnHelper.accessor('totalAmount', {
      header: () => <TableHeader>Amount</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <span className="text-sm text-mydra-black">
            {formatCurrencyValue(
              original.totalAmount.toString(),
              original.currency,
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor('createdAt', {
      header: () => <TableHeader>Date</TableHeader>,
      cell: ({ row: { original } }) => {
        return (
          <div>
            <span className="text-sm text-mydra-black">
              {isValid(new Date(original.createdAt)) &&
                format(original.createdAt, 'MMMM yyyy')}
            </span>
          </div>
        );
      },
    }),
    columnHelper.accessor('status', {
      header: () => <TableHeader>Status</TableHeader>,
      cell: ({ row: { original } }) => (
        <PaymentStatusTag status={original.status} />
      ),
    }),
  ];
}
