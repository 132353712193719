import { ApiPaginationParams, ApiQueryParams } from '@/types/api';
import { ApiIndividualCategory, ApiPaginatedCategory } from '@/types/category';
import qs from 'query-string';
import { ApiService } from './api';

export class CategoryService extends ApiService {
  static PATH = '/categories';

  static async getCategories(
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedCategory> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${CategoryService.PATH}?${queryString}`);
  }

  static async getCategory(id: string): Promise<ApiIndividualCategory> {
    return ApiService.get(`${CategoryService.PATH}/${id}`);
  }
}
