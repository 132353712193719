import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableFooter } from './ListEmployeesTable.components';
import { getListEmployeesTableColumns } from './ListEmployeesTable.constants';

import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { useEmployeeMutation } from '@/modules/common/hooks/useEmployer';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { EmployeeLearningBudgetDialog } from '@/modules/employees/components/EmployeeLearningBudgetDialog';
import {
  useAddTeamMember,
  useGetTeams,
} from '@/modules/employees/hooks/useTeams';
import { IEmployee } from '@/types/employer';
import { IUser } from '@/types/user';
import { CancelPresentation } from '@mui/icons-material';
import { useState } from 'react';

type ListEmployeesTable = {
  employees: IEmployee[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
} & React.TableHTMLAttributes<HTMLTableElement>;

export function ListEmployeesTable({
  employees,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  ...props
}: ListEmployeesTable) {
  const { mutateAsync: updateEmployee } = useEmployeeMutation();
  const { showNotification } = useNotificationContext();
  const userContext = useCurrentUserContext();
  const [learningBudgetEmployeeId, setLearningBudgetEmployeeId] = useState('');

  const { data: { data: teams = [] } = {} } = useGetTeams(
    userContext!.currentUser.company! as string,
  );

  const { mutateAsync: addTeamMember } = useAddTeamMember();

  const columns = getListEmployeesTableColumns({
    employer: userContext!.employer!,
    teams,
    onUpdateEmployeeTeam: async (teamId: string, employeeEmail: string) => {
      try {
        await addTeamMember({ teamId, email: employeeEmail });

        showNotification(
          `Employee's team updated successfully`,
          NotificationStyle.SUCCESS,
        );
      } catch {
        showNotification(
          `Failed to save employee to team`,
          NotificationStyle.ERROR,
        );
      }
    },
    onUpdateEmployeeAdmin: async (employeeId: string, isAdmin: boolean) => {
      try {
        await updateEmployee({
          id: userContext!.employer!._id!,
          employeeId,
          updatedData: { isAdmin },
        });

        showNotification(
          `Employee updated successfully`,
          NotificationStyle.SUCCESS,
        );
      } catch {
        showNotification(`Failed to update employee`, NotificationStyle.ERROR);
      }
    },
    onUpdateEmployeeLearningBudget: async (employeeId: string) => {
      setLearningBudgetEmployeeId(employeeId);
    },
  });

  const table = useReactTable<IEmployee>({
    data: employees,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel<IUser>(),
  });

  const rows = table.getRowModel().rows;

  const hasNoEmployees = rows.length === 0 && !isLoading;

  return (
    <>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoEmployees ? 'h-full' : ''}`}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-3 first:px-0 last:px-0"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoEmployees ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="h-full p-0 text-center"
              >
                <div className="flex flex-col justify-center h-full p-3 align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className=" text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">{`No employees found`}</p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
      <EmployeeLearningBudgetDialog
        open={!!learningBudgetEmployeeId}
        onClose={() => setLearningBudgetEmployeeId('')}
        onSuccess={() => {
          setLearningBudgetEmployeeId('');
        }}
        employee={learningBudgetEmployeeId}
      />
    </>
  );
}
