import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { TableFooter } from '@/modules/common/components/TableFooter';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { formatCurrencyValue } from '@/modules/common/lib/formatters';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { IPayment } from '@/types/payment';
import { UserRole } from '@/types/user';
import { CancelPresentation, Close } from '@mui/icons-material';
import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import pluralize from 'pluralize';
import { useState } from 'react';
import { usePayPayments } from '../../hooks/usePayments';
import { getListPendingPaymentsTableColumns } from './ListPendingPayments.constants';

type ListPendingPayments = {
  payments: IPayment[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
} & React.TableHTMLAttributes<HTMLTableElement>;

export function ListPendingPayments({
  payments,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  ...props
}: ListPendingPayments) {
  const { currentUser: { roles: userRoles = [] } = {} } =
    useCurrentUserContext() ?? {};
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [paymentIdsToPay, setPaymentIdsToPay] = useState<string[]>([]);
  const { showNotification } = useNotificationContext();
  const { mutateAsync: payPayments } = usePayPayments();

  const handleReviewMultiple = async () => {
    const selectedRowModel = table.getSelectedRowModel();

    if (selectedRowModel.rows.length === 0) {
      return;
    }

    setPaymentIdsToPay(selectedRowModel.rows.map(row => row.original._id!));
    setConfirmationModalOpen(true);
  };

  const paySelectedPayments = async () => {
    if (!paymentIdsToPay.length) {
      return;
    }

    try {
      const result = await payPayments({ paymentIds: paymentIdsToPay });
      setConfirmationModalOpen(false);

      window.open(result.data.redirectUrl, '_blank');

      table.resetRowSelection();
    } catch {
      showNotification(
        `Failed to pay payment${paymentIdsToPay.length > 1 ? 's' : ''}`,
        NotificationStyle.ERROR,
      );
    }
  };

  const columns = getListPendingPaymentsTableColumns({
    onPay: paymentId => {
      setPaymentIdsToPay([...paymentIdsToPay, paymentId]);
      setConfirmationModalOpen(true);
    },
    userRoles: userRoles as UserRole[],
  });

  const table = useReactTable<IPayment>({
    data: payments,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel<IPayment>(),
  });

  const rows = table.getRowModel().rows;

  const hasNoPayments = rows.length === 0 && !isLoading;

  return (
    <>
      <div className="flex flex-col gap-4 py-5">
        <div>
          <h2 className="text-2xl font-medium">Billing</h2>
          <p className="text-sm">Total Learning Budgets to Pay</p>
        </div>
        <input
          id="totalToPay"
          className="p-4 w-max text-xl text-gray-500 rounded-xl border"
          disabled
          value={formatCurrencyValue(
            table
              .getSelectedRowModel()
              .rows.reduce((acc, row) => acc + row.original.totalAmount, 0)
              .toString(),
            table.getSelectedRowModel().rows[0]?.original.currency || 'EUR',
          )}
        />
      </div>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoPayments ? 'h-full' : ''}`}
      >
        <thead>
          {!!table.getSelectedRowModel().rows.length && (
            <tr>
              <th
                colSpan={table.getVisibleFlatColumns().length}
                className="p-0"
              >
                <div className="flex gap-3 items-center p-4 w-full text-xs font-medium rounded-md bg-gray-background">
                  <p>
                    {pluralize(
                      'item',
                      table.getSelectedRowModel().rows.length,
                      true,
                    )}{' '}
                    selected
                  </p>
                  <button
                    className="flex items-center p-1 ml-10 rounded-md text-mydra-purple hover:bg-mydra-medium-gray hover:text-black"
                    onClick={() => table.resetRowSelection()}
                  >
                    <Close className="w-6 h-6" />
                    Deselect
                  </button>

                  <div className="flex justify-end w-full">
                    <Button small primary onClick={handleReviewMultiple}>
                      Pay in full
                    </Button>
                  </div>
                </div>
              </th>
            </tr>
          )}
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-3 first:px-0 last:px-0"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoPayments ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="p-0 h-full text-center"
              >
                <div className="flex flex-col justify-center p-3 h-full align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className="text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">{`No payments found`}</p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
      <Modal
        open={isConfirmationModalOpen}
        title={`Are you sure you want to pay ${paymentIdsToPay.length > 1 ? 'these payments' : 'this payment'}`}
        onClose={() => setConfirmationModalOpen(false)}
        showCloseButton
        className="max-w-2xl"
      >
        <div className="flex gap-3 justify-end">
          <Button
            onClick={() => {
              setConfirmationModalOpen(false);
              table.resetRowSelection();
            }}
          >
            Cancel
          </Button>
          <Button primary onClick={paySelectedPayments}>
            Yes, Please
          </Button>
        </div>
      </Modal>
    </>
  );
}
