import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { useGetTeam } from '@/modules/employees/hooks/useTeams';
import { ArrowBackIosOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { getRouteApi } from '@tanstack/react-router';
import { useGetEmployee } from '../hooks/useEmployees';

type Params = {
  employeeId: string;
};

const routeApi = getRouteApi('/_admin/employees/$employeeId');

export function ViewEmployeePage() {
  const { employeeId } = routeApi.useParams<Params>();
  const currentUser = useCurrentUserContext();

  const { isLoading: isLoadingEmployee, data: { data: employee } = {} } =
    useGetEmployee(currentUser?.employer?._id ?? '', employeeId);

  const { isLoading: isLoadingTeam, data: { data: team } = {} } = useGetTeam(
    employee?.team as string,
  );

  if (!employee || isLoadingEmployee || isLoadingTeam) {
    return <CircularProgress />;
  }

  return (
    <div className="flex flex-col h-full">
      <header className="flex justify-between items-center px-12 py-5 border-b">
        <h1 className="text-2xl font-medium flex items-center">
          <a
            className="mr-2 inline-block align-bottom"
            onClick={() => window.history.back()}
          >
            <ArrowBackIosOutlined fontSize={'small'} />
          </a>
          <span>
            {employee.firstName} {employee.lastName}
          </span>
        </h1>
      </header>

      <div className="flex-1 px-12 pt-8">
        <h2 className="font-semibold">Employee Info</h2>
        <p className="mt-4">
          <span className="font-semibold">Name:</span> {employee.firstName}{' '}
          {employee.lastName}
        </p>

        <p className="mt-4">
          <span className="font-semibold">Team:</span>{' '}
          {team ? team.name : 'N/A'}
        </p>

        <p className="mt-4">
          <span className="font-semibold">Learning Budget Limit:</span>{' '}
          {employee.profile.learningBudget
            ? `${employee.profile.learningBudget} €`
            : 'N/A'}
        </p>

        <p className="mt-4">
          <span className="font-semibold">Used Learning Budget:</span>{' '}
          {employee.profile.learningBudget ? `0 €` : 'N/A'}
        </p>
      </div>
      <div className="flex-1 px-12 pt-8">
        <div className="flex justify-end">
          <Button small alert onClick={() => ''}>
            Remove From Company
          </Button>
        </div>
      </div>
    </div>
  );
}
