import { USER_ROLES } from '@/constants';

import { CurrentUserContextProvider } from '@/modules/auth/providers/AuthUserProvider';
import { UseEmployerQueryOptions } from '@/modules/common/hooks/useEmployer';
import { getUseCurrentUserOptions } from '@/modules/common/hooks/useUser';
import { ApiError } from '@/services/api';
import { Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_admin')({
  beforeLoad: async ({ context: { queryClient } }) => {
    const { data: user } = await queryClient.ensureQueryData(
      getUseCurrentUserOptions(),
    );

    if (
      ![
        USER_ROLES.EDUCATION_PROVIDER,
        USER_ROLES.EMPLOYER,
        USER_ROLES.TAX_BENEFITS_PROVIDER,
      ].some(role => user?.roles?.includes(role))
    ) {
      throw new ApiError('Access denied', 403);
    }

    if (user?.roles.includes(USER_ROLES.EMPLOYER)) {
      try {
        await queryClient.ensureQueryData(
          UseEmployerQueryOptions(user.company as string),
        );
      } catch {
        throw new ApiError('Access denied', 403);
      }
    }

    return {
      user,
    };
  },
  component: () => (
    <CurrentUserContextProvider>
      <Outlet />
    </CurrentUserContextProvider>
  ),
});
