import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { TextField } from '@/modules/common/components/TextField';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalProps } from '@mui/base';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useUserPasswordResetRequest } from '../hooks/useAuth';

export type ConfirmResetPasswordDialogForValues = {
  email: string;
};

const FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email('Please provide an valid e-mail address')
    .required('Please input your email address'),
});

type ConfirmResetPasswordDialog = Omit<ModalProps, 'children'> & {
  defaultEmail?: string;
  onSuccess: () => void;
};

export function ConfirmResetPasswordDialog({
  onSuccess,
  defaultEmail,
  ...props
}: ConfirmResetPasswordDialog) {
  const { showNotification } = useNotificationContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConfirmResetPasswordDialogForValues>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      email: defaultEmail ?? '',
    },
  });

  const { mutateAsync: requestUserPasswordReset, isPending } =
    useUserPasswordResetRequest();

  const handlePasswordResetRequest = async ({
    email,
  }: ConfirmResetPasswordDialogForValues) => {
    try {
      await requestUserPasswordReset({
        email,
        redirectTo: `/login`,
      });
      onSuccess();
      showNotification(
        'Instructions were sent to your e-mail',
        NotificationStyle.SUCCESS,
      );
      reset();
    } catch {
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  return (
    <Modal
      {...props}
      title="Forgot Password?"
      onClose={(...args) => {
        reset();
        props.onClose?.(...args);
      }}
      className="w-96"
    >
      <form
        onSubmit={handleSubmit(handlePasswordResetRequest)}
        className="flex flex-col justify-end gap-3"
      >
        <p>
          Enter the email address you signed up with below. An email will be
          sent containing a link to reset your password.
        </p>
        <p>
          Forgot which email address you signed up with?{' '}
          <a
            className="underline"
            href="mailto:support@mydra.io"
            target="_blank"
          >
            Get help.
          </a>
        </p>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-9"
              placeholder="Email address"
            />
          )}
        />
        {errors.email?.message && (
          <p className="text-sm text-red-500">{errors.email.message}</p>
        )}
        <Button
          primary
          type="submit"
          className="w-full mt-6"
          isLoading={isPending}
        >
          Send reset instructions
        </Button>
      </form>
    </Modal>
  );
}
