import {
  ApplicationValidationReviewer,
  ApplicationValidationStatus,
  ApplicationValidationType,
  IApplicationValidation,
} from '@/types/application';

export function useApplicationValidationStatusText() {
  const getStatusText = (validations: IApplicationValidation[]) => {
    const { approvedTexts, rejectedTexts, awaitingTexts } = validations.reduce(
      (acc, validation) => {
        const approvedReviewers: string[] = [];
        const rejectedReviewers: string[] = [];
        const pendingReviewers: string[] = [];

        validation.reviews.forEach(review => {
          const reviewerText = getReviewerText(review.reviewer);

          if (review.status === ApplicationValidationStatus.Pending) {
            pendingReviewers.push(reviewerText);
          } else if (review.status === ApplicationValidationStatus.Approved) {
            approvedReviewers.push(reviewerText);
          } else if (review.status === ApplicationValidationStatus.Rejected) {
            rejectedReviewers.push(reviewerText);
          }
        });

        if (approvedReviewers.length > 0) {
          const approvedText = getPredefinedPhrase(
            'approved',
            approvedReviewers,
            validation.name,
          );
          acc.approvedTexts.push(approvedText as never);
        }

        if (rejectedReviewers.length > 0) {
          const rejectedText = getPredefinedPhrase(
            'rejected',
            rejectedReviewers,
            validation.name,
          );
          acc.rejectedTexts.push(rejectedText as never);
        }

        if (pendingReviewers.length > 0) {
          const awaitingText = getPredefinedPhrase(
            'awaiting',
            pendingReviewers,
            validation.name,
          );
          acc.awaitingTexts.push(awaitingText as never);
        }

        return acc;
      },
      {
        approvedTexts: [],
        rejectedTexts: [],
        awaitingTexts: [],
      },
    );

    const resultText = [...approvedTexts, ...rejectedTexts, ...awaitingTexts]
      .join(' ')
      .trim();
    return capitalizeFirstLetter(resultText);
  };

  function getReviewerText(reviewer: ApplicationValidationReviewer): string {
    switch (reviewer) {
      case ApplicationValidationReviewer.EducationProvider:
        return 'education provider';
      case ApplicationValidationReviewer.Employer:
        return 'company';
      case ApplicationValidationReviewer.FlexibleRemunerationProvider:
        return 'flexible remuneration provider';
      case ApplicationValidationReviewer.Internal:
        return 'internal reviewers';
      default:
        return 'unknown reviewer';
    }
  }

  function getValidationTypeLabel(
    validationType: ApplicationValidationType,
  ): string {
    switch (validationType) {
      case ApplicationValidationType.LearningBudget:
        return 'learning budget';
      case ApplicationValidationType.Scoring:
        return 'scoring';
      case ApplicationValidationType.FlexibleRemuneration:
        return 'flexible remuneration';
      case ApplicationValidationType.Skills:
        return 'skills validation';
      case ApplicationValidationType.Capacity:
        return 'capacity';
      default:
        return 'unknown validation';
    }
  }

  function getPredefinedPhrase(
    status: 'approved' | 'rejected' | 'awaiting',
    reviewers: string[],
    validationType: ApplicationValidationType,
  ): string {
    const typeLabel = getValidationTypeLabel(validationType);
    const formattedReviewers = formatReviewersList(reviewers);

    if (status === 'approved') {
      return reviewers.length === 1
        ? `${formattedReviewers} has approved the ${typeLabel}.`
        : `${formattedReviewers} have approved the ${typeLabel}.`;
    }

    if (status === 'rejected') {
      return reviewers.length === 1
        ? `${formattedReviewers} has rejected the ${typeLabel}.`
        : `${formattedReviewers} have rejected the ${typeLabel}.`;
    }

    if (status === 'awaiting') {
      return reviewers.length === 1
        ? `Awaiting ${formattedReviewers} approval for the ${typeLabel}.`
        : `Awaiting approval from ${formattedReviewers} for the ${typeLabel}.`;
    }

    return '';
  }

  function formatReviewersList(reviewers: string[]): string {
    if (reviewers.length === 1) {
      return reviewers[0];
    }
    const lastReviewer = reviewers.pop();
    return reviewers.join(', ') + ' and ' + lastReviewer;
  }

  function capitalizeFirstLetter(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return { getStatusText };
}
