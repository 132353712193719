import {
  DEFAULT_COURSE_CLASS_TYPES,
  DEFAULT_COURSE_FORMATS,
} from '@/constants';
import { formatCurrencyValue } from '@/modules/common/lib/formatters';
import {
  integerToMoney,
  moneyToInteger,
} from '@/modules/common/lib/moneyHandler';
import { DurationType, ICohort, ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Add,
  ClassOutlined,
  DateRangeOutlined,
  DeleteOutline,
  FormatListBulletedOutlined,
  InfoOutlined,
  LocationOnOutlined,
  LockClockOutlined,
  MonetizationOnOutlined,
} from '@mui/icons-material';
import { format, isValid } from 'date-fns';
import pluralize from 'pluralize';
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { CohortsSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';
import { CourseItem } from '../CourseItem';

type FormValues = Pick<ICourse, 'cohorts'>;

type CourseAddressProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseCohorts({ onSubmit, ...props }: CourseAddressProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const cohorts = getValues('cohorts');

  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      cohorts: cohorts ?? [],
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CohortsSchema),
  });

  const {
    fields: cohortFields,
    append: appendCohortField,
    remove: removeCohortField,
  } = useFieldArray({
    control,
    name: 'cohorts',
  });

  const handleCohortAdd = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    appendCohortField({
      location: '',
      format: '',
      classType: '',
      cost: {
        amount: 0,
        currency: 'EUR',
      },
      startDate: null,
      endDate: null,
      duration: {
        quantity: 0,
        quantityInDays: 0,
        type: DurationType.Hour,
      },
    } as ICohort);
  };

  const handleFieldClear =
    (fieldName: 'cost' | 'duration') =>
    (cohortIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue(`cohorts.${cohortIndex}.${fieldName}`, null);
      } else {
        if ('cost' === fieldName) {
          setValue(`cohorts.${cohortIndex}.${fieldName}`, {
            amount: 0,
            currency: 'EUR',
          });
        } else {
          setValue(`cohorts.${cohortIndex}.${fieldName}`, {
            quantity: 0,
            type: DurationType.Hour,
            quantityInDays: 0,
          });
        }
      }
    };

  const FIELDS_INFO = [
    {
      label: 'Location',
      fieldName: 'location',
      icon: (
        <LocationOnOutlined
          sx={{
            fontSize: 18,
          }}
        />
      ),
      value: ({ location }: ICohort) => location,
      editComponent: (cohortIndex: number) => (
        <input
          id={cohortIndex + 'location'}
          className="px-3 py-2 border rounded-lg border-mydra-black"
          {...register(`cohorts.${cohortIndex}.location`)}
        />
      ),
    },
    {
      label: 'Format',
      fieldName: 'format',
      icon: (
        <FormatListBulletedOutlined
          sx={{
            fontSize: 18,
          }}
        />
      ),
      value: ({ format }: ICohort) => format,
      editComponent: (cohortIndex: number) => (
        <select
          id={cohortIndex + 'format'}
          className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black"
          {...register(`cohorts.${cohortIndex}.format`)}
        >
          {DEFAULT_COURSE_FORMATS.map(format => (
            <option key={format} value={format}>
              {format}
            </option>
          ))}
        </select>
      ),
    },
    {
      label: 'Class type',
      fieldName: 'classType',
      icon: (
        <ClassOutlined
          sx={{
            fontSize: 18,
          }}
        />
      ),
      value: ({ classType }: ICohort) => classType ?? '',
      editComponent: (cohortIndex: number) => (
        <select
          id={cohortIndex + 'classType'}
          className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black"
          {...register(`cohorts.${cohortIndex}.classType`)}
        >
          {DEFAULT_COURSE_CLASS_TYPES.map(classType => (
            <option key={classType} value={classType}>
              {classType}
            </option>
          ))}
        </select>
      ),
    },
    {
      label: 'Cost',
      fieldName: 'cost',
      icon: (
        <MonetizationOnOutlined
          sx={{
            fontSize: 18,
          }}
        />
      ),
      value: ({ cost }: ICohort) =>
        cost && cost.amount !== undefined
          ? cost.amount
            ? `${formatCurrencyValue(cost.amount.toString(), cost.currency, {
                style: 'decimal',
                maximumFractionDigits: 2,
              })} ${cost.currency}`
            : 'free'
          : '(no cost defined)',
      editComponent: (cohortIndex: number) => {
        return (
          <Controller
            control={control}
            name={`cohorts.${cohortIndex}.cost.amount`}
            render={({ field: { value, onChange, ...field } }) => {
              const costValue = watch(`cohorts.${cohortIndex}.cost`);
              const cohortHasNoCost = !Number.isInteger(costValue?.amount);

              return (
                <>
                  <NumericFormat
                    {...field}
                    id={cohortIndex + 'cost'}
                    className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black disabled:cursor-not-allowed disabled:bg-mydra-gray disabled:border-mydra-medium-gray"
                    value={integerToMoney(value?.toString() ?? '0')}
                    onChange={e => {
                      onChange(moneyToInteger(e.target.value));
                    }}
                    suffix=" EUR"
                    decimalScale={2}
                    allowNegative={false}
                    min={0}
                    disabled={cohortHasNoCost}
                  />
                  <div className="flex items-center gap-2">
                    <input
                      id={`cohorts-${cohortIndex}-disableCost`}
                      type="checkbox"
                      onChange={e => handleFieldClear('cost')(cohortIndex, e)}
                      checked={cohortHasNoCost}
                    />
                    <label
                      htmlFor={`cohorts-${cohortIndex}-disableCost`}
                      className="text-xs"
                    >
                      Cohort has no cost
                    </label>
                  </div>
                </>
              );
            }}
          />
        );
      },
    },
    {
      label: 'Start date',
      fieldName: 'startDate',
      icon: (
        <DateRangeOutlined
          sx={{
            fontSize: 18,
          }}
        />
      ),
      value: ({ startDate }: ICohort) => {
        const parsedDate = startDate && new Date(startDate);
        return parsedDate && isValid(parsedDate)
          ? format(parsedDate, 'MMM d, y')
          : 'Not set';
      },
      editComponent: (cohortIndex: number) => (
        <Controller
          control={control}
          name={`cohorts.${cohortIndex}.startDate`}
          render={({ field: { onChange, value, ...field } }) => (
            <>
              <div className="flex gap-2">
                <input
                  {...field}
                  id={cohortIndex + 'startDate'}
                  className="px-3 py-2 border rounded-lg border-mydra-black"
                  value={
                    value && isValid(new Date(value)) ? value.split('T')[0] : ''
                  }
                  onChange={e => {
                    if (!e.target.value) {
                      onChange(null);
                    }

                    const dateValue = e.target.value + 'T00:00:00.000Z'; // always store date in UTC
                    const calculatedStartDate = new Date(
                      dateValue,
                    ).toISOString();
                    onChange(calculatedStartDate);
                  }}
                  type="date"
                />
              </div>
              <div className="flex items-center gap-1 text-xxs">
                <InfoOutlined
                  sx={{
                    fontSize: '16px',
                  }}
                />
                <p className="text-xxs">UTC timezone</p>
              </div>
            </>
          )}
        />
      ),
    },
    {
      label: 'End date',
      fieldName: 'endDate',
      icon: (
        <DateRangeOutlined
          sx={{
            fontSize: 18,
          }}
        />
      ),
      value: ({ endDate }: ICohort) =>
        endDate && isValid(new Date(endDate))
          ? format(new Date(endDate), 'MMM d, y')
          : 'Not set',
      editComponent: (cohortIndex: number) => (
        <Controller
          control={control}
          name={`cohorts.${cohortIndex}.endDate`}
          render={({ field: { onChange, value, ...field } }) => (
            <>
              <div className="flex gap-2">
                <input
                  {...field}
                  id={cohortIndex + 'endDate'}
                  className="px-3 py-2 border rounded-lg border-mydra-black"
                  value={
                    value && isValid(new Date(value)) ? value.split('T')[0] : ''
                  }
                  onChange={e => {
                    if (!e.target.value) {
                      onChange(null);
                    }

                    const dateValue = e.target.value + 'T00:00:00.000Z'; // always store date in UTC
                    const calculatedEndDate = new Date(dateValue).toISOString();
                    onChange(calculatedEndDate);
                  }}
                  type="date"
                />
              </div>
              <div className="flex items-start gap-1 text-xxs">
                <InfoOutlined
                  sx={{
                    fontSize: '16px',
                  }}
                />
                <p className="text-xxs">UTC timezone</p>
              </div>
            </>
          )}
        />
      ),
    },
    {
      label: 'Duration',
      fieldName: 'duration',
      icon: (
        <LockClockOutlined
          sx={{
            fontSize: 18,
          }}
        />
      ),
      value: ({ duration }: ICohort) =>
        duration && duration.quantity && duration.type
          ? pluralize(duration.type, duration.quantity, true)
          : null,
      editComponent: (cohortIndex: number) => {
        const isDurationDisabled =
          watch(`cohorts.${cohortIndex}.duration`) === null;

        return (
          <div className="flex flex-col gap-2">
            {formErrors.cohorts?.[cohortIndex]?.duration && (
              <p className="mt-1 text-red-500">
                {formErrors.cohorts?.[cohortIndex]?.duration?.quantity?.message}
              </p>
            )}
            <div className="flex gap-6">
              <input
                type="number"
                disabled={isDurationDisabled}
                min={0}
                className="px-3 py-2 border rounded-lg border-mydra-black disabled:cursor-not-allowed disabled:bg-mydra-gray disabled:border-mydra-medium-gray"
                {...(!isDurationDisabled &&
                  register(`cohorts.${cohortIndex}.duration.quantity`, {
                    disabled: isDurationDisabled,
                  }))}
              />
              <select
                disabled={isDurationDisabled}
                className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black disabled:cursor-not-allowed disabled:bg-mydra-gray disabled:border-mydra-medium-gray"
                {...(!isDurationDisabled &&
                  register(`cohorts.${cohortIndex}.duration.type`, {
                    disabled: isDurationDisabled,
                  }))}
              >
                {Object.values(DurationType).map(durationType => (
                  <option key={durationType} value={durationType}>
                    {durationType}
                  </option>
                ))}
                disabled={!cohortFields[cohortIndex].duration}
              </select>
            </div>
            <div className="flex items-center gap-2">
              <input
                id={`cohorts-${cohortIndex}-disableDuration`}
                type="checkbox"
                onChange={e => handleFieldClear('duration')(cohortIndex, e)}
                checked={isDurationDisabled}
              />
              <label
                htmlFor={`cohorts-${cohortIndex}-disableDuration`}
                className="text-xs"
              >
                Cohort has no duration
              </label>
            </div>
          </div>
        );
      },
    },
  ];

  const hasError = !!errors.cohorts;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      sectionTitle="Cohorts"
      isLoading={isSubmitting}
      sectionName="course-cohorts"
      onSave={handleSubmit(onSubmit)}
      onCancel={() =>
        reset({
          cohorts,
        })
      }
      defaultSection={
        <div className="relative flex flex-col gap-4">
          {(cohorts ?? []).length ? (
            <>
              {(cohorts ?? []).map((cohort, index) => (
                <div
                  key={cohort?._id ?? `cohort-${index}`}
                  id={`cohort-${cohort._id}`}
                  className="grid grid-cols-4 gap-6 target:border-dashed target:border target:border-red-500 target:p-4"
                >
                  {FIELDS_INFO.map(({ label, icon, value }) => (
                    <CourseItem
                      key={label}
                      label={label}
                      icon={icon}
                      className="capitalize"
                    >
                      {value(cohort) ?? '(not set)'}
                    </CourseItem>
                  ))}
                </div>
              ))}
            </>
          ) : (
            <p className="text-text">No cohorts available.</p>
          )}
        </div>
      }
      editingSection={
        <form
          action="/"
          method="post"
          onSubmit={e => handleSubmit(onSubmit)(e)}
          className="flex flex-col gap-6"
        >
          {errors.cohorts?.message && (
            <p className="mt-1 text-red-500">{errors.cohorts.message}</p>
          )}
          <button
            className="absolute top-0 right-0 px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
            onClick={handleCohortAdd}
          >
            <div className="flex items-center">
              <Add sx={{ fontSize: 18 }} /> Add cohort
            </div>
          </button>
          <div className="flex flex-col gap-12">
            {cohortFields.map((cohortField, index) => (
              <div
                key={cohortField.id}
                id={`cohort-${cohortField.id}`}
                className="flex flex-col w-full gap-4 target:border target:border-dashed target:border-red-500 target:p-4"
              >
                <div className="grid grid-cols-3 gap-6">
                  {FIELDS_INFO.map(
                    ({ label, icon, editComponent, fieldName }) => (
                      <CourseItem
                        hasInput
                        key={cohortField.id + fieldName}
                        label={label}
                        icon={icon}
                      >
                        {editComponent(index)}

                        {
                          // @ts-expect-error FIXME: fix types
                          formErrors.cohorts?.[index]?.[fieldName]?.message && (
                            <p className="mt-1 text-red-500">
                              {
                                // @ts-expect-error FIXME: fix types
                                formErrors.cohorts?.[index]?.[fieldName]
                                  ?.message
                              }
                            </p>
                          )
                        }
                      </CourseItem>
                    ),
                  )}
                </div>
                <button
                  className="self-start px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
                  onClick={() => removeCohortField(index)}
                >
                  <div className="flex items-center gap-2">
                    <DeleteOutline sx={{ fontSize: 18 }} /> Delete
                  </div>
                </button>
              </div>
            ))}
          </div>
        </form>
      }
    />
  );
}
