import { ApiPaginationParams, ApiQueryParams } from '@/types/api';
import { ICompanyPartialUpdatePayload } from '@/types/company';
import {
  ApiIndividualEmployee,
  ApiIndividualEmployer,
  ApiPaginatedCompanies,
  ApiPaginatedEmployees,
  IEmployee,
  IEmployersFilters,
} from '@/types/employer';
import qs from 'query-string';
import { ApiService } from './api';

export class EmployerService extends ApiService {
  static PATH = '/companies';

  static async getEmployer(id: string): Promise<ApiIndividualEmployer> {
    return ApiService.get(`${this.PATH}/${id}`);
  }

  static async patchEmployer(
    id: string,
    update: ICompanyPartialUpdatePayload,
  ): Promise<ApiIndividualEmployer> {
    return ApiService.patch(`${this.PATH}/${id}`, update);
  }

  static async getEmployees(
    employerId: string,
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedEmployees> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${this.PATH}/${employerId}/users?${queryString}`);
  }

  static async patchEmployee(
    employerId: string,
    employeeId: string,
    data: Partial<IEmployee>,
  ): Promise<ApiIndividualEmployee> {
    return ApiService.patch(
      `${this.PATH}/${employerId}/users/${employeeId}`,
      data,
    );
  }

  static async getEmployee(
    employerId: string,
    employeeId: string,
  ): Promise<ApiIndividualEmployee> {
    return ApiService.get(`${this.PATH}/${employerId}/users/${employeeId}`);
  }

  static async getEmployers(
    filters: IEmployersFilters,
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedCompanies> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${this.PATH}?${queryString}`);
  }

  static async inviteEmployees(
    employerId: string,
    file: FormData,
  ): Promise<void> {
    return ApiService.post(`${this.PATH}/${employerId}/users/invite`, file);
  }
}
