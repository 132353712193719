export const StripeConnectIconLogo = () => {
  return (
    <svg
      className="ProductIcon ProductIcon--Connect ProductNav__icon"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Connect</title>

      <path
        d="M12.47.01a13.01 13.01 0 0 0 .5 25.99h10.55c1.37 0 2.48-1.1 2.48-2.48V13.01a12.99 12.99 0 0 0-13.53-13z"
        fill="url(#product-icon-connect-ProductNav-a)"
      ></path>
      <path
        d="M27.53 39.99a13.01 13.01 0 0 0-.5-25.99H16.48A2.48 2.48 0 0 0 14 16.48v10.51a12.99 12.99 0 0 0 13.53 13z"
        fill="#0073E6"
      ></path>
      <path
        d="M26 14v9.52A2.48 2.48 0 0 1 23.52 26H14v-9.52A2.48 2.48 0 0 1 16.32 14l.16-.01H26z"
        fill="url(#product-icon-connect-ProductNav-b)"
      ></path>
      <defs>
        <linearGradient
          id="product-icon-connect-ProductNav-a"
          x1="13"
          y1="1.71"
          x2="13"
          y2="15.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11EFE3"></stop>
          <stop offset=".33" stopColor="#15E8E2"></stop>
          <stop offset=".74" stopColor="#1FD3E0"></stop>
          <stop offset="1" stopColor="#21CFE0"></stop>
        </linearGradient>
        <linearGradient
          id="product-icon-connect-ProductNav-b"
          x1="20"
          y1="15.72"
          x2="20"
          y2="27.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00299C"></stop>
          <stop offset="1" stopColor="#0073E6"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
