import { DEFAULT_COURSE_LEVELS } from '@/constants';
import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Category,
  InfoOutlined,
  LeaderboardOutlined,
  SchoolOutlined,
  Translate,
} from '@mui/icons-material';
import { getLangNameFromCode } from 'language-name-map';
import { useForm, useFormContext } from 'react-hook-form';
import { CourseInformationSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';
import { CourseItem } from '../CourseItem';

type FormValues = Pick<
  ICourse,
  'type' | 'language' | 'level' | 'hasCertificate' | '_id'
>;

type CourseInformationProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseInformation({
  onSubmit,
  ...props
}: CourseInformationProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [type, language, level, hasCertificate] = getValues([
    'type',
    'language',
    'level',
    'hasCertificate',
  ]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      type: type ?? '',
      language: language ?? 'en',
      level: level ?? '',
      hasCertificate: !!hasCertificate,
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CourseInformationSchema),
  });

  const hasError = !!(
    errors.type ||
    errors.language ||
    errors.level ||
    errors.hasCertificate
  );

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      className={`${props.className}`}
      sectionName="course-info"
      sectionTitle="Course information"
      defaultSection={
        <div className="flex flex-col gap-4">
          <CourseItem label="Type" icon={<Category />}>
            <p className="capitalize">{type}</p>
          </CourseItem>
          <CourseItem label="Language" icon={<Translate />}>
            <p>
              {language
                ? getLangNameFromCode(language)?.name ?? '(unknown)'
                : '(not set)'}
            </p>
          </CourseItem>
          <CourseItem label="Level" icon={<LeaderboardOutlined />}>
            <p className="capitalize">{level ?? '(not set)'}</p>
          </CourseItem>
          <CourseItem label="Has certification?" icon={<SchoolOutlined />}>
            <p>{hasCertificate ? 'Yes' : 'No'}</p>
          </CourseItem>
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-4">
          <CourseItem hasInput label="Type" icon={<Category />}>
            <select
              {...register('type')}
              className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black"
            >
              <option value="course">Course</option>
            </select>
          </CourseItem>
          <CourseItem hasInput label="Language" icon={<Translate />}>
            <select
              {...register('language')}
              className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black"
            >
              <option key="en" value="en">
                English
              </option>
            </select>
            {formErrors?.language?.message && (
              <p className="mt-1 text-red-500">{formErrors.language.message}</p>
            )}
            <div className="flex gap-1 text-xs">
              <InfoOutlined
                sx={{
                  fontSize: 14,
                }}
              />
              Multi-language support is coming soon
            </div>
          </CourseItem>
          <CourseItem hasInput label="Level" icon={<LeaderboardOutlined />}>
            <select
              {...register('level')}
              className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black"
            >
              {DEFAULT_COURSE_LEVELS.map(level => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
            {formErrors?.level?.message && (
              <p className="mt-1 text-red-500">{formErrors.level.message}</p>
            )}
          </CourseItem>
          <CourseItem
            hasInput
            label="Has certification?"
            icon={<LeaderboardOutlined />}
          >
            <div className="flex gap-2">
              <input
                {...register('hasCertificate')}
                type="checkbox"
                className="p-4 border rounded-xl"
                placeholder="Title"
              />
              Yes
            </div>
            {formErrors?.hasCertificate?.message && (
              <p className="mt-1 text-red-500">
                {formErrors.hasCertificate.message}
              </p>
            )}
          </CourseItem>
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() =>
        reset({
          type,
          language,
          level,
          hasCertificate,
        })
      }
    />
  );
}
