export function MydraLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={97}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#000"
        d="M41.068 5.719c-1.03 0-2.872.293-3.744 2.236-.662-1.525-1.814-2.236-3.616-2.236-1.672 0-2.951.808-3.607 2.277h-.71l.801-1.892h-2.677v11.978h2.644v-6.195c0-2.912 1.401-3.525 2.576-3.525 1.5 0 2.293.846 2.293 2.448v7.272h2.644v-6.22c0-2.29.9-3.5 2.601-3.5 1.484 0 2.268.846 2.268 2.448v7.272h2.644v-7.4c0-3.154-1.5-4.963-4.117-4.963ZM55.418 6.104l-3.025 7.91h-.807l-3.026-7.91h-2.934l3.998 10.823h2.16l-.741 2.274c-.328 1.052-.878 1.543-1.732 1.543s-1.294-.463-1.294-1.268v-.323H45.45v.323c0 2.209 1.444 3.527 3.86 3.527 2.195 0 3.394-1.11 4.422-4.096l4.623-12.806H55.42l-.002.003ZM67.993 7.909c-.87-1.415-2.28-2.191-3.986-2.191-1.588 0-3.045.601-4.105 1.693-1.132 1.166-1.73 2.784-1.73 4.68 0 1.897.598 3.516 1.73 4.682 1.06 1.091 2.517 1.692 4.105 1.692 1.782 0 3.157-.705 3.986-2.042v1.658h2.645V0h-2.645v7.909Zm.128 4.183c0 2.261-1.437 3.78-3.576 3.78-2.138 0-3.576-1.519-3.576-3.78 0-2.262 1.438-3.782 3.576-3.782 2.139 0 3.576 1.52 3.576 3.782ZM75.047 8.513v-2.41h-2.644V18.08h2.644v-5.322c0-2.685 1.178-3.935 3.704-3.935h.45v-3.08h-.244c-1.849 0-3.262 1.005-3.91 2.77ZM89.436 6.104V7.76c-.83-1.336-2.205-2.042-3.986-2.042-1.589 0-3.046.601-4.106 1.693-1.131 1.166-1.73 2.785-1.73 4.68 0 1.897.599 3.516 1.73 4.682 1.06 1.091 2.517 1.693 4.106 1.693 1.82 0 3.215-.737 4.038-2.129h.71l-.799 1.744h2.681V6.104h-2.644Zm.127 5.989c0 2.261-1.437 3.78-3.575 3.78-2.139 0-3.576-1.519-3.576-3.78s1.437-3.782 3.576-3.782c2.138 0 3.575 1.52 3.575 3.782Z"
      />
      <path
        fill="#895AD7"
        d="m12.403 17.79.795-.99c2.99 1.825 6.701 1.49 8.954-1.314 2.448-3.046 1.48-6.902-1.566-9.35L13.22.223 9.446 4.922l1.68.58-.78.974C7.354 4.652 3.633 4.998 1.38 7.803c-2.447 3.046-1.47 6.89 1.576 9.337l7.321 5.88 3.765-4.688-1.641-.542h.001ZM8.345 8.887a4.394 4.394 0 0 1 6.851 5.502 4.394 4.394 0 0 1-6.85-5.502Z"
      />
    </svg>
  );
}
