import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { DropDown } from '@/modules/common/components/DropDown';
import { MenuItem } from '@/modules/common/components/MenuItem';
import { TableFooter } from '@/modules/common/components/TableFooter';
import { formatSlug } from '@/modules/common/lib/formatters';
import { IPayment, PaymentStatus } from '@/types/payment';
import { UserRole } from '@/types/user';
import { CancelPresentation } from '@mui/icons-material';
import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { getListPaymentsTableColumns } from './ListHistoryPayments.constants';

type ListHistoryPayments = {
  payments: IPayment[];
  paginationState: PaginationState;
  rowCount: number;
  isLoading?: boolean;
  onPaginationChange: OnChangeFn<PaginationState>;
  onFilterChange: (status?: string) => void;
  searchState: Record<string, unknown>;
} & React.TableHTMLAttributes<HTMLTableElement>;

const statusFilterOptions = Object.values(PaymentStatus).map(status => ({
  label: formatSlug(status),
  value: status,
}));

const statusLabel = (status?: string) => (status ? formatSlug(status) : 'All');

const showClearFilters = (filters: Record<string, unknown>) => {
  return !!Object.entries(filters).filter(
    ([key]) => key === 'status' && !!filters[key],
  ).length;
};

export function ListHistoryPayments({
  payments,
  isLoading,
  paginationState,
  rowCount,
  onPaginationChange,
  searchState,
  onFilterChange,
  ...props
}: ListHistoryPayments) {
  const { currentUser: { roles: userRoles = [] } = {} } =
    useCurrentUserContext() ?? {};
  const columns = getListPaymentsTableColumns(userRoles as UserRole[]);

  const table = useReactTable<IPayment>({
    data: payments,
    columns,
    state: {
      pagination: paginationState,
    },
    onPaginationChange,
    rowCount: rowCount,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel<IPayment>(),
  });

  const rows = table.getRowModel().rows;

  const hasNoPayments = rows.length === 0 && !isLoading;

  return (
    <>
      <div className="py-5">
        <h2 className="text-2xl font-medium">Billing History</h2>
      </div>
      <div className="flex gap-12 py-6">
        <div className="flex items-center">
          <p className="text-xs font-medium">Status:</p>
          <DropDown
            toggleButtonLabel={statusLabel(searchState.status as string)}
            slotProps={{
              button: {
                className: 'text-xs font-medium',
              },
            }}
          >
            {statusFilterOptions.map(option => (
              <MenuItem
                key={option.value}
                className="text-xs"
                onClick={() => {
                  onFilterChange(option.value);
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </DropDown>
        </div>
        {showClearFilters(searchState) && (
          <div
            className="content-center text-xs font-medium cursor-pointer text-mydra-purple"
            onClick={() => onFilterChange()}
          >
            Clear
          </div>
        )}
      </div>
      <table
        {...props}
        className={`table-auto border-collapse ${props.className ?? ''} ${hasNoPayments ? 'h-full' : ''}`}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id} className="relative">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="p-3 first:px-0 last:px-0"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {hasNoPayments ? (
            <tr>
              <td
                colSpan={table.getVisibleFlatColumns().length}
                className="p-0 h-full text-center"
              >
                <div className="flex flex-col justify-center p-3 h-full align-center bg-mydra-gray text-mydra-black">
                  <div className="block">
                    <CancelPresentation
                      className="text-mydra-purple"
                      style={{
                        width: 68,
                        height: 62,
                      }}
                    />
                    <p className="mt-8 text-2xl font-medium">{`No payments found`}</p>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            rows.map(row => (
              <tr
                key={row.id}
                className="py-1 border-b hover:bg-gray-background"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-3 group first-of-type:px-0 last-of-type:px-0"
                    >
                      <div className="flex">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
        <TableFooter tableInstance={table}></TableFooter>
      </table>
    </>
  );
}
