import { TaxBenefitsProviderService } from '@/services/tax-benefit-providers';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactQueryOptions,
} from '@/types/api';
import { ApiPaginatedTaxBenefitProviders } from '@/types/tax-benefits-provider';
import { useQuery } from '@tanstack/react-query';

export const getTaxBenefitProvidersQueryOptions = (
  filters?: ApiQueryParams,
  pagination?: ApiPaginationParams,
  options?: ApiReactQueryOptions<ApiPaginatedTaxBenefitProviders>,
) => ({
  queryKey: [TaxBenefitsProviderService.PATH, filters, pagination],
  queryFn: async () =>
    await TaxBenefitsProviderService.getTaxBenefitsProviders(
      filters,
      pagination,
    ),
  ...options,
});

export const useGetTaxBenefitProviders = (
  filters: ApiQueryParams = {},
  pagination: ApiPaginationParams = {},
  options?: ApiReactQueryOptions<ApiPaginatedTaxBenefitProviders>,
) => {
  return useQuery<ApiPaginatedTaxBenefitProviders, ApiErrorResponse>(
    getTaxBenefitProvidersQueryOptions(filters, pagination, options),
  );
};
