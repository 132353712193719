import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { TextField } from '@/modules/common/components/TextField';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ApiErrorResponse } from '@/types/api';
import { AuthConfirmResetPayload } from '@/types/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalProps } from '@mui/base';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useUserPasswordReset } from '../hooks/useAuth';

export type ResetPasswordDialogForValues = AuthConfirmResetPayload;

const FORM_SCHEMA = yup.object().shape({
  token: yup.string().required('Token is required'),
  newPassword: yup.string().required('New password is required'),
  newPasswordConfirmation: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

type ResetPasswordDialog = Omit<ModalProps, 'children'> & {
  token?: string;
  onSuccess: (email?: string) => void;
};

export function ResetPasswordDialog({
  onSuccess,
  token,
  ...props
}: ResetPasswordDialog) {
  const { showNotification } = useNotificationContext();
  const [_, email] = atob(token ?? '')?.split(':') ?? [];

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordDialogForValues>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      token: token ?? '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });

  const { mutateAsync: resetUserPassword, isPending } = useUserPasswordReset();

  const handlePasswordResetRequest = async ({
    token,
    newPassword,
    newPasswordConfirmation,
  }: ResetPasswordDialogForValues) => {
    try {
      await resetUserPassword({
        token,
        newPassword,
        newPasswordConfirmation,
      });
      onSuccess(email);
      showNotification(
        'Password reset successfully. You can now log in.',
        NotificationStyle.SUCCESS,
      );
    } catch (error) {
      if ((error as ApiErrorResponse).statusCode === 400) {
        return setError('root.serverError', {
          type: 'manual',
          message:
            (error as ApiErrorResponse).message ??
            'An error occurred. Please try again later.',
        });
      }
      showNotification(
        'An error occurred. Please try again later.',
        NotificationStyle.ERROR,
      );
    }
  };

  return (
    <Modal
      {...props}
      title={`Set your new password`}
      subTitle="Enter a new password and confirm"
      onClose={(...args) => {
        reset();
        props.onClose?.(...args);
      }}
      className="w-96"
    >
      <form
        onSubmit={handleSubmit(handlePasswordResetRequest)}
        className="flex flex-col justify-end gap-3"
      >
        {errors.root?.serverError?.message && (
          <ul className="text-red-500 list-disc">
            {errors.root.serverError.message?.split(';').map((error, index) => (
              <li key={index}>
                {error}
                <br />
              </li>
            ))}
          </ul>
        )}
        <div className="flex flex-col gap-6">
          <div>
            <Controller
              control={control}
              name="newPassword"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  placeholder="New password"
                />
              )}
            />
            {errors.newPassword?.message && (
              <p className="text-sm text-red-500">
                {errors.newPassword.message}
              </p>
            )}
          </div>
          <div>
            <input type="hidden" value={token} />
            <Controller
              control={control}
              name="newPasswordConfirmation"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  placeholder="Confirm password"
                />
              )}
            />
            {errors.newPasswordConfirmation?.message && (
              <p className="text-sm text-red-500">
                {errors.newPasswordConfirmation.message}
              </p>
            )}
          </div>
        </div>
        <Button
          primary
          type="submit"
          className="w-full mt-6"
          isLoading={isPending}
        >
          Change password
        </Button>
      </form>
    </Modal>
  );
}
