import { Snackbar, SnackbarCloseReason } from '@mui/base';
import { CheckCircle, Close, Error, Info, Report } from '@mui/icons-material';
import { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useNotificationContext } from '../../hooks/useNotificationContext';
import { NotificationStyle } from '../../providers/NotificationProvider.types';

export function Notification() {
  const [exited, setExited] = useState(true);
  const nodeRef = useRef(null);

  const { notification, clearNotification, isOpen } = useNotificationContext();

  const handleClose = (_: unknown, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    clearNotification();
  };

  const handleOnEnter = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
  };

  return (
    <Snackbar
      autoHideDuration={5000}
      open={isOpen}
      onClose={handleClose}
      exited={exited}
      slotProps={{
        root: {
          className: 'fixed top-20 right-8 z-[1000001]',
        },
      }}
    >
      <Transition
        timeout={{ enter: 400, exit: 400 }}
        in={isOpen}
        appear
        unmountOnExit
        onEnter={handleOnEnter}
        onExited={handleOnExited}
        nodeRef={nodeRef}
      >
        {status => (
          <div
            className="flex items-center overflow-hidden rounded-lg shadow-notification"
            style={{
              transform: positioningStyles[status],
              transition: 'transform 300ms ease',
            }}
            ref={nodeRef}
          >
            <div
              className="self-stretch w-2"
              style={{
                backgroundColor: notificationColors[notification.style],
              }}
            />
            <div className="flex flex-1 gap-2 px-4 py-3 bg-white">
              {notificationIcons[notification.style]}
              <div className="snackbar-title">{notification.message}</div>
              <Close
                onClick={handleClose}
                className="cursor-pointer text-text"
              />
            </div>
          </div>
        )}
      </Transition>
    </Snackbar>
  );
}

const positioningStyles = {
  entering: 'translateX(0)',
  entered: 'translateX(0)',
  exiting: 'translateX(500px)',
  exited: 'translateX(500px)',
  unmounted: 'translateX(500px)',
};

const notificationColors = {
  [NotificationStyle.ERROR]: '#D20819',
  [NotificationStyle.INFO]: '#1E40AF',
  [NotificationStyle.SUCCESS]: '#059669',
  [NotificationStyle.WARNING]: '#D97706',
};

const notificationIcons = {
  [NotificationStyle.ERROR]: (
    <Error
      style={{
        color: notificationColors[NotificationStyle.ERROR],
      }}
    />
  ),
  [NotificationStyle.INFO]: (
    <Info
      style={{
        color: notificationColors[NotificationStyle.INFO],
      }}
    />
  ),
  [NotificationStyle.SUCCESS]: (
    <CheckCircle
      style={{
        color: notificationColors[NotificationStyle.SUCCESS],
      }}
    />
  ),
  [NotificationStyle.WARNING]: (
    <Report
      style={{
        color: notificationColors[NotificationStyle.WARNING],
      }}
    />
  ),
};
