import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { ICompany } from './company';
import { ObjectWithSoftDeletion, ObjectWithTimestamps } from './objects';
import { IPartner } from './partner';
import { IUser } from './user';

export interface ITaxBenefitsProvider extends IPartner, ObjectWithSoftDeletion {
  companies: string[];
  logoUrl?: string;
}

export interface ITaxBenefitsProviderWithCompanies
  extends Omit<ITaxBenefitsProvider, 'companies'> {
  companies: ICompany[];
}

export interface ITaxBenefitsProviderWithAdmins
  extends Omit<ITaxBenefitsProvider, 'adminUsers'> {
  adminUsers: IUser[];
}

export interface ITaxBenefitsProviderCreatePayload
  extends Omit<
    ITaxBenefitsProvider,
    | '_id'
    | 'companies'
    | 'adminUsers'
    | 'createdAt'
    | 'updatedAt'
    | 'verified'
    | 'deletedAt'
    | 'companies'
  > {
  adminUsers?: string[];
}

export interface ITaxBenefitsProviderFullUpdatePayload
  extends Omit<
    ITaxBenefitsProvider,
    | '_id'
    | 'companies'
    | 'adminUsers'
    | 'createdAt'
    | 'updatedAt'
    | 'verified'
    | 'deletedAt'
    | 'companies'
  > {
  adminUsers: string[];
}

export interface ITaxBenefitsProviderPartialUpdatePayload
  extends Partial<ITaxBenefitsProviderFullUpdatePayload> {}

export interface ITaxBenefitsProviderCompanyLink
  extends Required<ObjectWithTimestamps> {
  _id: string;
  company: string;
  taxBenefitsProvider: string;
  status: TaxBenefitsProviderCompanyLinkStatus;
}

export interface ITaxBenefitsProviderCompanyLinkWithCompany
  extends Omit<
    ITaxBenefitsProviderCompanyLink,
    'company' | 'taxBenefitsProvider'
  > {
  company: ICompany;
  taxBenefitsProvider: ITaxBenefitsProvider;
}

export interface ITaxBenefitsProviderCompanyLinkCreatePayload
  extends Omit<
    ITaxBenefitsProviderCompanyLink,
    '_id' | 'createdAt' | 'updatedAt' | 'status'
  > {}

export interface ITaxBenefitsProviderCompanyLinkUpdatePayload
  extends Omit<
    ITaxBenefitsProviderCompanyLink,
    '_id' | 'createdAt' | 'updatedAt' | 'company' | 'taxBenefitsProvider'
  > {}

export interface ITaxBenefitsProviderWithLinkStatus
  extends ITaxBenefitsProvider {
  status: TaxBenefitsProviderCompanyLinkStatus;
}

export enum TaxBenefitsProviderCompanyLinkStatus {
  Invited = 'INVITED', // Default state
  Canceled = 'CANCELED', // Company canceled the partnership invitation
  Accepted = 'ACCEPTED', // Tax Benefits provider accepted the invitation
  Rejected = 'REJECTED', // Tax Benefits provider rejected the invitation
  Terminated = 'TERMINATED', // Company terminated the partnership
}

export const TaxBenefitsProviderCompanyLinkActiveStatuses = [
  TaxBenefitsProviderCompanyLinkStatus.Invited,
  TaxBenefitsProviderCompanyLinkStatus.Accepted,
];

export type ApiIndividualTaxBenefitsProvider =
  ApiIndividualResponse<ITaxBenefitsProvider>;
export type ApiPaginatedTaxBenefitProviders =
  ApiPaginatedResponse<ITaxBenefitsProvider>;

export type ApiPaginatedTaxBenefitProviderLink =
  ApiIndividualResponse<ITaxBenefitsProviderCompanyLink>;
export type ApiPaginatedTaxBenefitProviderLinks =
  ApiPaginatedResponse<ITaxBenefitsProviderCompanyLink>;
