import { DropDown } from '@/modules/common/components/DropDown';
import { MenuItem } from '@/modules/common/components/MenuItem';
import { IInvitation } from '@/types/invitation';
import { ITeam } from '@/types/team';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { format, isValid } from 'date-fns';

type ListInvitations = {
  invitations: IInvitation[];
  isLoading?: boolean;
} & React.TableHTMLAttributes<HTMLUListElement>;

export function ListInvitations({
  invitations,
  isLoading,
  ...props
}: ListInvitations) {
  const hasNoInvitations = invitations.length === 0 && !isLoading;

  return (
    <>
      <div className="flex items-center pb-4">
        <p className="text-xs font-medium">View:</p>
        <DropDown
          toggleButtonLabel={'All'}
          slotProps={{
            button: {
              className: 'text-xs font-medium',
            },
          }}
        >
          <MenuItem key={'All'} className="text-xs">
            {'All'}
          </MenuItem>
        </DropDown>
      </div>

      <ul {...props}>
        <li>
          <div className="flex justify-between text-sm font-semibold p-4">
            <div className="flex flex-col mr-2 w-2/5">Email</div>
            <div className="flex flex-col items-center w-1/5">Role</div>
            <div className="flex flex-col items-center w-1/5">Team</div>
            <div className="flex items-center justify-end w-1/5">
              Expires At
            </div>
          </div>
        </li>
        {hasNoInvitations ? (
          <li>
            <div className="flex flex-col bg-mydra-gray text-mydra-black h-full p-3 items-center justify-center rounded">
              <PersonAddIcon
                className="text-mydra-purple mt-10"
                style={{
                  width: 68,
                  height: 62,
                }}
              />
              <p className="mt-2 text-xl font-medium">{`Invite an Employee`}</p>
              <p className="mb-10">{`Your created invitations will be displayed here once set up.`}</p>
            </div>
          </li>
        ) : (
          invitations.map(invitation => {
            return (
              <li key={invitation._id} className="mb-2 text-sm">
                <div className="flex justify-between rounded border p-4">
                  <div className="flex items-center mr-2 w-2/5">
                    {invitation.email}
                  </div>
                  <div className="flex items-center justify-center w-1/5">
                    {invitation.role}
                  </div>
                  <div className="flex items-center justify-center w-1/5">
                    {invitation.team ? (invitation.team as ITeam).name : 'None'}
                  </div>
                  <span className="flex items-center justify-end w-1/5">
                    {isValid(new Date(invitation.expiresAt))
                      ? format(invitation.expiresAt, 'P')
                      : 'None'}
                  </span>
                </div>
              </li>
            );
          })
        )}
      </ul>
    </>
  );
}
