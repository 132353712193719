import { StripeConnectIconLogo } from './StripeConnectIconLogo';

export function StripeLoadingScreen() {
  return (
    <div className="flex justify-center w-full min-h-screen bg-mydra-light-purple">
      <div className="flex flex-col justify-center w-4/12 h-screen md:w-6/12 lg:w-4/12">
        <div className="p-6 bg-white border rounded-lg border-[#E2E3E6]">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center gap-4">
              <StripeConnectIconLogo />
              <h5 className="text-2xl font-bold">Stripe Connect</h5>
            </div>
            <div className="w-full p-6">
              <div className="relative w-full h-1 overflow-hidden rounded-full bg-mydra-light-purple">
                <div className="absolute top-0 left-0 w-1/4 h-full bg-mydra-purple animate-loading"></div>
              </div>
            </div>
            <p className="">Redirecting to Stripe...</p>
          </div>
        </div>
      </div>
    </div>
  );
}
