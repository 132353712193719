import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useUserLogout } from '../hooks/useAuth';

const routeApi = getRouteApi('/logout');

export function LogoutPage() {
  const { mutateAsync: logout } = useUserLogout();
  const navigate = routeApi.useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      try {
        await logout().then(() => queryClient.clear());
      } finally {
        navigate({
          to: '/login',
        });
      }
    })();
  }, [logout, navigate, queryClient]);

  return <div>Logging out...</div>;
}
