import { EmployerService } from '@/services/employer';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactQueryOptions,
} from '@/types/api';
import { ApiIndividualEmployee, ApiPaginatedEmployees } from '@/types/employer';
import { useQuery } from '@tanstack/react-query';

export const getEmployeesQueryOptions = (
  employerId: string,
  filters?: ApiQueryParams,
  pagination?: ApiPaginationParams,
  options?: ApiReactQueryOptions<ApiPaginatedEmployees>,
) => ({
  queryKey: [EmployerService.PATH, filters, pagination],
  queryFn: async () =>
    await EmployerService.getEmployees(employerId, filters, pagination),
  ...options,
});

export const getEmployeeQueryOptions = (
  employerId: string,
  filters: ApiQueryParams,
  options?: ApiReactQueryOptions<ApiIndividualEmployee>,
) => ({
  queryKey: [EmployerService.PATH, filters.employee],
  queryFn: async () =>
    EmployerService.getEmployee(employerId, filters.employee as string),
  ...options,
});

export const useGetEmployees = (
  employerId: string,
  filters: ApiQueryParams = {},
  pagination: ApiPaginationParams = {},
  options?: ApiReactQueryOptions<ApiPaginatedEmployees>,
) => {
  return useQuery<ApiPaginatedEmployees, ApiErrorResponse>(
    getEmployeesQueryOptions(employerId, filters, pagination, options),
  );
};

export const useGetEmployee = (employerId: string, employee: string) => {
  return useQuery<ApiIndividualEmployee, ApiErrorResponse>(
    getEmployeeQueryOptions(employerId, { employee }),
  );
};
