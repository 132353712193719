import { Button } from '@/modules/common/components/Button';

type CourseNavBarActionsProps = {
  onPublish?: () => void;
  onPreview?: () => void;
  onFinishLater?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function CourseNavBarActions({
  onPublish,
  onFinishLater,
  onPreview,
  isLoading,
  disabled,
  ...props
}: CourseNavBarActionsProps) {
  return (
    <div {...props} className="flex gap-11">
      <div className="flex gap-16">
        {!!onPreview && (
          <button
            title="Go back to courses"
            className="text-sm font-medium text-text"
            onClick={onPreview}
            disabled={isLoading || disabled}
          >
            Preview
          </button>
        )}
        {!!onFinishLater && (
          <button
            disabled={isLoading || disabled}
            className="text-sm font-medium text-text"
            onClick={onFinishLater}
          >
            Finish later
          </button>
        )}
      </div>
      {!!onPublish && (
        <Button primary small onClick={onPublish} isLoading={isLoading}>
          Publish
        </Button>
      )}
    </div>
  );
}
