import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Add,
  Adjust,
  Cancel,
  Delete,
  WidgetsOutlined,
} from '@mui/icons-material';
import { useRef } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { CourseEngagementSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';
import { CourseItem } from '../CourseItem';

type FormValues = Pick<
  ICourse,
  'targets' | 'learningAreas' | 'prerequisites' | 'skills'
>;

type CourseEngagementAndOutcomesProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseEngagementAndOutcomes({
  onSubmit,
  ...props
}: CourseEngagementAndOutcomesProps) {
  const {
    getValues: getValuesFromParent,
    formState: { errors },
  } = useFormContext<FormValues>();
  const course = {
    learningAreas: getValuesFromParent('learningAreas'),
    targets: getValuesFromParent('targets'),
    skills: getValuesFromParent('skills'),
    prerequisites: getValuesFromParent('prerequisites'),
  };

  const {
    watch,
    register,
    reset,
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<
    Pick<ICourse, 'targets' | 'skills' | 'learningAreas' | 'prerequisites'>
  >({
    defaultValues: {
      targets: course.targets ?? [],
      skills: course.skills ?? [],
      learningAreas: course.learningAreas ?? [],
      prerequisites: course.prerequisites ?? [],
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CourseEngagementSchema),
  });

  const {
    fields: targetFields,
    append: appendTargetField,
    remove: removeTargetField,
  } = useFieldArray({
    control,
    name: 'targets',
  });

  const skills = watch('skills');
  const learningAreas = watch('learningAreas');
  const prerequisites = watch('prerequisites');

  const handleAddTarget = () => {
    appendTargetField({
      title: '',
      description: '',
    });
  };

  const newSkillInputRef = useRef<HTMLInputElement | null>(null);
  const newLearningAreaInputRef = useRef<HTMLInputElement | null>(null);
  const newPrerequisiteInputRef = useRef<HTMLInputElement | null>(null);

  const handleAddItem =
    (
      collectionName: 'learningAreas' | 'skills' | 'prerequisites',
      inputRef: React.MutableRefObject<HTMLInputElement | null>,
    ) =>
    (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLInputElement>,
    ) => {
      e.preventDefault();

      if (inputRef?.current === null) {
        return;
      }

      const { value = '' } = inputRef.current!;

      const trimmedValue = value.trim();

      if (trimmedValue) {
        setValue(collectionName, [
          ...(getValues(collectionName) ?? []),
          trimmedValue,
        ]);
        inputRef.current!.value = '';
      }
    };

  const hasError =
    !!errors.targets ||
    !!errors.skills ||
    !!errors.learningAreas ||
    !!errors.prerequisites;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      className={`${props.className}`}
      sectionName="course-engagement"
      sectionTitle="Engagement and Outcomes"
      defaultSection={
        <div className="flex flex-col gap-4">
          <CourseItem label="Targets" icon={<Adjust />}>
            {course.targets?.length ? (
              <ul className="flex flex-col gap-2 mt-3">
                {course.targets.map((target, index) => (
                  <li key={target.title + index} className="inline-block">
                    <p className="font-medium">{target.title}</p>
                    <p className="font-medium">{target.description}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>(no targets set)</p>
            )}
          </CourseItem>
          <CourseItem label="Skills" icon={<WidgetsOutlined />}>
            {course.skills?.length ? (
              <ul className="flex flex-wrap gap-2 mt-3">
                {course.skills.map((skill, index) => (
                  <li
                    key={skill + index}
                    className="inline-block px-3 py-2 capitalize border rounded-3xl"
                  >
                    {skill}
                  </li>
                ))}
              </ul>
            ) : (
              <p>(no skills set)</p>
            )}
          </CourseItem>
          <CourseItem label="Learning Areas" icon={<WidgetsOutlined />}>
            {course.learningAreas?.length ? (
              <ul className="flex flex-wrap gap-2 mt-3">
                {course.learningAreas.map((learningArea, index) => (
                  <li
                    key={learningArea + index}
                    className="block px-3 py-2 capitalize border rounded-3xl"
                  >
                    {learningArea}
                  </li>
                ))}
              </ul>
            ) : (
              <p>(no learning areas set)</p>
            )}
          </CourseItem>
          <CourseItem label="Prerequisites" icon={<WidgetsOutlined />}>
            {course.prerequisites?.length ? (
              <ul className="flex flex-col gap-3 mt-3">
                {course.prerequisites.map((prerequisite, index) => (
                  <li
                    key={prerequisite + index}
                    className="block px-3 py-2 capitalize border rounded-3xl"
                  >
                    {prerequisite}
                  </li>
                ))}
              </ul>
            ) : (
              <p>(no prerequisites set)</p>
            )}
          </CourseItem>
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-4">
          <CourseItem hasInput label="Targets" icon={<Adjust />}>
            <div className="flex flex-col gap-4 mt-3">
              {targetFields.map((targetField, index) => (
                <div key={targetField.id} className="flex flex-col gap-3">
                  <div key={targetField.id} className="flex flex-col gap-3">
                    <input
                      {...register(`targets.${index}.title`)}
                      className="w-full p-2 border rounded-xl"
                      placeholder="Design Professionals"
                    />
                    {formErrors.targets?.[index]?.title?.message && (
                      <p className="mt-1 text-red-500">
                        {formErrors.targets?.[index]?.title?.message}
                      </p>
                    )}
                    <div>
                      <TextareaAutosize
                        {...register(`targets.${index}.description`)}
                        className="w-full p-2 border rounded-xl"
                        placeholder="Designed for professionals in the design and digital content creation fields looking to integrate AI tools into their work."
                      />
                    </div>
                    {formErrors.targets?.[index]?.description?.message && (
                      <p className="mt-1 text-red-500">
                        {formErrors.targets?.[index]?.description?.message}
                      </p>
                    )}
                  </div>
                  <div className="flex gap-3">
                    <button
                      className="px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
                      onClick={handleAddTarget}
                    >
                      <div className="flex items-center">
                        <Add sx={{ fontSize: 18 }} /> Add target
                      </div>
                    </button>
                    <button
                      className="px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
                      onClick={() => removeTargetField(index)}
                    >
                      <div className="flex items-center">
                        <Delete sx={{ fontSize: 18 }} /> Delete
                      </div>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </CourseItem>
          {[
            {
              dataSource: skills,
              collection: 'skills',
              label: 'Skills',
              placeholder: 'Figma',
              inputRef: newSkillInputRef,
            },
            {
              dataSource: learningAreas,
              label: 'Learning Areas',
              collection: 'learningAreas',
              placeholder: 'User experience',
              inputRef: newLearningAreaInputRef,
            },
            {
              dataSource: prerequisites,
              label: 'Prerequisites',
              collection: 'prerequisites',
              placeholder: 'A personal computer and stable internet',
              inputRef: newPrerequisiteInputRef,
            },
          ].map(({ dataSource, collection, label, placeholder, inputRef }) => (
            <CourseItem
              key={collection}
              label={label}
              icon={<WidgetsOutlined />}
            >
              <div className="flex flex-col mt-3">
                <div className="flex flex-wrap gap-2">
                  {(dataSource ?? []).map((itemName, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-1 px-2 font-medium border cursor-pointer rounded-2xl"
                      onClickCapture={() => {
                        setValue(
                          collection as
                            | 'learningAreas'
                            | 'skills'
                            | 'prerequisites',
                          (dataSource ?? []).filter((_, i) => i !== index),
                        );
                      }}
                    >
                      <Cancel className="text-mydra-purple" />

                      <span
                        onClickCapture={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        }}
                      >
                        {itemName}
                      </span>
                    </div>
                  ))}
                  <input
                    className="w-full p-2 border rounded-xl"
                    placeholder={placeholder}
                    ref={inputRef}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleAddItem(
                          collection as
                            | 'learningAreas'
                            | 'skills'
                            | 'prerequisites',
                          inputRef,
                        )(e);
                      }
                    }}
                  />
                  {
                    //@ts-expect-error FIXME: fix types
                    formErrors?.[collection]?.message && (
                      <p className="mt-1 text-red-500">
                        {
                          //@ts-expect-error FIXME: fix types
                          formErrors?.[collection]?.message
                        }
                      </p>
                    )
                  }
                  <button
                    className="px-4 py-2 text-xs font-medium border rounded-lg text-mydra-purple"
                    onClick={handleAddItem(
                      collection as
                        | 'learningAreas'
                        | 'skills'
                        | 'prerequisites',
                      inputRef,
                    )}
                  >
                    <div className="flex items-center">
                      <Add sx={{ fontSize: 18 }} /> Add {label}
                    </div>
                  </button>
                </div>
              </div>
            </CourseItem>
          ))}
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() =>
        reset({
          ...course,
        })
      }
    />
  );
}
