import { CategoryService } from '@/services/category';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiQueryParams,
  ApiReactQueryOptions,
} from '@/types/api';
import { ApiIndividualCategory, ApiPaginatedCategory } from '@/types/category';
import { useQuery } from '@tanstack/react-query';

export const useGetCategories = (
  filters: ApiQueryParams = {},
  pagination: ApiPaginationParams = {},
  options?: ApiReactQueryOptions<ApiPaginatedCategory>,
) =>
  useQuery<ApiPaginatedCategory, ApiErrorResponse>({
    queryKey: [CategoryService.PATH, filters, pagination],
    queryFn: async () =>
      await CategoryService.getCategories(filters, pagination),
    ...options,
  });

export const useGetCategory = (
  id: string,
  options?: ApiReactQueryOptions<ApiIndividualCategory>,
) =>
  useQuery<ApiIndividualCategory, ApiErrorResponse>({
    queryKey: [CategoryService.PATH, id],
    queryFn: async () => await CategoryService.getCategory(id),
    ...options,
  });
