export function TableHeader({
  children,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <div {...props} className={`flex w-full ${props.className ?? ''}`}>
      <span className={`text-xs text-text w-full ${props.className ?? ''}`}>
        {children}
      </span>
    </div>
  );
}
