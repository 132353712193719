import { useFloating, UseFloatingOptions } from '@floating-ui/react';

type TooltipProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  reference: Required<UseFloatingOptions>['elements']['reference'];
  options?: Omit<UseFloatingOptions, 'open'>;
};

export function Tooltip({
  open,
  options,
  reference,
  children,
  ...props
}: TooltipProps) {
  const { refs, floatingStyles } = useFloating({
    elements: { ...options?.elements, reference },
    ...options,
    open,
  });

  return (
    <div
      ref={refs.setFloating}
      style={floatingStyles}
      className={`inline-flex gap-2 justify-center items-center px-2 py-1 rounded-lg bg-white shadow-tooltip ${open ? 'visible' : 'invisible'} ${props.className}`}
      {...props}
    >
      {children}
    </div>
  );
}
