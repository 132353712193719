import { yupResolver } from '@hookform/resolvers/yup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/modules/common/components/Button';
import { Switch } from '@/modules/common/components/Switch';
import { TextField } from '@/modules/common/components/TextField';
import { ITeam } from '@/types/team';
import { InviteType } from '../../types';

export type InviteUserFormValues = {
  email: string;
  role: string;
  team?: string;
  inviteType: InviteType;
};

type InviteUserFormProps = Omit<
  React.FormHTMLAttributes<HTMLFormElement>,
  'onSubmit'
> & {
  teams: ITeam[];
  onSubmit: (data: InviteUserFormValues) => Promise<unknown>;
};

const FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email('Please provide an valid e-mail address')
    .required('Please input your email address'),
  role: yup.string().required().oneOf(['user', 'employer']), // TODO
  team: yup.string().optional(),
  inviteType: yup.string().required().oneOf(Object.values(InviteType)),
});

export function InviteUserForm({
  teams = [],
  onSubmit,
  ...rest
}: InviteUserFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<InviteUserFormValues>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      email: '',
      role: 'user',
      team: '',
      inviteType: InviteType.User,
    },
  });

  return (
    <form
      {...rest}
      className="flex items-center w-full gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="email"
            errorMessage={errors.email?.message as string}
            name="name"
            id="name"
            className="text-sm w-96"
            label="Email"
            placeholder="user@company.com"
          />
        )}
      />
      <div className="flex flex-col items-center gap-2 align-center">
        <Controller
          name="team"
          control={control}
          render={({ field }) => (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
              <InputLabel id="invitation-select-team">Team</InputLabel>
              <Select
                {...field}
                labelId="invitation-select-team"
                onChange={e => field.onChange(e.target.value)}
              >
                {teams.map(t => (
                  <MenuItem key={t._id} value={t._id}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="flex flex-col items-center gap-2 align-center">
        <label htmlFor="role" className="text-sm">
          Admin
        </label>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <Switch
              defaultChecked={field.value === 'employer'}
              onChange={e => {
                field.onChange(e.target.checked ? 'employer' : 'user');
              }}
            />
          )}
        />
      </div>
      <Button
        primary
        small
        type="submit"
        isLoading={isSubmitting}
        slotProps={{ wrapperProps: { className: 'self-end' } }}
      >
        Invite
      </Button>
    </form>
  );
}
