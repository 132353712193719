import { NavBar } from '../components/NavBar';
import { SideBar } from '../components/SideBar';

type AdminLayoutProps = React.HTMLAttributes<HTMLDivElement> & {
  beforeLogo?: React.ReactNode;
  afterLogo?: React.ReactNode;
  beforeUserMenu?: React.ReactNode;
  hideSidebar?: boolean;
  hideUserMenu?: boolean;
  stickyNavBar?: boolean;
};

export const AdminLayout = function AdminLayout({
  beforeLogo,
  afterLogo,
  beforeUserMenu,
  hideSidebar,
  hideUserMenu,
  children,
  stickyNavBar,
  ...props
}: AdminLayoutProps) {
  return (
    <div
      {...props}
      className={`flex relative flex-col w-full overflow-hidden h-screen ${props.className ?? ''}`}
    >
      <NavBar
        beforeLogo={beforeLogo}
        afterLogo={afterLogo}
        beforeUserMenu={beforeUserMenu}
        showUserMenu={!hideUserMenu}
        className={`${stickyNavBar ? 'sticky top-0 w-full' : ''}`}
      />
      <div className="flex flex-1 overflow-hidden">
        {!hideSidebar && (
          <SideBar className="flex self-stretch flex-grow-0 flex-shrink-0 basis-64" />
        )}
        <main className="w-full h-full overflow-auto ">{children}</main>
      </div>
    </div>
  );
};
