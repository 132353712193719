import { ICategory } from '@/types/category';
import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { Category, LayersOutlined } from '@mui/icons-material';
import { keepPreviousData } from '@tanstack/react-query';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useGetCategories } from '../../hooks/useCategories';
import { CourseCategoriesSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';
import { CourseItem } from '../CourseItem';

type FormValues = {
  categoryIds: string[];
  subCategories: string[];
};

type CourseData = Pick<ICourse, 'categories' | 'subCategories'>;

type CourseCategoriesProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseCategories({
  onSubmit,
  ...props
}: CourseCategoriesProps) {
  const { data: { data: availableCategories } = {} } = useGetCategories(
    {},
    { limit: 500 },
    { placeholderData: keepPreviousData },
  );

  const {
    getValues,
    formState: { errors },
  } = useFormContext<CourseData & FormValues>();
  const [categoryIds, categoriesFromCourse, subCategoriesFromCourse] =
    getValues(['categoryIds', 'categories', 'subCategories']);

  const course: CourseData = {
    categories: categoriesFromCourse,
    subCategories: subCategoriesFromCourse,
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      categoryIds:
        Array.isArray(course?.categories) && course?.categories.length
          ? [(course.categories?.[0] as ICategory)?._id]
          : [],
      subCategories: course?.subCategories ?? [],
    },
    // @ts-expect-error FIXME: fix types
    resolver: yupResolver(CourseCategoriesSchema),
  });

  const selectedCategoryId = watch('categoryIds')[0];
  const subCategories = watch('subCategories');
  const mainCategory = Array.isArray(course.categories)
    ? course.categories[0]?.name
    : availableCategories?.find?.(({ _id }) => _id === categoryIds?.[0])?.name;

  const availableSubcategories = availableCategories?.find(
    category => category._id === selectedCategoryId,
  )?.subCategories;

  const hasError = !!errors.categoryIds || !!errors.subCategories;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      className={`${props.className}`}
      sectionName="course-categories"
      sectionTitle="Categories"
      defaultSection={
        <div className="flex flex-col gap-4">
          <CourseItem label="Primary category" icon={<Category />}>
            {mainCategory ? (
              <p className="p-2 border rounded-3xl">{mainCategory}</p>
            ) : (
              <p>(not set)</p>
            )}
          </CourseItem>
          <CourseItem label="Subcategories" icon={<LayersOutlined />}>
            {course?.subCategories?.length ? (
              <ul className="flex flex-col items-start gap-2">
                {subCategories.map(subCategory => (
                  <li
                    key={subCategory}
                    className="px-3 py-2 capitalize border rounded-3xl"
                  >
                    {subCategory}
                  </li>
                ))}
              </ul>
            ) : (
              '(no subcategories set)'
            )}
          </CourseItem>
        </div>
      }
      editingSection={
        <div className="flex flex-col gap-4">
          <CourseItem label="Primary category" icon={<Category />}>
            <Controller
              name="categoryIds"
              control={control}
              render={({ field }) => (
                <>
                  <select
                    {...field}
                    value={field.value[0]}
                    onChange={e => {
                      field.onChange([e.target.value]);
                      setValue('subCategories', []);
                    }}
                    className="px-3 py-2 capitalize bg-white border rounded-lg border-mydra-black"
                  >
                    <option value="">Select a category</option>
                    {availableCategories?.map(category => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {formErrors.categoryIds?.message && (
                    <p className="mt-1 text-red-500">
                      {formErrors.categoryIds.message}
                    </p>
                  )}
                </>
              )}
            />
          </CourseItem>
          <CourseItem label="Subcategories" icon={<LayersOutlined />}>
            <ul>
              {(availableSubcategories ?? []).map(subCategory => (
                <li key={subCategory.slug}>
                  <label className="flex gap-2">
                    <input
                      type="checkbox"
                      {...register('subCategories')}
                      value={subCategory.slug}
                      checked={subCategories.includes(subCategory.slug)}
                    />
                    {subCategory.name}
                  </label>
                </li>
              ))}
            </ul>
            {formErrors.subCategories?.message && (
              <p className="mt-1 text-red-500">
                {formErrors.subCategories.message}
              </p>
            )}
          </CourseItem>
        </div>
      }
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
    />
  );
}
