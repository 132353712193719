import { ApiPaginationParams, ApiQueryParams } from '@/types/api';
import {
  ApiIndividualEducationProvider,
  ApiPaginatedEducationProvider,
  ApiStripeLinks,
  IEducationProvider,
} from '@/types/education-provider';
import { IUser } from '@/types/user';
import qs from 'query-string';
import { ApiService } from './api';

export class EducationProviderService extends ApiService {
  static PATH = '/education-providers';
  static PATH_STRIPE_LINKS = `${EducationProviderService.PATH}/:id/stripe-account-links`;

  static formatPayload(educationProvider: IEducationProvider) {
    const {
      name,
      logoUrl,
      slug,
      country,
      countryCode,
      isAffiliate,
      users,
      paymentProvider,
    } = educationProvider;

    return {
      name,
      logoUrl,
      country,
      countryCode,
      isAffiliate,
      paymentProvider: paymentProvider?.type
        ? {
            type: paymentProvider.type,
            loginEnabled: paymentProvider.loginEnabled,
            status: paymentProvider.status,
            externalId: paymentProvider.externalId,
          }
        : undefined,
      slug: slug || name?.toLowerCase().split(' ').join('-'),
      users: users?.map(user => (user as IUser)?._id) || undefined,
    };
  }

  static async getEducationProviders(
    filters: ApiQueryParams = {},
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedEducationProvider> {
    const queryString = qs.stringify({ ...filters, ...pagination });
    return ApiService.get(`${EducationProviderService.PATH}?${queryString}`);
  }

  static async getEducationProvider(
    id: string,
  ): Promise<ApiIndividualEducationProvider> {
    return ApiService.get(`${EducationProviderService.PATH}/${id}`);
  }

  static async createEducationProvider(
    payload: IEducationProvider,
  ): Promise<ApiIndividualEducationProvider> {
    return ApiService.post(
      EducationProviderService.PATH,
      EducationProviderService.formatPayload(payload),
    );
  }

  static async editEducationProvider(
    payload: IEducationProvider,
  ): Promise<ApiIndividualEducationProvider> {
    const { _id: id } = payload;
    return ApiService.put(
      `${EducationProviderService.PATH}/${id}`,
      EducationProviderService.formatPayload(payload),
    );
  }

  static async getConnectStripeAccountLinks(
    id: string,
  ): Promise<ApiStripeLinks> {
    return ApiService.get(
      EducationProviderService.PATH_STRIPE_LINKS.replace(':id', id),
    );
  }
}
