import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { Notification } from '@/modules/common/components/Notification';
import { IUser } from '@/types/user';
import { QueryClient } from '@tanstack/react-query';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import React, { Suspense } from 'react';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

const ReactQueryDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/react-query-devtools').then(res => ({
          default: res.ReactQueryDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  user?: IUser;
}>()({
  component: () => (
    <>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
      <Suspense>
        <ReactQueryDevtools buttonPosition="bottom-right" />
      </Suspense>
      <Notification />
    </>
  ),
  errorComponent: ({ error, reset }) => {
    return <ErrorMessage error={error} reset={reset} />;
  },
  notFoundComponent: () => {
    return <ErrorMessage error={{ statusCode: 404 }} reset={() => null} />;
  },
});
