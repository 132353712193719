import { getCourseQueryOptions } from '@/modules/courses/hooks/useCourses';
import { UpsertCoursePage } from '@/modules/courses/pages/UpsertCoursePage';
import { QueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_admin/courses/$courseId/edit')({
  loader: async ({
    context: { queryClient },
    params,
  }: {
    params: { courseId: string };
    context: { queryClient: QueryClient };
  }) => {
    return queryClient.ensureQueryData(getCourseQueryOptions(params.courseId));
  },
  component: () => <UpsertCoursePage />,
});
