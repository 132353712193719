import { USER_ROLES } from '@/constants';
import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import {
  DEFAULT_USER_INCLUDES,
  useCurrentUser,
} from '@/modules/common/hooks/useUser';
import { Navigate, createFileRoute } from '@tanstack/react-router';

const RedirectComponent = () => {
  const { data: { data: user } = {}, isLoading } = useCurrentUser(
    DEFAULT_USER_INCLUDES,
    {
      throwOnError: false,
    },
  );

  if (isLoading) return null;

  return user?.roles.includes(USER_ROLES.EDUCATION_PROVIDER) ? (
    <Navigate to="/courses" />
  ) : (
    <Navigate to="/applications" />
  );
};

export const Route = createFileRoute('/')({
  component: RedirectComponent,
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 500 }} reset={() => null} />;
  },
});
