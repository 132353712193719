import { EmployerService } from '@/services/employer';
import { ApiErrorResponse } from '@/types/api';
import { ICompanyPartialUpdatePayload } from '@/types/company';
import { ApiIndividualEmployer } from '@/types/employer';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useCompanyMutation(companyId: string) {
  const mutationKey = [EmployerService.PATH, companyId];
  const queryClient = useQueryClient();

  return useMutation<
    ApiIndividualEmployer,
    ApiErrorResponse,
    { id?: string; updatedData: ICompanyPartialUpdatePayload }
  >({
    mutationKey: mutationKey,
    mutationFn: async ({ id = companyId, updatedData }) => {
      return EmployerService.patchEmployer(id ?? companyId, updatedData);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [EmployerService.PATH],
        exact: false,
      });
    },
  });
}
