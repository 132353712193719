import { ICourse } from '@/types/course';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { CourseDescriptionSchema } from '../../pages/UpsertCoursePage.constants';
import { CourseEditingSection } from '../CourseEditingSection';

type FormValues = Pick<ICourse, 'summary' | 'learningDescription'>;

type CourseDescriptionProps = {
  onSubmit: (data: FormValues) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

export function CourseDescription({
  onSubmit,
  ...props
}: CourseDescriptionProps) {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [summary, learningDescription] = getValues([
    'summary',
    'learningDescription',
  ]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      summary: summary ?? '',
      learningDescription: learningDescription ?? '',
    },
    resolver: yupResolver(CourseDescriptionSchema),
  });

  const hasError = !!errors.summary || !!errors.learningDescription;

  return (
    <CourseEditingSection
      {...props}
      hasError={hasError}
      sectionName="course-description"
      isLoading={isSubmitting}
      onSave={handleSubmit(onSubmit)}
      onCancel={() =>
        reset({
          summary,
          learningDescription,
        })
      }
      defaultSection={
        <div className="flex flex-col gap-7">
          <div className="flex flex-col gap-3">
            <p className="text-base font-medium">Summary</p>
            <p className="text-text">{summary ?? 'No sumary set'}</p>
          </div>

          <div className="flex flex-col gap-3">
            <p className="text-base font-medium">Learning description</p>
            <p className="text-text">
              {learningDescription ?? 'No learning description set'}
            </p>
          </div>
        </div>
      }
      editingSection={
        <form
          action="/"
          method="post"
          onSubmit={e => handleSubmit(onSubmit)(e)}
          className="flex flex-col gap-6"
        >
          <div>
            <label htmlFor="summary" className="font-medium">
              Summary
            </label>
            <TextareaAutosize
              rows={4}
              id="summary"
              {...register('summary')}
              className="w-full px-3 py-2 mt-3 bg-transparent border rounded-lg border-text text-text"
            />
            {formErrors.summary?.message && (
              <p className="mt-1 text-red-500">{formErrors.summary.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="learningDescription" className="font-medium">
              Learning description
            </label>
            <TextareaAutosize
              rows={6}
              id="learningDescription"
              {...register('learningDescription')}
              className="w-full px-3 py-2 mt-3 bg-transparent border rounded-lg border-text text-text"
            />
            {formErrors.learningDescription?.message && (
              <p className="mt-1 text-red-500">
                {formErrors.learningDescription.message}
              </p>
            )}
          </div>
        </form>
      }
    />
  );
}
