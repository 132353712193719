import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { PaymentStatus } from '@/types/education-provider';
import { PaymentInclude } from '@/types/payment';
import { UserRole } from '@/types/user';
import { Tab as TabBase } from '@mui/base/Tab';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ListIcon from '@mui/icons-material/List';
import { keepPreviousData } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { PaginationState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ListHistoryPayments } from '../components/ListHistoryPayments';
import { ListPendingPayments } from '../components/ListPendingPayments';
import { useGetPayments } from '../hooks/usePayments';
import { PaymentsSearchParams } from '../types';

const routeApi = getRouteApi('/_admin/payments/');

const Tab = ({
  value,
  children,
}: {
  value: string;
  children: React.ReactNode;
}) => {
  return (
    <TabBase
      slotProps={{
        root: ({ selected }) => ({
          className: `inline-flex items-center gap-2 py-5 text-base font-medium ${
            selected ? 'border-b-4 border-black' : ''
          }`,
        }),
      }}
      value={value}
    >
      {children}
    </TabBase>
  );
};

export function ListPaymentsPage() {
  const { currentUser, taxBenefitsProvider } = useCurrentUserContext() ?? {};
  const search = routeApi.useSearch() as PaymentsSearchParams;
  const navigate = routeApi.useNavigate();
  const [selectedTab, setSelectedTab] = useState('upcoming');

  const {
    isLoading,
    data: { data: payments = [], metadata: { total = 0, page = 1 } = {} } = {},
  } = useGetPayments(
    {
      ...(currentUser?.roles.includes(UserRole.TaxBenefitsProvider) &&
        taxBenefitsProvider?._id && {
          payer: taxBenefitsProvider._id,
        }),
      status: search.status,
    },
    {
      page: search.page,
      limit: 10,
    },
    [PaymentInclude.Course, PaymentInclude.Order, PaymentInclude.User],
    {
      placeholderData: keepPreviousData,
    },
  );

  const paginationState = {
    pageIndex: (page ?? 1) - 1 || 0,
    pageSize: 10,
  };

  const onPaginationChange = ({ pageIndex }: PaginationState) => {
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        page: pageIndex + 1,
      }),
    });
  };

  useEffect(() => {
    navigate({
      search: () => ({
        ...(selectedTab === 'upcoming' && { status: PaymentStatus.Pending }),
      }),
    });
  }, [navigate, selectedTab]);

  return (
    <div className="flex flex-col h-full">
      <header className="flex justify-between items-center px-12 py-5 border-b">
        <h1 className="text-2xl font-medium">Payments</h1>
      </header>
      <Tabs
        defaultValue="upcoming"
        onChange={(_event, newValue) => {
          setSelectedTab(newValue as string);
        }}
      >
        <TabsList className="flex gap-5 px-12 border-b">
          <Tab value={'upcoming'}>
            <ListIcon />
            Upcoming Payments
          </Tab>
          <Tab value={'history'}>
            <DescriptionOutlinedIcon />
            Billing History
          </Tab>
        </TabsList>
        <TabPanel value="upcoming" className="flex-1 px-12 pt-8">
          <ListPendingPayments
            isLoading={isLoading}
            onPaginationChange={updater => {
              return typeof updater === 'function'
                ? onPaginationChange(updater(paginationState))
                : onPaginationChange(updater);
            }}
            payments={payments}
            paginationState={paginationState}
            rowCount={total}
          />
        </TabPanel>
        <TabPanel value="history" className="flex-1 px-12 pt-8">
          <ListHistoryPayments
            isLoading={isLoading}
            onPaginationChange={updater => {
              return typeof updater === 'function'
                ? onPaginationChange(updater(paginationState))
                : onPaginationChange(updater);
            }}
            onFilterChange={status =>
              navigate({
                search: () =>
                  ({
                    page: 1,
                    ...(status && { status }),
                  }) as PaymentsSearchParams,
              })
            }
            searchState={search}
            payments={payments}
            paginationState={paginationState}
            rowCount={total}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
}
